import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

// Get Course by slug
export const checkCompletedCourse = createAsyncThunk('checkCourse/checkCompletedCourse', async (courseId) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/course/isCompleted?course_id=${courseId}`, {
                headers: {
                    Authorization: userToken
                }
            });

            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const getCurrentCourseState = createAsyncThunk('checkCourse/getCurrentCourseState', async (courseId) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/course/state/${courseId}`, {
                headers: {
                    Authorization: userToken
                }
            });

            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const courseCheckSlice = createSlice({
    name: 'checkCourse',
    initialState: {
        completedCourse: {},
        currentCourseState: {},
        loadingCourse: false
    },
    extraReducers: {
        // Get Single Course
        [checkCompletedCourse.pending]: (state, action) => {
            state.loadingCourse = true;
        },
        [checkCompletedCourse.fulfilled]: (state, action) => {
            state.loadingCourse = false;
            state.completedCourse = action.payload;
        },
        [checkCompletedCourse.rejected]: (state, action) => {
            state.loadingCourse = false;
        },

        // Get Current Course State
        [getCurrentCourseState.pending]: (state, action) => {
            state.loadingCourse = true;
        },
        [getCurrentCourseState.fulfilled]: (state, action) => {
            state.loadingCourse = false;
            state.currentCourseState = action.payload;
        },
        [getCurrentCourseState.rejected]: (state, action) => {
            state.loadingCourse = false;
        }
    }
});

export default courseCheckSlice.reducer;
