const InputSelect = ({ name, label, subtitle, errors, register, isRequired, children }) => {
    return (
        <>
            <label htmlFor={name} className="form-label fw-bolder">{label}{isRequired && <span className="text-danger">*</span>}</label>
            <small>{subtitle}</small>
            <select className="form-select" {...register(name, { required: isRequired })}>
                {children}
            </select>
            {errors?.name?.type === 'required' && <span className="text-danger">This field is required</span>}
        </>
    );
};

export default InputSelect