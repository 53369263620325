import { faEdit, faLightbulb, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import slugify from 'react-slugify';
import Layout from "../../../components/global/Layout/Layout";
import { getDetailMentorRoadmap, updateMentorRoadmap } from '../../../features/mentor/mentorRoadmapSlice';
import { capitalizeFirstLetter, removeHTMLTags, uploadImage } from '../../../utils/helper';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { InputTags } from 'react-bootstrap-tagsinput';
import Sidebar from '../../../components/mentor/Sidebar/Sidebar';

const EditRoadmapOverview = () => {
    const navigate = useNavigate()
    const MySwal = withReactContent(Swal);

    const dispatch = useDispatch()
    const { id } = useParams()
    const { loadingMentorRoadmaps } = useSelector((state) => state.mentorRoadmap);

    const { reset, register, watch, setValue, handleSubmit, formState: { errors } } = useForm();
    const watchRoadmapTitleValue = watch('path_name');

    const [cover, setCover] = useState("");
    const [coverLoading, setCoverLoading] = useState(false);
    const [disabledCover, setDisabledCover] = useState(false);
    const [errorUploaderCover, setErrorUploaderCover] = useState(false)

    const [smallIcon, setSmallIcon] = useState("");
    const [smallIconLoading, setSmallIconLoading] = useState(false);
    const [disabledSmallIcon, setDisabledSmallIcon] = useState(false);
    const [errorUploaderSmallIcon, setErrorUploaderSmallIcon] = useState(false)

    const [tags, setTags] = useState([])

    const [provideCertificate, setProvideCertificate] = useState(false)

    useEffect(() => {
        setValue('path_slug', slugify(watchRoadmapTitleValue))
    }, [setValue, watchRoadmapTitleValue]);

    useEffect(() => {
        dispatch(getDetailMentorRoadmap(id)).then(res => {
            const roadmap = res.payload.result
            reset({
                path_name: roadmap?.path_name,
                path_slug: roadmap?.path_slug,
                landing_url: roadmap?.landing_url,
                description: roadmap?.description,
                long_description: roadmap?.long_description,
                duration: roadmap?.duration,
                status: roadmap?.status,
                level: roadmap?.level,
                cover: roadmap.image_url,
                small_icon: roadmap.small_icon,
            })

            setCover(roadmap.image_url)
            setSmallIcon(roadmap.small_icon)
            setProvideCertificate(roadmap.allow_certificate === 'Y' ? true : false)

            let newtags = roadmap?.tags?.split(',');
            setTags(newtags);

        }).catch(err => err)
    }, [dispatch, reset, id]);

    const onSubmitRoadmap = (data) => {

        // Check uploader image
        if (cover === "") {
            setErrorUploaderCover(true);
        }
        if (smallIcon === "") {
            setErrorUploaderSmallIcon(true); return;
        }

        // Inject data payload
        data.id = id
        data.image_url = cover
        data.small_icon = smallIcon
        data.tags = tags.join(',')
        data.allow_certificate = provideCertificate ? 'Y' : 'N'
        data.is_public = 1

        // Post data payload
        dispatch(updateMentorRoadmap(data)).then(response => {
            if (response.payload.status === 'success') {
                MySwal.fire({
                    title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                    text: removeHTMLTags(response.payload.message),
                    icon: 'success',
                    timer: 1500
                }).then(
                    navigate(`/mentor/roadmaps/edit/curriculum/${id}`)
                );
            } else {
                // Alert Failed
                MySwal.fire({
                    title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                    text: removeHTMLTags(response.payload.message),
                    icon: 'error'
                });
            };
        })
    };

    return (
        <>
            <Helmet>
                <title>Edit Roadmap</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            {/* Sidebar */}
                            <div className="col-lg-2">
                                <Sidebar />
                            </div>
                            {/* End of Sidebar */}

                            {/* Main Content */}
                            <div className="col-lg-10 text-muted px-4 px-lg-5">
                                <h3 className="section-title mb-5">Edit Roadmap</h3>

                                <ul className="nav nav-pills text-nowrap overflow-auto flex-nowrap my-4" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link px-4 active" to={`/mentor/roadmaps/edit/overview/${id}`}>Overview</Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link px-4" to={`/mentor/roadmaps/edit/curriculum/${id}`}>Curriculum</Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link px-4" to={`/mentor/roadmaps/edit/pricing/${id}`}>Pricing</Link>
                                    </li>
                                </ul>

                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="section-title my-auto"><FontAwesomeIcon icon={faEdit} /> Roadmap Property</h5>
                                    </div>
                                </div>

                                <div className="row gx-5 my-4">
                                    <div className="col-lg-8">
                                        <div className="card">
                                            <div className="card-body">
                                                <form onSubmit={handleSubmit(onSubmitRoadmap)}>
                                                    <div className="mb-3">
                                                        <label htmlFor="roadmap" className="form-label fw-bolder">Roadmap</label>
                                                        <input name="roadmap" className="form-control" placeholder="Roadmap" {...register("path_name", { required: true, maxLength: 50 })} />
                                                        {errors.path_name && errors.path_name.type === 'required' && <span className="text-danger">This field is required</span>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="path_slug" className="form-label fw-bolder">Roadmap Slug</label>
                                                        <input name="path_slug" className="form-control" placeholder="roadmap-slug" {...register("path_slug")} readOnly />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="landingURL" className="form-label fw-bolder">Landing URL</label>
                                                        <input name="landingURL" className="form-control" placeholder="Landing URL" {...register("landing_url", { required: true, maxLength: 50 })} />
                                                        {errors.landing_url && errors.landing_url.type === 'required' && <span className="text-danger">This field is required</span>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="cover" className="form-label fw-bolder">Cover</label>
                                                        <input className="form-control" type="hidden" value={cover} />
                                                        <input className="form-control" onChange={(e) => { uploadImage(e, setCover, setCoverLoading); setDisabledCover(true); setErrorUploaderCover(false) }} type="file" id="cover" hidden />
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                onChange={(e) => { setCover(e.target.value); setErrorUploaderCover(false) }}
                                                                disabled={disabledCover}
                                                                className="form-control"
                                                                value={cover}
                                                            />
                                                            <label className="btn btn-primary rounded" htmlFor="cover"><FontAwesomeIcon icon={faUpload} /> Upload</label>
                                                        </div>
                                                        {errorUploaderCover && <span className="text-danger">This field is required</span>}
                                                        {coverLoading && (
                                                            <>
                                                                <span className="spinner-border spinner-border-sm me-2 text-muted" role="status" aria-hidden="true" />
                                                                <small className="text-muted">Please wait....</small>
                                                            </>
                                                        )}
                                                        {!coverLoading && cover && (
                                                            <img height={200} width={200} className="objectfit-cover d-block mx-auto my-3" src={cover || "/assets/img/placeholder.jpg"} alt="Cover" />
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="smallIcon" className="form-label fw-bolder">Small Icon</label>
                                                        <input className="form-control" type="hidden" value={smallIcon} />
                                                        <input className="form-control" onChange={(e) => { uploadImage(e, setSmallIcon, setSmallIconLoading); setDisabledSmallIcon(true); setErrorUploaderSmallIcon(false) }} type="file" id="smallIcon" hidden />
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                onChange={(e) => { setSmallIcon(e.target.value); setErrorUploaderSmallIcon(false) }}
                                                                disabled={disabledSmallIcon}
                                                                className="form-control"
                                                                value={smallIcon}
                                                            />
                                                            <label className="btn btn-primary rounded" htmlFor="smallIcon"><FontAwesomeIcon icon={faUpload} /> Upload</label>
                                                        </div>
                                                        {errorUploaderSmallIcon && <span className="text-danger">This field is required</span>}
                                                        {smallIconLoading && (
                                                            <>
                                                                <span className="spinner-border spinner-border-sm me-2 text-muted" role="status" aria-hidden="true" />
                                                                <small className="text-muted">Please wait....</small>
                                                            </>
                                                        )}
                                                        {!smallIconLoading && smallIcon && (
                                                            <img height={200} width={200} className="objectfit-cover d-block mx-auto my-3" src={smallIcon || "/assets/img/placeholder.jpg"} alt="smallIcon" />
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="description" className="form-label fw-bolder">Description</label>
                                                        <textarea name="description" id="description" rows="3" placeholder="description" className="form-control" {...register("description", { required: true })}></textarea>
                                                        {errors.description && errors.description.type === 'required' && <span className="text-danger">This field is required</span>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="longDescription" className="form-label fw-bolder">Long Description</label>
                                                        <textarea name="longDescription" id="longDescription" rows="3" placeholder="Long description" className="form-control" {...register("long_description", { required: true })}></textarea>
                                                        {errors.long_description && errors.long_description.type === 'required' && <span className="text-danger">This field is required</span>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="level" className="form-label fw-bolder">Level</label>
                                                        <select name="level" id="level" className="form-select" {...register("level", { required: true })}>
                                                            <option value="beginner">Beginner</option>
                                                            <option value="intermediate">intermediate</option>
                                                            <option value="advance">Advance</option>
                                                        </select>
                                                        {errors.level && errors.level.type === 'required' && <span className="text-danger">This field is required</span>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="tags" className="form-label fw-bolder mb-0">Tags</label>
                                                        <p className="small text-muted">Gunakan spasi atau koma untuk menambah tag</p>
                                                        <div className="input-group">
                                                            <InputTags
                                                                name="tags"
                                                                className="form-control shadow-none p-0"
                                                                placeholder="Add tags..."
                                                                values={tags}
                                                                onTags={(tag) => setTags(tag.values)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="duration" className="form-label fw-bolder mb-0">Duration</label>
                                                        <p className="small text-muted">Total durasi jam dalam roadmap</p>
                                                        <input type="text" name="duration" className="form-control" placeholder="Ex : 10:00" {...register("duration", { required: true })} />
                                                        {errors.duration && errors.duration.type === 'required' && <span className="text-danger">This field is required</span>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="status" className="form-label fw-bolder">Status</label>
                                                        <select name="status" id="status" className="form-select" {...register("status", { required: true })}>
                                                            <option value="draft">Draft</option>
                                                        </select>
                                                        {errors.status && errors.status.type === 'required' && <span className="text-danger">This field is required</span>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label fw-bolder">Provide Certificate</label>
                                                        <div className="form-check">
                                                            <input onChange={() => setProvideCertificate(false)} className="form-check-input" type="radio" name="provideCertificate" id="no" checked={!provideCertificate} />
                                                            <label className="form-check-label" htmlFor="no">
                                                                No
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input onChange={() => setProvideCertificate(true)} className="form-check-input" type="radio" name="provideCertificate" id="yes" checked={provideCertificate} />
                                                            <label className="form-check-label" htmlFor="yes">
                                                                Yes
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="text-end">
                                                        <button type="reset" className="btn text-muted shadow-none btn-reset">Reset</button>
                                                        {
                                                            loadingMentorRoadmaps ?
                                                                (
                                                                    <button type="submit" className="btn btn-primary btn-rounded disabled">
                                                                        <div className="spinner-border" role="status">
                                                                            <span className="visually-hidden">Loading...</span>
                                                                        </div>
                                                                    </button>
                                                                )
                                                                : (<button type="submit" className="btn btn-primary btn-rounded">Save & continue</button>)
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 d-none d-lg-block">
                                        <div className="sticky-top" style={{ top: '100px' }}>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <FontAwesomeIcon className="text-primary me-3" size="3x" icon={faLightbulb} />
                                                        <h5 className="text-muted my-auto">Pro Tip:</h5>
                                                    </div>
                                                    <p className="text-muted my-3">Isi data-data Roadmap selengkap mungkin agar Roadmap yang kamu buat lebih mudah dicari</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* End of Main Content */}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default EditRoadmapOverview;
