import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Legend,
    Tooltip
} from 'chart.js';
import { useForm } from 'react-hook-form';
import Input from '../../../global/Inputs/Input/Input';
import { useDispatch } from 'react-redux';
import { getMentorEarningStats, getMentorTransactionStats } from '../../../../features/mentor/mentorSlice';
import { useSelector } from 'react-redux';
import { formatDate } from '../../../../utils/helper';

ChartJS.register(BarElement, CategoryScale, LinearScale, Legend, Tooltip);

const SectionGraphics = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { earningStats, transactionStats } = useSelector(state => state.mentor);

    const dispatch = useDispatch();

    let earningLabels = [];
    let earningData = [];
    let transactionLabels = [];
    let transactionData = [];

    earningStats?.forEach(earning => {
        earningLabels.push(formatDate(earning.date));
        earningData.push(earning.total);
    });
    transactionStats?.forEach(transaction => {
        transactionLabels.push(formatDate(transaction.date));
        transactionData.push(transaction.total);
    });

    const earnings = {
        labels: earningLabels,
        datasets: [
            {
                label: 'Earnings',
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'transparent',
                borderWidth: 2,
                data: earningData
            }
        ]
    };

    const transactions = {
        labels: transactionLabels,
        datasets: [
            {
                label: 'Transactions',
                backgroundColor: 'rgba(255, 105, 180, 1)',
                borderColor: 'transparent',
                borderWidth: 2,
                data: transactionData
            }
        ]
    };

    const options = {
        title: {
            display: true,
            text: 'Earnings and Transactions'
        }
    };

    const onSubmit = (data) => {
        dispatch(getMentorEarningStats(`?date_start=${data.date_start}&date_end=${data.date_end}`));
        dispatch(getMentorTransactionStats(`?date_start=${data.date_start}&date_end=${data.date_end}`));
    };

    return (
        <section className="mt-5">
            <h4 className="section-title mb-3">Grafik Penjualan</h4>

            <div className="card border-0 shadow-sm rounded">
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)} className="d-grid d-md-flex gap-3">
                        <div className="my-auto col-md-5">
                            <Input
                                type="date"
                                name="date_start"
                                label="Dari tanggal"
                                isRequired
                                register={register}
                                errors={errors}
                            />
                        </div>
                        <div className="my-auto col-md-5">
                            <Input
                                type="date"
                                name="date_end"
                                label="Sampai tanggal"
                                isRequired
                                register={register}
                                errors={errors}
                            />
                        </div>
                        <div className="col-auto mt-auto">
                            <button className="btn btn-primary" type="submit">Simpan</button>
                        </div>
                    </form>
                </div>
            </div>

            <div className="row gx-3 my-3">
                <div className="col-lg-6 py-2">
                    <div className="card border-0 shadow-sm rounded">
                        <div className="card-body">
                            <Bar
                                options={options}
                                data={earnings}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 py-2">
                    <div className="card border-0 shadow-sm rounded">
                        <div className="card-body">
                            <Bar
                                options={options}
                                data={transactions}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionGraphics;
