import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

// Get All Tutorials
export const getAllTutorial = createAsyncThunk('lesson/getAllTutorial', async (limit) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/posts/latest/post?page=1&limit=${limit || 100}`);
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Popular Tutorials
export const getPopularTutorials = createAsyncThunk('lesson/getPopularTutorials', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/posts/popularWeekly?limit=10`);
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const tutorialSlice = createSlice({
    name: 'tutorial',
    initialState: {
        tutorials: [],
        popularTutorials: [],
        loadingTutorial: false
    },
    extraReducers: {
        // Get Single Course
        [getAllTutorial.pending]: (state, action) => {
            state.loadingTutorial = true;
        },
        [getAllTutorial.fulfilled]: (state, action) => {
            state.loadingTutorial = false;
            state.tutorials = action.payload;
        },
        [getAllTutorial.rejected]: (state, action) => {
            state.loadingTutorial = false;
        },

        // Get Popular Tutorials
        [getPopularTutorials.pending]: (state, action) => {
            state.loadingTutorial = true;
        },
        [getPopularTutorials.fulfilled]: (state, action) => {
            state.loadingTutorial = false;
            if (action.payload.error !== 'No Content') state.popularTutorials = action.payload;
        },
        [getPopularTutorials.rejected]: (state, action) => {
            state.loadingTutorial = false;
        },
    }
});

export default tutorialSlice.reducer;
