import { faCheckCircle, faRedo, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import SkeletonCardCourse from "../../../skeletons/SkeletonCardCourse";
import CardExercise from "../../Cards/CardExercise/CardExercise";

const SectionBrowseExercise = ({ data, loading }) => {
    const [query, setQuery] = useState('');
    const [limit, setLimit] = useState(8);
    const [level, setLevel] = useState('');
    // const [price, setPrice] = useState('');

    const search = (data) => data.filter((quiz) => quiz.title.toLowerCase().includes(query.toLowerCase()) || quiz.level.toLowerCase().includes(query.toLowerCase()));
    const filterLevel = (data) => data.filter(quiz => quiz.level.includes(level));
    // const filterPrice = (data) => data.filter(quiz => quiz.price >= price);

    return (
        <section className="my-5">
            <div className="row">
                <div className="col-auto mb-3 mb-lg-auto">
                    <h4 className="section-title">Browse Exercises</h4>
                </div>
                <div className="col-auto ms-xl-auto">
                    <div className="d-flex">
                        {/* <div className="dropdown me-3">
                            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Harga
                            </button>
                            <ul className="dropdown-menu">
                                <li><button type="button" className="dropdown-item">Semua</button></li>
                                <li><button type="button" className="dropdown-item">Gratis</button></li>
                                <li><button type="button" className="dropdown-item">Berbayar</button></li>
                            </ul>
                        </div> */}
                        <div className="dropdown">
                            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Level
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <button type="button" className="dropdown-item" onClick={() => setLevel('')}>
                                        Semua
                                        {level === '' && (<FontAwesomeIcon className="text-primary ms-2" icon={faCheckCircle} />)}
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="dropdown-item" onClick={() => setLevel('Beginner')}>
                                        Beginner
                                        {level === 'Beginner' && (<FontAwesomeIcon className="text-primary ms-2" icon={faCheckCircle} />)}
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="dropdown-item" onClick={() => setLevel('Intermediate')}>
                                        Intermediate
                                        {level === 'Intermediate' && (<FontAwesomeIcon className="text-primary ms-2" icon={faCheckCircle} />)}
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="dropdown-item" onClick={() => setLevel('Master')}>
                                        Master
                                        {level === 'Master' && (<FontAwesomeIcon className="text-primary ms-2" icon={faCheckCircle} />)}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 my-3 my-lg-auto">
                    <div className="input-group mb-3">
                        <span className="input-group-text text-muted bg-white border-end-0">
                            <FontAwesomeIcon icon={faSearch} />
                        </span>
                        <input onChange={(e) => setQuery(e.target.value)} value={query} className="form-control border-start-0" type="search" placeholder="Search exercise..." />
                    </div>
                </div>
            </div>
            <div className="row my-4">
                {loading && [1, 2, 3, 4].map((item) => {
                    return (
                        <div className="col-md-6 col-lg-4 col-xl-3 p-2 mb-3" key={item}>
                            <SkeletonCardCourse />
                        </div>
                    );
                })}
                {!loading && search(filterLevel(data)).length < 1 ? (
                    <div className="col-12 text-center">
                        <p>Exercise tidak ditemukan</p>
                    </div>
                ) : null}
                {!loading && search(filterLevel(data))?.map((quiz) => {
                    return (
                        <div className="col-md-6 col-lg-4 col-xl-3 p-2 mb-3" key={quiz.id}>
                            <Link className="link" to={`/exercises/detail/${quiz.slug}`}>
                                <CardExercise
                                    thumbnail={quiz.cover}
                                    title={quiz.title}
                                    level={quiz.level}
                                    totalStudents={quiz.total_students}
                                    duration={quiz.duration}
                                    totalQuestions={quiz.total_questions}
                                    price={quiz.price}
                                />
                            </Link>
                        </div>
                    );
                }).slice(0, limit)}
            </div>
            {!loading && search(filterLevel(data)).length > limit ? (
                <div className="text-center">
                    <button type="button" onClick={() => setLimit(limit + 8)} className="btn btn-outline-secondary">
                        <FontAwesomeIcon className="me-2" icon={faRedo} />
                        Load more
                    </button>
                </div>
            ) : null}
        </section>
    );
};

export default SectionBrowseExercise;
