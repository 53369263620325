import { faLock, faPlayCircle, faShapes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SectionLessons = () => {
    const { course, loadingCourse } = useSelector((state) => state.courses);
    const freeCourse = course?.products?.find(product => product.retail_price === 0);

    return (
        <section className="card mb-3">
            <div className="card-body text-muted">
                <h5 className="mb-3"><FontAwesomeIcon className="text-primary me-2" icon={faShapes} />Daftar Materi</h5>

                <div className="accordion accordion-flush" id="accordionModules">
                    {!loadingCourse && course.lessons?.map((lesson, index) => {
                        return (
                            <div className="accordion-item mb-2 border-0 px-0" key={index}>
                                <h2 className="accordion-header" id={'flush-heading' + lesson.topic_slug}>
                                    <button className={index !== 0 ? `accordion-button bg-light collapsed` : `accordion-button bg-light`} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + lesson.topic_slug} aria-expanded={index === 0 ? "true" : "false"} aria-controls={"collapse" + lesson.id}>
                                        {lesson.topic_title}
                                    </button>
                                </h2>
                                <div id={"collapse" + lesson.topic_slug} className={index === 0 ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby={'flush-heading' + lesson.topic_slug} data-bs-parent="#accordionModules">
                                    <div className="accordion-body">
                                        <table className="table table-hover">
                                            <tbody>
                                                {lesson.contents.map((content, index) => {
                                                    return (
                                                        <tr className="text-muted" key={index}>
                                                            <td className="col-1"><FontAwesomeIcon className="text-primary" icon={course?.purchased || freeCourse ? faPlayCircle : faLock} /></td>
                                                            <td className="col-auto">
                                                                {course?.purchased || freeCourse ? (
                                                                    <Link className="link" to={`/learn/courses/${course.course?.slug}/lessons/${content.id}`}>
                                                                        <span className="text-muted">
                                                                            {content.lesson_title}
                                                                        </span>
                                                                    </Link>
                                                                ) : (
                                                                    <span className="text-muted">
                                                                        {content.lesson_title}
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td className="col-1 text-muted small">{content.duration}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default SectionLessons;
