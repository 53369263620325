import { useSelector } from "react-redux";
import CardCourse from "../../global/Cards/CardCourse/CardCourse";
import SkeletonCardMyLearning from "../../skeletons/SkeletonCardMyLearning";
import { Link } from "react-router-dom";

const SectionCourses = ({ loadMoreCourses }) => {
    const { courses, loadingBrowseCourse, hasMore } = useSelector((state) => state.browseCourse);

    return (
        <section>
            <div className="row">
                {courses.length > 0 && courses.map((course, index) => (
                    <div className="col-sm-6 col-md-4 col-xl-3 p-2" key={index}>
                        <Link className="link" to={`/learn/courses/detail/${course.slug}`}>
                            <CardCourse
                                thumbnail={course.cover}
                                author={course.author}
                                title={course.title}
                                level={course.level}
                                totalStudents={course.total_student}
                                totalModules={course.total_module}
                                totalTimes={course.total_time}
                                totalRating={course.total_rating}
                                totalFeedback={course.total_feedback}
                                normalBuyPrice={course.buy?.normal_price || course.normal_price}
                                retailBuyPrice={course.buy?.retail_price || course.retail_price}
                                normalRentPrice={course.rent?.normal_price}
                                retailRentPrice={course.rent?.retail_price}
                            />
                        </Link>
                    </div>
                ))}

                {loadingBrowseCourse && Array.from({ length: 12 }).map((_, index) => (
                    <div className="col-sm-6 col-md-4 col-xl-3 p-2" key={index}>
                        <SkeletonCardMyLearning />
                    </div>
                ))}

                {(!loadingBrowseCourse && courses.length === 0) && (
                    <p className="text-center my-5">Kelas tidak ditemukan</p>
                )}
            </div>
            {hasMore && (
                <div className="text-center my-3">
                    <button onClick={loadMoreCourses} type="button" className="btn btn-outline-secondary" disabled={loadingBrowseCourse}>
                        {loadingBrowseCourse ? "Please wait..." : "Load More"}
                    </button>
                </div>
            )}
        </section>
    );
};

export default SectionCourses;
