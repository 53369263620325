import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

export const getLatestLearn = createAsyncThunk('user/getLatestLearn', async () => {
    try {

        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/course/state/last`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const learnSlice = createSlice({
    name: 'learn',
    initialState: {
        latestLearn: [],
        loadingLearn: false
    },
    extraReducers: {
        [getLatestLearn.pending]: (state, action) => {
            state.loadingLearn = true;
        },
        [getLatestLearn.fulfilled]: (state, action) => {
            state.loadingLearn = false;
            state.latestLearn = action.payload;
        },
        [getLatestLearn.rejected]: (state, action) => {
            state.loadingLearn = false;
        }
    }
});

export default learnSlice.reducer;
