import { useEffect, useState } from "react";

const Timer = ({ duration, setExpired }) => {
    const [countDown, setCountDown] = useState(0);
    const [runTimer, setRunTimer] = useState(true);

    useEffect(() => {
        let timerId;

        if (runTimer) {
            setCountDown(60 * duration);
            timerId = setInterval(() => {
                setCountDown((countDown) => countDown - 1);
            }, 1000);
        } else {
            clearInterval(timerId);
        };

        return () => clearInterval(timerId);
    }, [duration, runTimer]);

    useEffect(() => {
        if (countDown < 0 && runTimer) {
            setRunTimer(false);
            setCountDown(0);
            if (countDown === 0) {
                setExpired(true);
            };
        };
    }, [countDown, runTimer, setExpired]);

    // const togglerTimer = () => setRunTimer((t) => !t);

    const seconds = String(countDown % 60).padStart(2, 0);
    const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

    return (
        <span className="text-muted h5">
            {minutes || '00'}:{seconds || '00'}
        </span>
    );
};

export default Timer;