import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getBanners = createAsyncThunk('banner/getBanners', async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/entry/index/slider`);
        return response.data.results;
    } catch (error) {
        throw new Error(error);
    }
});

const bannerSlice = createSlice({
    name: 'banner',
    initialState: {
        banners: [],
        loadingBanner: false
    },
    extraReducers: {
        [getBanners.pending]: (state, action) => {
            state.loadingBanner = true;
        },
        [getBanners.fulfilled]: (state, action) => {
            state.loadingBanner = false;
            state.banners = action.payload;
        },
        [getBanners.rejected]: (state, action) => {
            state.loadingBanner = false;
        }
    }
});

export default bannerSlice.reducer;
