import { faEdit, faImage, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment/moment";
import { useState, useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BannerHeader from "../../components/global/Banner/BannerHeader";
import Breadcrumb from "../../components/global/Breadcrumb/Breadcrumb";
import Input from "../../components/global/Inputs/Input/Input";
import InputFile from "../../components/global/Inputs/InputFile/InputFile";
import Layout from "../../components/global/Layout/Layout";
import { checkSubscribeExpired, deleteSubmission, getAllSubmission, getSubmissionDetail, postSubmissionData, updateSubmissionData } from "../../features/submissionKelasfullstack/submissionSlice";
import { uploadImage } from "../../utils/helper";

const SubmissionKelasfullstack = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const MySwal = withReactContent(Swal);
    const today = moment().format('YYYY-MM-DD HH:mm:ss');

    const { submissions, loadingSubmission, subscribeExpired } = useSelector((state) => state.kelasFullstackSubmission);

    const { register: registerSubmission, handleSubmit: handleSubmitSubmission, reset: resetSubmission, formState: { errors: errorsSubmission } } = useForm();

    const [isEdit, setIsEdit] = useState(false);
    const [ID, setID] = useState("");

    const [showForm, setShowForm] = useState(false);

    const [screenshotSubmission, setScreenshotSubmission] = useState('');
    const [screenshotSubmissionLoading, setScreenshotSubmissionLoading] = useState(false);

    useEffect(() => {
        dispatch(getAllSubmission());
        dispatch(checkSubscribeExpired()).then(response => {
            if (response.payload.error === 'No Content') navigate("/learn")
        })
    }, [dispatch, navigate]);

    const onSubmitSubmissionForm = (payload) => {
        let initialState = {
            ...payload,
            screenshotSubmission,
        }

        dispatch(postSubmissionData(initialState)).then(results => {
            MySwal.fire({
                icon: results.payload.status === 'success' ? 'success' : 'error',
                title: results.payload.status === 'success' ? 'Nice!' : 'Failed',
                html: results.payload.status === 'success' ? (
                    <p>Tugas berhasil dikirim. Mohon tunggu review mentor ya. Pemberitahuan review akan dikirimkan via email, WA, dan group</p>
                ) : results.payload.message,
                confirmButtonText: 'Close',
                confirmButtonColor: '#14a7a0'
            }).then(() => {
                if (results.payload.status === 'success') {
                    resetSubmission({
                        title: "",
                        github: ""
                    });
                    setScreenshotSubmission("")
                    setShowForm(false)
                }
            })
        })
    };

    const onUpdateSubmissionForm = (payload) => {
        payload.id = ID
        let initialState = {
            ...payload,
            screenshotSubmission,
        }

        dispatch(updateSubmissionData(initialState)).then(results => {
            MySwal.fire({
                icon: results.payload.status === 'success' ? 'success' : 'error',
                title: results.payload.status === 'success' ? 'Success' : 'Failed',
                html: results.payload.status === 'success' ? (
                    <p>Data has been updated.</p>
                ) : results.payload.message,
                confirmButtonText: 'Close',
                confirmButtonColor: '#14a7a0'
            }).then(() => {
                if (results.payload.status === 'success') {
                    resetSubmission({
                        title: "",
                        github: ""
                    });
                    setIsEdit(false);
                    setScreenshotSubmission("");
                    setID("");
                    setShowForm(false);
                }
            })
        })
    };

    const onEditSubmission = (id) => {
        setShowForm(true);
        setIsEdit(true);
        dispatch(getSubmissionDetail(id)).then(res => {
            resetSubmission({
                title: res.payload.title,
                github: res.payload.github_link
            });
            setScreenshotSubmission(res.payload.screenshot);
            setID(id)
        })
    }

    const onDeleteSubmission = (id) => {
        MySwal.fire({
            icon: 'question',
            title: 'Hapus Tugas?',
            showCancelButton: true,
            cancelButtonText: 'Batal',
            confirmButtonText: 'Hapus',
            confirmButtonColor: '#df4759'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteSubmission(id)).then(() => {
                    MySwal.fire('Data submission has been deleted', '', 'success')
                })
            }
        })
    };

    return (
        <>
            <Helmet>
                <title>Tugas Kelas Fullstack</title>
            </Helmet>
            <Layout>
                <section className="section" style={{ paddingTop: '150px' }}>
                    <div className="container">
                        <div className="row gx-lg-5 justify-content-center text-muted">
                            {/* Main Content */}
                            <div className="col-lg-10 px-4 px-lg-5">
                                <Breadcrumb>
                                    <li className="breadcrumb-item">
                                        <Link className="link" to="/learn">Learn</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link className="link" to="/learn/roadmaps/menjadi-fullstack-web-developer">Kelas Fullstack</Link>
                                    </li>
                                    <li className="breadcrumb-item active text-primary" aria-current="page">
                                        {document.title}
                                    </li>
                                </Breadcrumb>
                                <BannerHeader
                                    title="Tugas Kelas Fullstack"
                                    subtitle="Submit tugas Roadmap Kelas Fullstack"
                                    image="/assets/img/kelasfullstack.png"
                                >
                                    {!showForm ? (
                                        <button type="button" className="btn btn-primary" onClick={() => {
                                            setShowForm(true);
                                            setIsEdit(false);
                                        }}><FontAwesomeIcon icon={faPlusCircle} /> Kumpulkan Tugas</button>
                                    ) : null}
                                </BannerHeader>

                                <section>
                                    <div className="bg-white p-3 my-4 d-flex flex-column flex-md-row">
                                        <div>Subscribe Produk : {subscribeExpired?.start}</div>
                                        <div className="mx-3 d-none d-md-block">|</div>
                                        <div>Tanggal Deadline : {subscribeExpired?.deadline}</div>
                                    </div>
                                    {showForm ? (
                                        <>
                                            {
                                                today >= subscribeExpired?.deadline
                                                    ? (<div className="alert alert-warning">Maaf, hari ini sudah melebihi deadline pengumpulan tugas. Formulir pengiriman tugas sudah ditutup. <span onClick={() => setShowForm(false)} className="text-primary link">Kembali</span></div>)
                                                    : (
                                                        <form form onSubmit={handleSubmitSubmission(isEdit ? onUpdateSubmissionForm : onSubmitSubmissionForm)}>
                                                            <div className="row justify-content-center mt-3">
                                                                <div className="col-lg-8">
                                                                    <h2 className="h3 section-title mb-4">{!isEdit ? 'Tambah Tugas' : 'Edit Tugas'}</h2>
                                                                    <hr />

                                                                    <div className="mb-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="title"
                                                                            label="Judul Tugas"
                                                                            placeholder="Judul tugas..."
                                                                            isRequired
                                                                            register={registerSubmission}
                                                                            errors={errorsSubmission}
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="github"
                                                                            label="Github Project Link"
                                                                            subtitle={<p>Pastikan repository tidak di private</p>}
                                                                            placeholder="https://github.com/yourusername/yourproject"
                                                                            isRequired
                                                                            register={registerSubmission}
                                                                            errors={errorsSubmission}
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <InputFile
                                                                            title="Project Screenshot"
                                                                            placeholder="Upload project screenshot"
                                                                            subtitle={<p><FontAwesomeIcon icon={faImage} /> Lihat contoh <a className="link text-primary fw-bolder" href="https://image.web.id/images/1-submission.png" target="_blank" rel="noopener noreferrer">disini</a></p>}
                                                                            loading={screenshotSubmissionLoading}
                                                                            value={screenshotSubmission}
                                                                            setValue={setScreenshotSubmission}
                                                                            onChangeValue={(e) => uploadImage(e, setScreenshotSubmission, setScreenshotSubmissionLoading)}
                                                                            type="image"
                                                                        />
                                                                    </div>
                                                                    <div className="d-grid gap-2 my-5">
                                                                        {!isEdit && (
                                                                            <>
                                                                                <button type="submit" className="btn btn-primary fw-bold" disabled={loadingSubmission}>
                                                                                    {loadingSubmission ? 'Please wait...' : 'Submit'}
                                                                                </button>
                                                                                <button onClick={() => setShowForm(false)} type="button" className="btn btn-secondary fw-bold">
                                                                                    Cancel
                                                                                </button>
                                                                            </>
                                                                        )}
                                                                        {isEdit && (
                                                                            <>
                                                                                <button type="submit" className="btn btn-primary fw-bold" disabled={loadingSubmission}>
                                                                                    {loadingSubmission ? 'Please wait...' : 'Update'}
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => {
                                                                                        setShowForm(false);
                                                                                        setIsEdit(false);
                                                                                        resetSubmission({ title: '', github: '' });
                                                                                        setScreenshotSubmission("");
                                                                                    }}
                                                                                    type="button"
                                                                                    className="btn btn-secondary fw-bold"
                                                                                >
                                                                                    Cancel
                                                                                </button>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    )
                                            }

                                        </>
                                    ) : (
                                        <div className="overflow-auto">
                                            <table className="table">
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Judul Tugas</th>
                                                        <th scope="col">Screenshot</th>
                                                        <th scope="col">Github Link</th>
                                                        <th scope="col">Tanggal Submit</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        submissions?.map((submission, index) => {
                                                            return (
                                                                <tr key={index} className="text-muted">
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td>{submission.title}</td>
                                                                    <td><a href={submission.screenshot} target="_blank noreferrer" className="link"><FontAwesomeIcon icon={faImage} /> Lihat Gambar</a></td>
                                                                    <td><a href={submission.github_link} target="blank noreferrer" className="link">{submission.github_link}</a></td>
                                                                    <td>{submission.created_at}</td>
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <button onClick={() => onEditSubmission(submission.id)} type="button" className="btn btn-outline-primary btn-sm me-2">
                                                                                <FontAwesomeIcon icon={faEdit} />
                                                                            </button>
                                                                            <button onClick={() => onDeleteSubmission(submission.id)} type="button" className="btn btn-outline-danger btn-sm">
                                                                                <FontAwesomeIcon icon={faTrash} />
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {typeof submissions === 'undefined' && (<div className="text-center fst-italic">Belum ada submission</div>)}
                                        </div>
                                    )}
                                </section>
                            </div>
                            {/* End of Main Content */}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default SubmissionKelasfullstack;
