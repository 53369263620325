import { useEffect } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import ReactGA from 'react-ga';
import ProtectedRoute from "./components/global/ProtectedRoute";
import NotFound from "./pages/NofFound/NotFound";
import { unprotectedRoutes, protectedRoutes } from "./routes";
import { userRole, userToken } from "./utils/config";

const App = () => {
  // Tracking Google Analitycs
  const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <Routes>
        {/* Unprotected Routes */}
        <Route path="/" element={<Navigate to="/learn" />} />

        {unprotectedRoutes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={route.element}
            />
          );
        })}

        {/* Protected Routes */}
        {protectedRoutes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoute path={route.path} auth={userToken} role={userRole}>
                  {route.element}
                </ProtectedRoute>
              }
            />
          );
        })}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
