import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Layout from "../../../components/global/Layout/Layout";
import SectionMyCoursesLearn from "../../../components/learn/Section/SectionMyCoursesLearn/SectionMyCoursesLearn";
import { useDispatch, useSelector } from 'react-redux';
import { getMyCourses } from '../../../features/courses/courseSlice';
import { getMyRoadmaps } from '../../../features/roadmaps/roadmapSlice';

const MyCourses = () => {
    const dispatch = useDispatch();
    const { coursePage } = useSelector((state) => state.courses);

    useEffect(() => {
        dispatch(getMyCourses(coursePage));
        dispatch(getMyRoadmaps());
    }, [dispatch, coursePage]);

    return (
        <>
            <Helmet>
                <title>Kelas Saya</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <SectionMyCoursesLearn />
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default MyCourses;
