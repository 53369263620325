import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

export const getLogsCertificates = createAsyncThunk('user/getLogsCertificates', async () => {
    try {

        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/certificate/logs`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const certificatesSlice = createSlice({
    name: 'certificates',
    initialState: {
        certificates: [],
        loadingCertificates: false
    },
    extraReducers: {
        [getLogsCertificates.pending]: (state, action) => {
            state.loading = true;
        },
        [getLogsCertificates.fulfilled]: (state, action) => {
            state.loading = false;
            action.payload.status !== 'failed' && (state.certificates = action.payload.result)
        },
        [getLogsCertificates.rejected]: (state, action) => {
            state.loading = false;
        }
    }
});

export default certificatesSlice.reducer;
