import ItemRoadmap from "../../Items/ItemRoadmap/ItemRoadmap";

const ListRoadmap = ({ data }) => {
    return (
        <ul className="list-group">
            {/* Looping Course */}
            {data?.map((course, index) => {
                return (
                    <ItemRoadmap
                        key={index}
                        index={index}
                        dataLength={data.length}
                        title={course.course_title}
                        objectId={course.object_id}
                        position={course.sort}
                        categoryId={course.course_path_category_id}
                    />
                );
            })}
            {/* End of Looping Course */}
        </ul>
    )
};

export default ListRoadmap;