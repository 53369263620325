import { createSlice, createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

// Get Course by slug
export const getLesson = createAsyncThunk('lesson/getLesson', async (id) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/lesson/detail/${id}`, {
                headers: {
                    Authorization: userToken
                }
            });

            return response.data;
        }

    } catch (error) {
        return isRejectedWithValue(error);
    }
});

export const getMarkedLesson = createAsyncThunk('lesson/getMarkedLesson', async (courseId) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/lesson/marked/${courseId}`, {
                headers: {
                    Authorization: userToken
                }
            });

            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const getLessonStats = createAsyncThunk('lesson/getLessonStats', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/lesson/stats`, {
                headers: {
                    Authorization: userToken
                }
            });

            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const lessonSlice = createSlice({
    name: 'lesson',
    initialState: {
        lesson: {},
        markedLessons: [],
        lessonStats: [],
        loadingLesson: false
    },
    extraReducers: {
        // Get Lesson
        [getLesson.pending]: (state, action) => {
            state.loadingLesson = true;
        },
        [getLesson.fulfilled]: (state, action) => {
            state.loadingLesson = false;
            state.lesson = action.payload;
        },
        [getLesson.rejected]: (state, action) => {
            state.loadingLesson = true;
        },

        // Get Marked Lessons
        [getMarkedLesson.pending]: (state, action) => {
            state.loadingLesson = true;
        },
        [getMarkedLesson.fulfilled]: (state, action) => {
            state.loadingLesson = false;
            state.markedLessons = action.payload;
        },
        [getMarkedLesson.rejected]: (state, action) => {
            state.loadingLesson = true;
        },

        // Get Lesson Stats
        [getLessonStats.pending]: (state, action) => {
            state.loadingLesson = true;
        },
        [getLessonStats.fulfilled]: (state, action) => {
            state.loadingLesson = false;
            state.lessonStats = action.payload;
        },
        [getLessonStats.rejected]: (state, action) => {
            state.loadingLesson = true;
        }
    }
});

export default lessonSlice.reducer;
