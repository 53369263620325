import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import SectionLastExercise from '../../../components/exercises/Sections/SectionLastExercise/SectionLastExercise';
import SectionMyExercises from '../../../components/exercises/Sections/SectionMyExercises/SectionMyExercises';
import SectionSidebar from '../../../components/exercises/Sections/SectionSidebar/SectionSidebar';
import BannerHeader from '../../../components/global/Banner/BannerHeader';
import Layout from "../../../components/global/Layout/Layout";
import { getLatestQuiz, getMyQuizzes, getRecentQuizzes } from '../../../features/quiz/quizSlice';

const ExerciseOverview = () => {
    const dispatch = useDispatch()
    const { myQuizzes, loadingQuiz, latestQuiz, recentQuizzes } = useSelector((state) => state.quiz);

    useEffect(() => {
        dispatch(getLatestQuiz());
        dispatch(getRecentQuizzes());
        dispatch(getMyQuizzes());
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <title>Overview</title>
            </Helmet>
            <Layout>
                <section className="section mt-5" id="learnHome">
                    <div className="container-fluid">

                        <div className="alert alert-warning">Fitur ini masih dalam tahap test beta, boleh dicoba dan laporkan jika ada kendala dalam penggunaannya.</div>
                        <div className="mb-5">
                            <BannerHeader
                                title="Sejauh Mana Skill Pemrogramman Kamu?"
                                subtitle="Yuk buktikan skill coding kamu dengan exercise."
                                image="/assets/img/exercises/exercise-banner.png"
                            />
                        </div>

                        <div className="row justify-content-between">
                            <div className="col-xl-8">
                                {Object.keys(latestQuiz).length > 0 ? (
                                    <SectionLastExercise data={latestQuiz} loading={loadingQuiz} />
                                ) : null}
                                <SectionMyExercises data={myQuizzes} loading={loadingQuiz} />
                            </div>
                            <div className="col-xl-4">
                                <SectionSidebar recentQuizzes={recentQuizzes} />
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default ExerciseOverview;
