import { faSquarePollHorizontal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

const SectionInfo = () => {
    const { course, loadingCourse } = useSelector((state) => state.courses);

    return (
        <section className="card mb-3">
            <div className="card-body text-muted">
                <h5 className="mb-3"><FontAwesomeIcon className="text-primary me-2" icon={faSquarePollHorizontal} />Tentang Kelas</h5>
                {loadingCourse && <Skeleton count={5} />}
                {!loadingCourse && (
                    <div dangerouslySetInnerHTML={{ __html: course?.course?.long_description }} />
                )}
            </div>
        </section>
    );
};

export default SectionInfo;
