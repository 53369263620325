import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

// Get All Quizzes
export const getAllQuizzes = createAsyncThunk('quiz/getAllQuizzes', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/quiz?category=Exam`);
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Quizzes by tag
export const getQuizzesByTag = createAsyncThunk('quiz/getQuizzesByTag', async (tag) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/quiz?category=Exam&label=${tag}`);
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Quizzes by group
export const getQuizzesByGroup = createAsyncThunk('quiz/questions/', async (slug) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/quiz/questions/${slug}`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get latest Quiz by User
export const getLatestQuiz = createAsyncThunk('quiz/getLatestQuiz', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/quiz/latest`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Recent Quiz by User
export const getRecentQuizzes = createAsyncThunk('quiz/getRecentQuizzes', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/quiz/recent`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get My Quizzes
export const getMyQuizzes = createAsyncThunk('quiz/getMyQuizzes', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/quiz/user?category=Exam`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

// Get Detail Quiz
export const getQuiz = createAsyncThunk('quiz/getQuiz', async (slug) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/quiz/detail/${slug}`);
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Quiz History
export const getQuizHistories = createAsyncThunk('quiz/getQuizHistories', async (groupId) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/quiz/history/${groupId}`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

// Post Answers
export const postAnswers = createAsyncThunk('quiz/postAnswers', async ({ groupId, duration, sessionId, answer }) => {
    try {
        let data = {
            "group_id": groupId,
            "duration": duration,
            "session_id": sessionId,
            "answer": answer
        };

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/quiz/submit`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Post marking understand
export const postUnderstand = createAsyncThunk('quiz/postUnderstand', async ({ courseId, lessonId }) => {
    try {
        let data = {
            "course_id": courseId,
            "lesson_id": lessonId
        };

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/quiz/understand`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get My Certificate
export const getMyCertificate = createAsyncThunk('/api/certificate/claim/', async (id) => {
    try {
        let data = { object_id: id, object_type: 'exercise' }
        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/certificate/claim/`, JSON.stringify(data), {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Result info by group
export const getResultInfoByGroup = createAsyncThunk('/quiz/result', async (groupId) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/quiz/result?group_id=${groupId}`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});


const quizzesSlice = createSlice({
    name: 'quiz',
    initialState: {
        quizzes: [],
        quizzesByTag: [],
        quizzesByGroup: [],
        latestQuiz: {},
        myQuizzes: [],
        quizHistories: [],
        recentQuizzes: [],
        quiz: {},
        resultInfo: {},
        loadingQuiz: false
    },
    extraReducers: {
        // Get All Quizzes
        [getAllQuizzes.pending]: (state, action) => {
            state.loadingQuiz = true;
        },
        [getAllQuizzes.fulfilled]: (state, action) => {
            state.loadingQuiz = false;
            state.quizzes = action.payload;
        },
        [getAllQuizzes.rejected]: (state, action) => {
            state.loadingQuiz = false;
        },

        // Get Quizzes By Tag
        [getQuizzesByTag.pending]: (state, action) => {
            state.loadingQuiz = true;
        },
        [getQuizzesByTag.fulfilled]: (state, action) => {
            state.loadingQuiz = false;
            state.quizzesByTag = !action.payload.error ? action.payload : [];
        },
        [getQuizzesByTag.rejected]: (state, action) => {
            state.loadingQuiz = false;
        },

        // Get Quizzes By Group
        [getQuizzesByGroup.pending]: (state, action) => {
            state.loadingQuiz = true;
        },
        [getQuizzesByGroup.fulfilled]: (state, action) => {
            state.loadingQuiz = false;
            state.quizzesByGroup = action.payload;
        },
        [getQuizzesByGroup.rejected]: (state, action) => {
            state.loadingQuiz = false;
        },

        // Get Detail Quiz By slug
        [getQuiz.pending]: (state, action) => {
            state.loadingQuiz = true;
        },
        [getQuiz.fulfilled]: (state, action) => {
            state.loadingQuiz = false;
            state.quiz = action.payload;
        },
        [getQuiz.rejected]: (state, action) => {
            state.loadingQuiz = false;
        },

        // Get Latest Quiz
        [getLatestQuiz.pending]: (state, action) => {
            state.loadingQuiz = true;
        },
        [getLatestQuiz.fulfilled]: (state, action) => {
            state.loadingQuiz = false;
            state.latestQuiz = !action.payload.error ? action.payload : {};
        },
        [getLatestQuiz.rejected]: (state, action) => {
            state.loadingQuiz = false;
        },

        // Get Recent Quiz
        [getRecentQuizzes.pending]: (state, action) => {
            state.loadingQuiz = true;
        },
        [getRecentQuizzes.fulfilled]: (state, action) => {
            state.loadingQuiz = false;
            state.recentQuizzes = action.payload;
        },
        [getRecentQuizzes.rejected]: (state, action) => {
            state.loadingQuiz = false;
        },

        // Get My Quizzes
        [getMyQuizzes.pending]: (state, action) => {
            state.loadingQuiz = true;
        },
        [getMyQuizzes.fulfilled]: (state, action) => {
            state.loadingQuiz = false;
            state.myQuizzes = !action.payload.error ? action.payload : {};
        },
        [getMyQuizzes.rejected]: (state, action) => {
            state.loadingQuiz = false;
        },

        // Get Quiz Histories
        [getQuizHistories.pending]: (state, action) => {
            state.loadingQuiz = true;
        },
        [getQuizHistories.fulfilled]: (state, action) => {
            state.loadingQuiz = false;
            state.quizHistories = !action.payload.error ? action.payload : {};
        },
        [getQuizHistories.rejected]: (state, action) => {
            state.loadingQuiz = false;
        },

        // Get My Result Info
        [getResultInfoByGroup.pending]: (state, action) => {
            state.loadingQuiz = true;
        },
        [getResultInfoByGroup.fulfilled]: (state, action) => {
            state.loadingQuiz = false;
            state.resultInfo = !action.payload.error ? action.payload : {};
        },
        [getResultInfoByGroup.rejected]: (state, action) => {
            state.loadingQuiz = false;
        },
    }
});

export default quizzesSlice.reducer;
