import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ItemLeaderboard.module.scss';
import { faFireAlt } from '@fortawesome/free-solid-svg-icons';

const ItemLeaderboard = ({ position, avatar, rankPicture, point, name, rank, bgBlue, username, headline }) => {

    let background = null;

    if (position === 1) {
        background = styles.gold;
    } else if (position === 2) {
        background = styles.silver;
    } else if (position === 3) {
        background = styles.bronze;
    } else if (bgBlue) {
        background = styles.blue;
    };

    return (
        <li className="list-group-item px-0 px-md-auto">
            <a className="text-decoration-none text-dark" href={`${process.env.REACT_APP_URL}/coders/${username}`}>
                <div className={`card rounded-2 border-0 ${background}`}>
                    <div className="card-body">
                        <div className="row justify-content-between">
                            <div className="col-2 col-md-1 text-center my-auto">
                                <span className="fw-bolder my-auto">{position ? position : 'N/A'}</span>
                            </div>
                            <div className="col-1 d-none d-md-block my-auto">
                                {position === 1 && (
                                    <img
                                        src="https://image.web.id/images/gold.png"
                                        width={40}
                                        height={40}
                                        alt="gold"
                                    />
                                )}
                                {position === 2 && (
                                    <img
                                        src="https://image.web.id/images/silver.png"
                                        width={40}
                                        height={40}
                                        alt="silver"
                                    />
                                )}
                                {position === 3 && (
                                    <img
                                        src="https://image.web.id/images/bronze.png"
                                        width={40}
                                        height={40}
                                        alt="bronze"
                                    />
                                )}
                            </div>
                            <div className="col-10 col-lg-5 d-flex my-auto">
                                <img
                                    className="rounded-circle objectfit-cover"
                                    src={avatar || "https://image.web.id/images/Avatar.jpg"}
                                    width={60}
                                    height={60}
                                    alt={avatar}
                                />
                                <div className="ms-3 my-auto">
                                    <p className="fw-bolder mb-0">{name}</p>
                                    <p className="text-muted text-capitalize mb-0 small text-truncate" style={{ maxWidth: '20em' }}>{headline ? headline : 'Coder'}</p>
                                    <small className="d-lg-none">
                                        <span className="fw-bolder text-primary">{rank}</span>
                                        <span className="mx-1">|</span>
                                        <span className="fw-bolder">{point || 0} XP</span>
                                    </small>
                                </div>
                            </div>
                            <div className="col-3 d-none d-lg-flex my-auto">
                                <img
                                    src={rankPicture || "/assets/img/placeholder.jpg"}
                                    width={40}
                                    height={40}
                                    alt={rankPicture}
                                />
                                <p className="text-primary fw-bolder ms-3 my-auto">{rank}</p>
                            </div>
                            <div className="col-2 d-none d-lg-flex my-auto">
                                <FontAwesomeIcon className="my-auto" icon={faFireAlt} style={{ color: '#FF8B99' }} />
                                <p className="fw-bolder text-muted ms-2 mb-0">{point || 0} XP</p>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </li>
    );
};

export default ItemLeaderboard;
