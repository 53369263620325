import { useState, useEffect } from 'react';
import axios from 'axios';
import { faAt, faEnvelope, faEye, faEyeSlash, faLock, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import Footer from '../../../components/global/Footer/Footer';
import NavbarAuth from '../../../components/global/Navbar/NavbarAuth';
import { userToken } from '../../../utils/config';

const Register = () => {
    const navigate = useNavigate();

    const [isLoggin, setIsLoggin] = useState(false);
    const [inputState, setInputState] = useState({
        fullname: '',
        username: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({
        status: '',
        message: ''
    });

    useEffect(() => {
        // Validation
        if (userToken) {
            setIsLoggin(true);
            navigate('/learn')
        };
    }, [navigate]);

    // Handle input changes
    const handleChange = (e) => {
        const value = e.target.value;
        setInputState({
            ...inputState,
            [e.target.name]: value
        });
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        setLoading(true);
        let data = new FormData();
        data.append('name', inputState.fullname);
        data.append('username', inputState.username);
        data.append('email', inputState.email);
        data.append('phone', inputState.phone);
        data.append('password', inputState.password);
        data.append('confirm_password', inputState.confirmPassword);
        data.append('callback', 'https://www.codepolitan.com');

        let response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/user/auth/register`,
            headers: { "Content-Type": "multipart/form-data" },
            data: data,
        });

        if (response.data.status === "success") {
            setLoading(false);
            setInputState({
                fullname: '',
                username: '',
                email: '',
                phone: '',
                password: '',
                confirmPassword: ''
            });
            setAlert({
                status: response.data.status,
                message: response.data.message
            });
            // navigate("/login");
        } else {
            console.log(response.data);
            setLoading(false);
            setAlert({
                status: response.data.status,
                message: response.data.message
            });
        }
    };

    if (isLoggin) return (<Navigate to="/learn" replace />);

    return (
        <>
            <Helmet>
                <title>Daftar</title>
            </Helmet>
            <NavbarAuth />
            <main className="bg-light" style={{ paddingTop: '150px', minHeight: '100vh' }} id="main">
                <section className="section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5 my-auto">
                                <div className="card card-rounded border-0 shadow">
                                    <div className="card-body m-4 m-md-5 rounded-3">
                                        <div className="text-center mb-4">
                                            <h3 className="text-secondary">Daftar</h3>
                                        </div>
                                        {alert.status === 'success' && (
                                            <div className="alert alert-info alert-dismissible fade show" role="alert">
                                                {alert.message}.
                                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
                                            </div>
                                        )}
                                        {alert.status === 'failed' && (
                                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                                {alert.message}.
                                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
                                            </div>
                                        )}
                                        <form onSubmit={handleRegister}>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text bg-white border-end-0"><FontAwesomeIcon className="text-muted" icon={faUser} /></span>
                                                <input name="fullname" onChange={handleChange} value={inputState.fullname} type="text" className="form-control form-control-lg border-start-0" placeholder="Nama Lengkap" required />
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text bg-white border-end-0"><FontAwesomeIcon className="text-muted" icon={faAt} /></span>
                                                <input name="username" onChange={handleChange} value={inputState.username.replace(/\s+/g, '')} type="text" className="form-control form-control-lg border-start-0" placeholder="Username" required />
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text bg-white border-end-0"><FontAwesomeIcon className="text-muted" icon={faEnvelope} /></span>
                                                <input name="email" onChange={handleChange} value={inputState.email} type="email" className="form-control form-control-lg border-start-0" placeholder="Email" required />
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text bg-white border-end-0"><FontAwesomeIcon className="text-muted" icon={faPhone} /></span>
                                                <input name="phone" onChange={handleChange} value={inputState.phone} type="tel" className="form-control form-control-lg border-start-0" placeholder="Whatsapp" required />
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text bg-white border-end-0"><FontAwesomeIcon className="text-muted" icon={faLock} /></span>
                                                <input name="password" onChange={handleChange} value={inputState.password} type={!showPassword ? 'password' : 'text'} className={`form-control form-control-lg border-start-0 border-end-0`} placeholder="Password" required />
                                                <span className="input-group-text bg-white border-start-0" onClick={() => setShowPassword(!showPassword)} role="button">
                                                    <FontAwesomeIcon className="text-muted" icon={!showPassword ? faEye : faEyeSlash} title={!showPassword ? 'Show Password' : 'Hide Password'} />
                                                </span>
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text bg-white border-end-0"><FontAwesomeIcon className="text-muted" icon={faLock} /></span>
                                                <input name="confirmPassword" onChange={handleChange} value={inputState.confirmPassword} type={!showConfirmPassword ? 'password' : 'text'} className={`form-control form-control-lg border-start-0 border-end-0`} placeholder="Konfirmasi Password" required />
                                                <span className="input-group-text bg-white border-start-0" onClick={() => setShowConfirmPassword(!showConfirmPassword)} role="button">
                                                    <FontAwesomeIcon className="text-muted" icon={!showConfirmPassword ? faEye : faEyeSlash} title={!showConfirmPassword ? 'Show Password' : 'Hide Password'} />
                                                </span>
                                            </div>
                                            <div className="d-grid">
                                                <button type="submit" className="btn btn-primary border-0 btn-lg" disabled={loading}>
                                                    {loading && (
                                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />
                                                    )}
                                                    {loading ? 'Please wait...' : 'Daftar'}
                                                </button>
                                            </div>
                                        </form>
                                        <div className="text-center mt-4">
                                            <a className="link" href={`${process.env.REACT_APP_URL}/login`}>
                                                Sudah punya akun? Masuk
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default Register;
