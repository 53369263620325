import { Link } from 'react-router-dom';
import SkeletonCardMyLearning from '../../../skeletons/SkeletonCardMyLearning';
import CardMyLearning from '../../Card/CardMyLearning/CardMyLearning';
import CardAdd from '../../Card/CardAdd/CardAdd';
import { useDispatch, useSelector } from 'react-redux';
import CardMyRoadmap from '../../Card/CardMyRoadmap/CardMyRoadmap';
import { incrementCoursePage } from '../../../../features/courses/courseSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';

const SectionMyCoursesLearn = () => {
    const { myRoadmaps, loadingRoadmap } = useSelector((state) => state.roadmap);
    const { myCourses, loadingCourse } = useSelector((state) => state.courses);
    const dispatch = useDispatch();

    const isEmpty = !loadingCourse && !loadingRoadmap && myRoadmaps?.length === 0 && myCourses?.length === 0;
    const showLoadMoreButton = myCourses?.length > 49;

    return (
        <section className="section mb-5 min-vh-100">
            <h4 className="section-title mb-4">Kelas Saya</h4>

            <div className="row my-4">
                {(loadingRoadmap || loadingCourse) && Array.from({ length: 8 }).map((_, index) => (
                    <div key={index} className="col-sm-6 col-md-4 col-xl-3 p-2">
                        <SkeletonCardMyLearning />
                    </div>
                ))}

                {isEmpty && (
                    <div className="text-center bg-white py-4">
                        <img src="/assets/img/icon-not-found.png" width="10%" alt="Icon" />
                        <p>Sayang sekali kamu belum memiliki kelas yang bisa dipelajari</p>
                        <div className="row justify-content-center">
                            <div className="col-sm-6 col-md-4 col-xl-3 p-2">
                                <a className="link" href="https://codepolitan.com/library" target="_blank" rel="noopener noreferrer">
                                    <CardAdd text="Pilih Kelas" background="bg-primary" />
                                </a>
                            </div>
                        </div>
                    </div>
                )}

                {!loadingRoadmap && !loadingCourse && myRoadmaps?.map((item, index) => (
                    <div key={index} className="col-sm-6 col-md-4 col-xl-3 p-2">
                        <Link to={`/learn/roadmaps/${item.slug}`}>
                            <CardMyRoadmap
                                thumbnail={item.image_url}
                                title={item.name}
                                totalCourse={item.total_courses}
                                totalCompleted={item.total_completed}
                            />
                        </Link>
                    </div>
                ))}

                {!loadingRoadmap && !loadingCourse && myCourses
                    ?.filter(item => item.title !== "Panduan Member CODEPOLITAN")
                    .map((item, index) => (
                        <div key={index} className="col-sm-6 col-md-4 col-xl-3 p-2">
                            <Link className="link" to={`/learn/courses/detail/${item.slug}`}>
                                <CardMyLearning
                                    thumbnail={item.thumbnail}
                                    courseTitle={item.title}
                                    totalModule={item.total_module}
                                    totalTime={item.total_time}
                                    marked={item.marked}
                                    author={item.author}
                                />
                            </Link>
                        </div>
                    ))
                }

                {!loadingRoadmap && !loadingCourse && myCourses?.length > 0 && myCourses?.length < 49 && (
                    <div className="col-sm-6 col-md-4 col-xl-3 p-2">
                        <a className="link" href="https://codepolitan.com/library" target="_blank" rel="noopener noreferrer">
                            <CardAdd text="Tambah Kelas Baru" background="bg-primary" />
                        </a>
                    </div>
                )}
            </div>

            {showLoadMoreButton && (
                <div className="text-center my-5">
                    <button
                        onClick={() => dispatch(incrementCoursePage())}
                        type="button"
                        className="btn btn-outline-dark btn-sm rounded-0"
                    >
                        <FontAwesomeIcon icon={faRedo} /> Load more
                    </button>
                </div>
            )}
        </section>
    );
};

export default SectionMyCoursesLearn;
