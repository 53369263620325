import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

export const getNotifications = createAsyncThunk('user/getNotifications', async () => {
    try {

        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/notification`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            });
            return response.data.result;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const notificationSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications: [],
        loadingNotifications: false
    },
    extraReducers: {
        [getNotifications.pending]: (state, action) => {
            state.loadingNotification = true;
        },
        [getNotifications.fulfilled]: (state, action) => {
            state.loadingNotification = false;
            state.notifications = action.payload;
        },
        [getNotifications.rejected]: (state, action) => {
            state.loadingNotification = false;
        }
    }
});

export default notificationSlice.reducer;
