import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const CardNote = ({ index, onEdit, onDelete, courseTitle, lessonTitle, noteMinute, noteTitle, noteDescription, theme, courseSlug, lessonId }) => {
    return (
        <>
            <div className="col-md-4">
                <div className={`card ${theme === 'dark' && 'bg-soft-dark text-white'} h-100`}>
                    <div className="card-header">
                        <h5 className="my-auto">Catatan {index + 1}</h5>
                    </div>
                    <div className="card-body">
                        <p className="mb-1"><strong>{courseTitle || 'Untitled'}</strong></p>
                        <small>{lessonTitle || 'Unknown'}</small>
                    </div>
                    <div className="card-footer border-1 text-end">
                        <div className="d-flex float-end">
                            <small className="text-end">di menit ke {noteMinute || "0.00"}</small>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-8">
                <div className={`card ${theme === 'dark' && 'bg-soft-dark text-white'} h-100`}>
                    <div className="card-body">
                        <h5 className="card-title">{noteTitle || "Untitled"}</h5>
                        <p>{noteDescription || "No description"}</p>
                    </div>
                    <div className="card-footer bg-transparent border-1 text-end">
                        <div className="d-flex float-end">
                            {onEdit && onDelete ? (
                                <>
                                    <button onClick={onEdit} type="button" className="btn text-primary" data-bs-toggle="modal" data-bs-target="#notesModal"><FontAwesomeIcon icon={faEdit} /> Edit</button>
                                    <button onClick={onDelete} type="button" className="btn text-danger"><FontAwesomeIcon icon={faTrash} /> Delete</button>
                                </>
                            ) : (
                                <Link className="link" to={`/learn/courses/${courseSlug}/lessons/${lessonId}`}>Lihat Lesson</Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardNote;
