import ItemTopic from "../../Items/ItemTopic/ItemTopic";

const ListTopic = ({ data, onEditTopic, onDeleteTopic, onMoveUpTopic, onMoveDownTopic, setTopicId, onEditLesson, onDeleteLesson, onMoveUpLesson, onMoveDownLesson, setQuizId, onEditQuiz, onEditQuestion, onDeleteQuestion }) => {
    return (
        <div className="accordion" id="customAccordion">
            {/* Looping Topic */}
            {data?.map((topic, index) => {
                return (
                    <ItemTopic
                        key={index}
                        index={index}
                        topic={topic}
                        topicLength={data?.length}
                        onEdit={() => onEditTopic(topic.id)}
                        onDelete={() => onDeleteTopic(topic.id)}
                        onMoveUpTopic={() => onMoveUpTopic(topic.id, topic.topic_order)}
                        onMoveDownTopic={() => onMoveDownTopic(topic.id, topic.topic_order)}
                        onSetTopicId={() => setTopicId(topic.id)}
                        onEditLesson={onEditLesson}
                        onDeleteLesson={onDeleteLesson}
                        onMoveUpLesson={onMoveUpLesson}
                        onMoveDownLesson={onMoveDownLesson}
                        onSetQuizId={setQuizId}
                        onEditQuiz={onEditQuiz}
                        onEditQuestion={onEditQuestion}
                        onDeleteQuestion={onDeleteQuestion}
                    />
                );
            })}

            {/* End of Looping Topic */}
        </div>
    );
};

export default ListTopic;