import { Link } from "react-router-dom";

const SectionSidebar = ({ recentQuizzes }) => {
  return (
    <section className="sticky-top" style={{ top: '100px', zIndex: 2 }}>
      <div className="card">
        <div className="card-body">
          <h5 className="mb-3">Recent Completed</h5>
          {recentQuizzes?.length < 1 ? (
            <p className="text-muted my-3">Kamu belum menyelesaikan exercise apapun</p>
          ) : null}
          {recentQuizzes?.map((quiz, index) => {
            return (
              <Link className="link" to={`/exercises/detail/${quiz.slug}`} key={index}>
                <div className="row mb-3">
                  <div className="col-4">
                    <img className="img-fluid objectfit-cover" src={quiz.cover || "/assets/img/placeholder.jpg"} alt={quiz.title || "Thumbnail"} />
                  </div>
                  <div className="col-8 my-auto">
                    <h5 className="fs-6">{quiz.title || 'Untitled'}</h5>
                    <p className="m-0">Score: {quiz.score || 0}</p>
                  </div>
                </div>
              </Link>
            );
          }).slice(0, 3)}
        </div>
      </div>
    </section>
  );
};

export default SectionSidebar;
