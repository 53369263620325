import axios from "axios";
import moment from "moment";
import 'moment/locale/id';
import Swal from "sweetalert2";

// Format Date
export const formatDate = (date) => {
    return moment(date).format('LL');
};

export const formatDateTransaction = (date) => {
    return moment(date).format('lll');
};

export const formatPrice = (price) => {
    if (!price) return 0;
    return parseInt(price)?.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

// Handle upload image
export const uploadImage = async (e, setImage, setLoading) => {
    const files = e.target.files;
    const data = new FormData();
    data.append('source', files[0]);
    setLoading(true);
    try {
        let response = await axios.post(
            'https://image.web.id/api/1/upload/?key=80fae3eff42076d79f8284e9f2441fe8',
            data
        );
        setImage(response.data.image.display_url);
        setLoading(false);
    } catch (error) {
        setLoading(false);
        Swal.fire({
            title: error.response.data.error.message,
            text: error.response.data.error.message === 'Duplicated upload' ? 'Gambar kamu terdeteksi duplikat di server, coba gunakan gambar lain' : '',
            icon: 'error',
            confirmButtonText: 'Ok, paham',
            confirmButtonColor: '#14a7a0'
        });
        return error;
    }
};

// Handle upload file
export const uploadFile = (e, setFile, setLoading) => {
    setLoading(true)
    var file = e.target.files[0] //the file
    var reader = new FileReader() //this for convert to Base64 
    reader.readAsDataURL(e.target.files[0]) //start conversion...
    reader.onload = async (e) => { //.. once finished..
        var rawLog = reader.result.split(',')[1]; //extract only thee file data part
        var dataSend = { dataReq: { data: rawLog, name: file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //prepare info to send to API
        try {
            let response = await axios.post(
                'https://script.google.com/macros/s/AKfycbx-RoK7r0qshpQqBNO1G0pd-6P-CnH6g4JbPT5k0E4iqol0SmLqLez4Iv2GgLSWqia82g/exec',
                JSON.stringify(dataSend)
            );
            setFile(response.data.url);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            Swal.fire({
                title: error.response.data.error.message,
                text: error.response.data.error.message === 'Duplicated upload' ? 'Gambar kamu terdeteksi duplikat di server, coba gunakan gambar lain' : '',
                icon: 'error',
                confirmButtonText: 'Ok, paham',
                confirmButtonColor: '#14a7a0'
            });
            return error;
        }
    }
};

// Year Select
export const selectYear = [];
const currentYear = new Date().getFullYear();
for (let year = 1990; year <= currentYear; year++) {
    selectYear.push(year);
}

// Hitung rata-rata rating
export const countRates = (rate) => {
    if (rate.status === 'failed') {
        return 0;
    }

    const total = rate.reduce((acc, item) => acc + parseInt(item.rate), 0);
    const ratingValue = total / rate.length;

    return ratingValue;
}

// Get Percentage From Feedback

export const getPercentageFromFeedback = (feedback, count) => {
    // eslint-disable-next-line eqeqeq
    const total = feedback.length > 1 && feedback.map(item => item.rate).filter(item => item == count)
    let result = (total.length / feedback.length) * 100
    return Math.round(result)
}

// Format day tomorrow
export const formatDay = (date) => {
    return moment(date, "YYYY-MM-DD HH:mm:ss").fromNow();
}

// Format time discussion
export const formatDateDiscussion = (date) => {
    return moment(date).calendar();
}

// Remove HTML Tags
export const removeHTMLTags = (html) => {
    let regX = /(<([^>]+)>)/ig;
    return html.replace(regX, "");
}

// Capitalize First Letter
export const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

// reorder list
export const reorderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

// Detect backticks
export const htmlEntities = (string) => {
    if (string.match(/`/)) {
        return string;
    } else {
        return '``` \n' + string + '\n ```';
    };
};

// Custom toolbar React Simple MDE
export const customToolbar = [
    {
        name: "bold",
        action: function customFunction(editor) {
            editor.toggleBold();
        },
        className: "fa fa-bold",
        title: "Bold"
    },
    {
        name: "italic",
        action: function customFunction(editor) {
            editor.toggleItalic();
        },
        className: "fa fa-italic",
        title: "Italic"
    },
    {
        name: "heading",
        action: function customFunction(editor) {
            editor.toggleHeadingSmaller();
        },
        className: "fa fa-header",
        title: "Heading"
    },
    "|",
    {
        name: "code",
        action: function customFunction(editor) {
            editor.toggleCodeBlock();
        },
        className: "fa fa-code",
        title: "Insert Code Block"
    },
    {
        name: "quote",
        action: function customFunction(editor) {
            editor.toggleBlockquote();
        },
        className: "fa fa-quote-left",
        title: "Quote"
    },
    {
        name: "unordered-list",
        action: function customFunction(editor) {
            editor.toggleUnorderedList();
        },
        className: "fa fa-list-ul",
        title: "Generic List"
    },
    {
        name: "ordered-list",
        action: function customFunction(editor) {
            editor.toggleOrderedList();
        },
        className: "fa fa-list-ol",
        title: "Numbered List"
    },
    "|",
    {
        name: "link",
        action: function customFunction(editor) {
            editor.drawLink();
        },
        className: "fa fa-link",
        title: "Insert Link"
    },
    {
        name: "image",
        action: function customFunction(editor) {
            editor.drawImage();
        },
        className: "fa fa-picture-o",
        title: "Insert Image"
    },
    {
        name: "uploadImage",
        action: function customFunction(editor) {
            window.open('https://postimages.org/', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        },
        className: "fa fa-upload",
        title: "Upload Image"
    },
    // {
    //   name: "table",
    //   action: function customFunction(editor) {
    //     editor.drawTable();
    //   },
    //   className: "fa fa-table",
    //   title: "Insert Table"
    // },
    // {
    //   name: "horizontal-rule",
    //   action: function customFunction(editor) {
    //     editor.drawHorizontalRule();
    //   },
    //   className: "fa fa-minus",
    //   title: "Insert Horizontal Line"
    // },
    "|",
    {
        name: "preview",
        action: function customFunction(editor) {
            editor.togglePreview();
        },
        className: "fa fa-eye no-disable",
        title: "Toggle Preview"
    },
    "|",
    {
        name: "guide",
        action: function customFunction(editor) {
            // You can replace this with your preferred method of displaying the markdown guide
            window.open("https://www.markdownguide.org/basic-syntax/", "_blank");
        },
        className: "fa fa-question-circle",
        title: "Markdown Guide"
    }
];

// Copy to clipboard
export const copyText = (text) => {
    navigator.clipboard.writeText(text)
        .then(() => alert('✅ Text copied to clipboard'))
        .catch((error) => {
            throw new Error(error);
        });
};

// Decode HTML Entities
export const decodeHtml = (html) => {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
};