import { faChartSimple, faChevronRight, faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { countRates, formatPrice } from "../../../../utils/helper";
import Skeleton from "react-loading-skeleton";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import { CodeBlock } from "../../../global/CodeBlock";

const SectionBanner = () => {
    const { course, loadingCourse } = useSelector((state) => state.courses);
    const { courseFeedbacks } = useSelector((state) => state.feedbacks);

    const ratingValue = countRates(courseFeedbacks);

    return (
        <section className="bg-dark text-white" style={{ minHeight: '23em' }}>
            <div className="container-fluid p-3 p-lg-5">
                {loadingCourse && (
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="mb-5">
                                <Skeleton width="30%" />
                            </div>
                            <div className="mb-3">
                                <Skeleton width="70%" />
                                <Skeleton width="40%" />
                            </div>
                            <Skeleton width="100%" count={3} />
                        </div>
                    </div>
                )}
                {!loadingCourse && (
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="mb-5">
                                <Link className="link text-white" to="/browse-course">Browse Course</Link>
                                <FontAwesomeIcon className="small my-auto mx-2" icon={faChevronRight} />
                                <span className="text-white">{course?.course?.title}</span>
                            </div>
                            <h1 className="h3">{course?.course?.title}</h1>
                            <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                components={CodeBlock}
                            >
                                {course?.course?.description}
                            </ReactMarkdown>

                            <div className="row">
                                <div className="col-auto">
                                    <div className="d-flex">
                                        <img height={25} width={40} className="my-auto" src="https://image.web.id/images/group.png" alt="Siswa" />
                                        <p className="my-auto ms-2">{formatPrice(course?.total_student)} Siswa</p>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <p><span className="fw-bolder text-warning"><FontAwesomeIcon icon={faStarHalfStroke} /> {ratingValue ? ratingValue.toFixed(1) : 0}</span> ({formatPrice(courseFeedbacks?.length) || 0}) Penilaian</p>
                                </div>
                                <div className="col-auto">
                                    <p className="text-capitalize"><FontAwesomeIcon icon={faChartSimple} /> Level {course?.course?.level}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section >
    );
};

export default SectionBanner;
