import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
    const location = useLocation();

    return (
        <div className="sticky-top" style={{ top: '100px' }}>
            <h5 className="text-muted text-uppercase mb-3">Pengaturan</h5>
            <ul className="list-group bg-transparent" role="tablist">
                <li className="list-group-item bg-transparent border-0 ps-1">
                    <Link className={`link ${location.pathname === '/user/settings' && 'text-primary'}`} type="button" to="/user/settings">Akun</Link>
                </li>
                <li className="list-group-item bg-transparent border-0 ps-1">
                    <Link className={`link ${location.pathname === '/user/settings/avatar' && 'text-primary'}`} type="button" to="/user/settings/avatar">Avatar</Link>
                </li>
                <li className="list-group-item bg-transparent border-0 ps-1">
                    <Link className={`link ${location.pathname === '/user/settings/change-password' && 'text-primary'}`} type="button" to="/user/settings/change-password">Ganti Password</Link>
                </li>
                <li className="list-group-item bg-transparent border-0 ps-1">
                    <Link className={`link ${location.pathname === '/user/settings/interface' && 'text-primary'}`} type="button" to="/user/settings/interface">Interface</Link>
                </li>
            </ul>
        </div>
    )
}

export default Sidebar