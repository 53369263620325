import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import InputTextArea from "../Inputs/InputTextArea/InputTextArea";

const FormFeedback = () => {
    const [loading, setLoading] = useState();
    const MySwal = withReactContent(Swal);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const closeModal = document.querySelector('.btn-close');

    const onSubmitForm = async (input) => {
        const url = 'https://script.google.com/macros/s/AKfycbxogfaYKg9diKNXeHnZ1gv908GeXh1PFPzSIVfPb7GwB19ZCZkMx8d90Id0VhL0RxPz/exec';
        setLoading(true);

        let data = new FormData();
        data.append('SatisfactionLevel', input.satisfactionLevel);
        data.append('DissatisfactionReasons', input.dissatisfactionReasons);
        data.append('RecomendLevel', input.recomendLevel);
        data.append('OtherReasons', input.otherReasons);


        try {
            const response = await axios.post(url, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            return response;
        } catch (error) {
            console.log(error)
        };

        MySwal.fire({
            icon: 'success',
            title: 'Berhasil',
            text: 'Feedback berhasil dikirim!',
            confirmButtonText: 'Close',
            confirmButtonColor: '#14a7a0'
        }).then(() => {
            reset();
            setLoading(false);
            closeModal.click();
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="mb-3">
                <p className="fw-bolder">Seberapa puas kamu dalam menggunakan dashboard baru ini?</p>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" {...register("satisfactionLevel")} defaultValue="1" />
                    <label className="form-check-label">1</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" {...register("satisfactionLevel")} defaultValue="2" />
                    <label className="form-check-label">2</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" {...register("satisfactionLevel")} defaultValue="3" />
                    <label className="form-check-label">3</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" {...register("satisfactionLevel")} defaultValue="4" />
                    <label className="form-check-label">4</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" {...register("satisfactionLevel")} defaultValue="5" />
                    <label className="form-check-label">5</label>
                </div>
            </div>
            <div className="mb-3">
                <InputTextArea
                    type="text"
                    name="dissatisfactionReasons"
                    label="Jika kurang memuaskan, bagian manakah yang belum maksimal?"
                    placeholder="Tuliskan sesuatu..."
                    register={register}
                    errors={errors}
                />
            </div>
            <div className="mb-3">
                <p className="fw-bolder">Seberapa ingin kamu merekomendasikan Codepolitan ke teman-teman mu?</p>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" {...register("recomendLevel")} defaultValue="1" />
                    <label className="form-check-label">1</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" {...register("recomendLevel")} defaultValue="2" />
                    <label className="form-check-label">2</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" {...register("recomendLevel")} defaultValue="3" />
                    <label className="form-check-label">3</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" {...register("recomendLevel")} defaultValue="4" />
                    <label className="form-check-label">4</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" {...register("recomendLevel")} defaultValue="5" />
                    <label className="form-check-label">5</label>
                </div>
            </div>
            <div className="mb-3">
                <InputTextArea
                    type="text"
                    name="otherReasons"
                    label="Mungkin ada hal yang lain yang mau kamu sampaikan?"
                    placeholder="Tuliskan sesuatu..."
                    register={register}
                    errors={errors}
                />
            </div>
            <div className="mb-3 d-grid">
                <button type="submit" className="btn btn-primary text-white" disabled={loading}>{loading ? 'Please wait' : 'Kirim'}</button>
            </div>
        </form>
    );
};

export default FormFeedback;
