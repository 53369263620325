import { faFile, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InputFile = ({ title, placeholder, subtitle, value, setValue, onChangeValue, loading, disabled, type, smallText }) => {
    return (
        <>
            <label htmlFor={title} className="form-label fw-bolder">{title}</label>
            <small>{subtitle}</small>
            <input className="form-control" type="hidden" value={value} disabled={disabled} />
            <input className="form-control" onChange={onChangeValue} type="file" id={title} hidden disabled={type !== "file" && disabled} />
            <div className="input-group mb-1">
                <input
                    type="text"
                    placeholder={placeholder}
                    onChange={(e) => setValue(e.target.value)}
                    className="form-control"
                    value={value}
                    readOnly={disabled}
                    disabled={disabled}
                />
                <label className="btn btn-primary rounded" htmlFor={title}><FontAwesomeIcon icon={faUpload} /> Upload</label>
            </div>
            {smallText && !loading && !value && (<small>{smallText}</small>)}
            {loading && (
                <>
                    <span className="spinner-border spinner-border-sm me-2 text-muted" role="status" aria-hidden="true" />
                    <small className="text-muted">Please wait....</small>
                </>
            )}
            {!loading && value && (
                <>
                    {type === "image" && (<img height={200} width={200} className="objectfit-cover d-block mx-auto my-3" src={value || "/assets/img/placeholder.jpg"} alt={title} />)}
                    {type === "file" && (<a href={value} target="_blank noreferrer" className="link"><FontAwesomeIcon icon={faFile} /> See file</a>)}
                </>
            )}
        </>
    );
};

export default InputFile;