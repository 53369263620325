import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ItemQuestion = ({ index, title, onEdit, onDelete }) => {
    return (
        <li className="list-group-item bg-light border rounded-0 mb-3 text-nowrap" key={index}>
            <span className="text-muted">
                {title || "untitled"}
            </span>
            <span className="ms-2">
                <button onClick={onEdit} className="btn text-muted btn-sm shadow-none" data-bs-toggle="modal" data-bs-target="#questionForm">
                    <FontAwesomeIcon icon={faPen} />
                </button>
                <button onClick={onDelete} className="btn text-muted btn-sm shadow-none">
                    <FontAwesomeIcon icon={faTrash} />
                </button>
            </span>
        </li>
    );
};

export default ItemQuestion;