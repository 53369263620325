import { faClock, faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardCourseRoadmap = ({ thumbnail, title, totalDuration, totalModule, status, labels }) => {
    let labelsArray = labels?.split(',');

    return (
        <div className="card h-100">
            <div className="position-relative">
                <img height="150" className="card-img-top objectfit-cover" src={thumbnail || "/assets/img/placeholder.jpg"} alt={title || "Placeholder"} />
                {status ? (
                    <span className="badge bg-primary my-2 py-2 px-4 position-absolute rounded-0" style={{ zIndex: 2, bottom: '-8px', left: 0 }}><FontAwesomeIcon icon={faCheckCircle} /> Lulus</span>
                ) : null}
            </div>
            <div className="card-body">
                <h5 className="h6 text-muted fw-bold">{title || 'Untitled'}</h5>

                <div className="d-flex flex-wrap gap-2 font-monospace small">
                    {labelsArray?.map((label, index) => (
                        <span className="badge bg-light text-primary border border-primary" key={index}>{label}</span>
                    ))}
                </div>
            </div>
            <div className="card-footer bg-white text-muted">
                <div className="row justify-content-between">
                    <div className="col-auto">
                        <small>
                            <FontAwesomeIcon className="me-1" icon={faClock} />
                            {totalDuration || 0} Jam
                        </small>
                    </div>
                    <div className="col-auto">
                        <small>
                            <FontAwesomeIcon className="me-1" icon={faPlayCircle} />
                            {totalModule || 0} Materi
                        </small>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardCourseRoadmap;
