import React from 'react'
import Skeleton from 'react-loading-skeleton'

const SkeletonCardDiscussion = () => {
    return (
        <div className="card mb-3 p-3">
            <div className="d-flex flex-column">
                <div className="d-flex align-items-center gap-3">
                    <Skeleton className="rounded-circle" height={70} width={70} />
                    <div className="d-flex flex-column">
                        <Skeleton width={100} />
                        <Skeleton width={200} />
                    </div>
                </div>
                <div className="d-flex mt-3">
                    <Skeleton width={170} />
                    <div className="ms-auto me-2">
                        <Skeleton width={100} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkeletonCardDiscussion