import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/global/Breadcrumb/Breadcrumb";
import SidebarCollapse from "../../../components/global/SidebarCollapse/SidebarCollapse";
import Footer from '../../../components/global/Footer/Footer';
import { getCourse } from "../../../features/courses/courseSlice";
import { getMarkedLesson } from "../../../features/lessons/lessonSlice";
import { checkCompletedCourse, getCurrentCourseState } from "../../../features/courses/courseCheckSlice";
import Quiz from "../../../components/learn/Quiz/Quiz";
import { getQuizHistories, getQuizzesByGroup } from "../../../features/quiz/quizSlice";
import { formatDate } from "../../../utils/helper";

const CourseExam = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [startExam, setStartExam] = useState(false);
    const [limit, setLimit] = useState(10);

    const { theme } = useSelector((state) => state.theme);
    const { course } = useSelector((state) => state.courses);
    const { completedCourse } = useSelector((state) => state.completedCourse);
    const { markedLessons } = useSelector((state) => state.lesson);
    const { quizzesByGroup, quizHistories, loadingQuiz } = useSelector((state) => state.quiz);

    const dispatch = useDispatch();
    const { slug } = useParams();

    // Set sidebar default to closed in mobile device
    useEffect(() => {
        const width = window.innerWidth;
        if (width < 800) setSidebarOpen(false);
        else setSidebarOpen(true);
    }, []);

    useEffect(() => {
        dispatch(getCourse(slug));
        if (course?.course?.id !== undefined) {
            dispatch(getMarkedLesson(course?.course?.id));
            dispatch(checkCompletedCourse(course?.course?.id));
            dispatch(getCurrentCourseState(course?.course?.id));
            dispatch(getQuizzesByGroup(course?.exam?.slug));
        }
        if (quizzesByGroup?.detail?.id) {
            dispatch(getQuizHistories(quizzesByGroup?.detail?.id));
        }
    }, [dispatch, slug, course?.course?.id, course?.exam?.slug, quizzesByGroup?.detail?.id]);

    return (
        <>
            <Helmet>
                <title>{course.exam?.title}</title>
            </Helmet>
            <main id="main">

                <SidebarCollapse
                    isOpen={sidebarOpen}
                    onClickToggle={() => setSidebarOpen(!sidebarOpen)}
                    courseSlug={slug}
                    examData={course?.exam}
                    markedLessons={markedLessons}
                    lessons={course?.lessons}
                    courseIsComplete={completedCourse}
                />

                <div className={`content ${sidebarOpen && 'open'} ${theme === 'light' && 'bg-light'} ${theme === 'dark' && 'bg-dark text-white'} overflow-auto`}>
                    <div className="container p-4">
                        <Breadcrumb>
                            <li className="breadcrumb-item">
                                <Link className="link" to="/learn/courses">Kelas Saya</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link className="link" to={`/learn/courses/detail/${slug}`}>{course?.course?.title}</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {document.title}
                            </li>
                        </Breadcrumb>

                        {!startExam && (
                            <section className="section vh-100">
                                <div className={`card ${theme === 'dark' && 'bg-soft-dark'} mb-3`}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col my-auto">
                                                <h1 className={`section-title ${theme === 'dark' && 'text-white'} h4`}>{course?.exam?.title}</h1>
                                                <p className={`${theme === 'dark' ? 'text-white' : 'text-muted'} mb-4`}>Level : {course?.course?.level || ''}</p>
                                                <button onClick={() => setStartExam(true)} className="btn btn-primary">Mulai Exam</button>
                                            </div>
                                            <div className="col-auto d-none d-lg-block">
                                                <img style={{ height: '10em', width: '100%', objectFit: 'cover' }} className="card-rounded" src={course?.exam?.cover} alt={course?.exam?.title} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="alert alert-info">
                                    <h5>Informasi Ujian</h5>
                                    <p>Kerjakan semua soal yang diberikan dengan batas waktu yang tertera selama ujian. Setelah mengklik tombol Mulai maka ujian akan dimulai. Kerjakan dengan sebaik semaksimal mungkin. Semangat!</p>
                                </div>
                                <h3 className={`"section-title mt-4 mb-3"`}>History</h3>
                                <div className={`card ${theme === 'dark' ? 'bg-soft-dark' : 'bg-white'}`}>
                                    <div className="card-body overflow-auto">
                                        <table className="table">
                                            <thead className={theme === 'dark' ? 'text-white' : ''}>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Right</th>
                                                    <th scope="col">Wrong</th>
                                                    <th scope="col">Score</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody className={theme === 'dark' ? 'text-white' : ''}>
                                                {loadingQuiz && (
                                                    <tr>
                                                        <td colSpan={6} className="text-center">Loading...</td>
                                                    </tr>
                                                )}
                                                {!loadingQuiz && quizHistories?.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{formatDate(item.created_at)}</td>
                                                            <td>{item.total_right}</td>
                                                            <td>{item.total_wrong}</td>
                                                            <td>{item.score}</td>
                                                            <td>
                                                                {item.score > item.kkm ? (
                                                                    <span className="badge bg-primary text-white">Lulus</span>
                                                                ) : (
                                                                    <span className="badge bg-danger text-white">Tidak Lulus</span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                }).slice(0, limit)}
                                            </tbody>
                                        </table>
                                        {quizHistories?.length > limit && (
                                            <div className="text-center my-4">
                                                <button type="button" onClick={() => setLimit(limit + 10)} className={`btn btn-outline-${theme === 'dark' ? 'primary' : 'secondary'} btn-sm`}>Load more</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </section>
                        )}

                        {startExam && (
                            <section className="section">
                                <div className="row my-5">
                                    <div className="col">
                                        <Quiz data={course} type="exam" />
                                    </div>
                                </div>
                            </section>
                        )}
                        <Footer theme={theme} />
                    </div>
                </div >
            </main >
        </>
    );
};

export default CourseExam;