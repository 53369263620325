import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Sidebar = ({ setQuery, query }) => {
    const labels = [
        'Web',
        'Android',
        'Free',
        'Codeigniter',
        'JavaScript',
        'Laravel',
        'PHP',
        'React',
        'Vue',
        'Wordpress',
        'Node Js'
    ];

    return (
        <div className="card rounded-0">
            <div className="card-body">
                <h5 className="h6 bg-light p-3 mb-3">Rekomendasi Keywoards</h5>
                {labels.map((label, index) => {
                    return(
                        <input className="btn bg-light btn-sm text-muted me-2 mb-2" onClick={() => setQuery(label)} type="button" value={label} key={index} />
                    );
                })}
                <div className="input-group mt-4">
                    <input onChange={(e) => setQuery(e.target.value)} value={query} className="form-control border-0 border-bottom" type="search" placeholder="Cari disini..." />
                    <span className="input-group-text bg-transparent border-0 border-bottom text-muted">
                        <FontAwesomeIcon icon={faSearch} />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
