import { useState, useEffect } from 'react';
import axios from 'axios';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import Footer from '../../../components/global/Footer/Footer';
import NavbarAuth from '../../../components/global/Navbar/NavbarAuth';
import ReCAPTCHA from 'react-google-recaptcha';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { userToken } from '../../../utils/config';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    const [isLoggin, setIsLoggin] = useState(false);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const [captchaToken, setCaptchaTocken] = useState(null);

    useEffect(() => {
        // Validation

        if (userToken) {
            setIsLoggin(true);
            navigate('/learn')
        };
    }, [navigate]);

    const onChange = (token) => {
        setCaptchaTocken(token)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        let data = new FormData();
        data.append('token', captchaToken)
        data.append('email', email);
        data.append('callback', 'https://dashboard.codepolitan.com');

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/auth/reset_password`, data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
            });

            if (response.data.status === 'success') {
                setLoading(false);
                setEmail('');
                MySwal.fire({
                    icon: "success",
                    title: response.data.status,
                    text: response.data.message,
                });
            } else {
                MySwal.fire({
                    icon: "error",
                    title: response.data.status,
                    text: response.data.message,
                }).then(result => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    };
                });
            }
        } catch (error) {
            if (error.response.status === 401) {
                setLoading(false);
                MySwal.fire({
                    icon: "error",
                    title: error.response.data.status,
                    text: error.response.data.message,
                }).then(result => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    };
                });
            };
        };
    };

    if (isLoggin) return (<Navigate to="/learn" replace />);

    return (
        <>
            <Helmet>
                <title>Forgot Password</title>
            </Helmet>
            <NavbarAuth />
            <main className="bg-light vh-100" style={{ paddingTop: '150px' }} id="main">
                <section className="section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5 my-auto">
                                <div className="card card-rounded border-0 shadow">
                                    <div className="card-body m-4 m-md-5 rounded-3">
                                        <div className="text-center mb-4">
                                            <h3 className="text-secondary">Password Recovery</h3>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text bg-white border-end-0"><FontAwesomeIcon className="text-muted" icon={faEnvelope} /></span>
                                                <input name="email" onChange={(e) => setEmail(e.target.value)} value={email} type="email" className="form-control form-control-lg border-start-0" placeholder="Enter your email address..." required />
                                            </div>
                                            <div className="d-flex justify-content-center mb-3">
                                                <ReCAPTCHA
                                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                    onChange={onChange}
                                                />
                                            </div>
                                            <div className="d-grid">
                                                <button type="submit" className="btn btn-primary border-0 btn-lg" disabled={loading || !captchaToken || !email}>
                                                    {loading && (
                                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />
                                                    )}
                                                    {loading ? 'Please wait...' : 'Submit'}
                                                </button>
                                            </div>
                                        </form>
                                        <div className="text-center mt-4">
                                            <a className="link" href="https://www.codepolitan.com/login">
                                                Sudah punya akun? Masuk
                                            </a>
                                        </div>
                                        <div className="text-center mt-2">
                                            <Link className="link" to="/register">
                                                Belum punya akun? Daftar
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default ForgotPassword;
