import { formatPrice } from "../../../../utils/helper";

const CardIncome = ({ total, title, price, tax }) => {
    return (
        <div className="card py-1 border-0 shadow-sm rounded h-100 w-100">
            <div className="card-body">
                <div className="d-flex">
                    <h4 className={`${tax ? 'text-danger' : 'text-primary'} h5`}>
                        {price ? `Rp ${formatPrice(total) || 0}` : formatPrice(total) || 0}
                    </h4>
                </div>
                <div>{title}</div>
            </div>
        </div>
    );
};

export default CardIncome;
