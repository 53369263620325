import { faRedo, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteMentorRoadmap } from "../../../../features/mentor/mentorRoadmapSlice";
import { capitalizeFirstLetter, removeHTMLTags } from "../../../../utils/helper";
import CardRoadmap from "../../Cards/CardRoadmap/CardRoadmap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SkeletonCardMentorCourse from "../../../skeletons/SkeletonCardMentorCourse";

const SectionRoadmaps = ({ data, loading }) => {
    const [limit, setLimit] = useState(10);
    const [searchValue, setSearchValue] = useState('');

    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch()

    const onDeleteRoadmap = (roadmapId) => {
        MySwal.fire({
            title: 'Delete Roadmap?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result => {
            if (result.isConfirmed) {
                dispatch(deleteMentorRoadmap(roadmapId)).then((response) => {
                    // Alert
                    MySwal.fire({
                        title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                        text: removeHTMLTags(response.payload.message),
                        icon: response.payload.status === 'success' ? 'success' : 'error',
                        showConfirmButton: false,
                        timer: 2000
                    });
                });
            }
        }));
    };
    return (
        <section className="section mb-4">
            <div className="row justify-content-between">
                <div className="col-auto mb-3 mb-lg-auto">
                    <h3 className="section-title">Roadmaps</h3>
                </div>
                <div className="col-auto ms-auto">
                    <div className="input-group mb-3">
                        <span className="input-group-text bg-white border-1 text-muted border-end-0" id="basic-addon1"><FontAwesomeIcon icon={faSearch} /></span>
                        <input onChange={(e) => setSearchValue(e.target.value)} value={searchValue} type="text" className="form-control border-1 border-start-0" placeholder="Cari roadmap..." />
                    </div>
                </div>
            </div>

            <ul className="nav nav-pills border-0 text-nowrap overflow-auto flex-nowrap my-4" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link px-4 active" id="pills-all-roadmap-tab" data-bs-toggle="pill" data-bs-target="#pills-all-roadmap" type="button" role="tab" aria-controls="pills-all-roadmap" aria-selected="true">All Roadmaps</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link px-4" id="pills-active-roadmap-tab" data-bs-toggle="pill" data-bs-target="#pills-active-roadmap" type="button" role="tab" aria-controls="pills-active-roadmap" aria-selected="false">Active</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link px-4" id="pills-draft-roadmap-tab" data-bs-toggle="pill" data-bs-target="#pills-draft-roadmap" type="button" role="tab" aria-controls="pills-draft-roadmap" aria-selected="false">Draft</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link px-4" id="pills-paused-roadmap-tab" data-bs-toggle="pill" data-bs-target="#pills-paused-roadmap" type="button" role="tab" aria-controls="pills-paused-roadmap" aria-selected="false">Paused</button>
                </li>
            </ul>

            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-all-roadmap" role="tabpanel" aria-labelledby="pills-all-roadmap-tab">
                    {loading && [1, 2, 3].map(item => (
                        <SkeletonCardMentorCourse key={item} />
                    ))}
                    {
                        !loading && data.paths?.length !== 0
                            ? data.paths?.filter(path => {
                                if (path.path_name.toLowerCase().includes(searchValue.toLowerCase())) {
                                    return path;
                                }
                                return undefined;

                            }).map((roadmap, index) => {
                                return (
                                    <div key={index} className="mb-3">
                                        <CardRoadmap
                                            id={roadmap.id}
                                            pathName={roadmap.path_name}
                                            status={roadmap.status}
                                            image={roadmap.image_url}
                                            slug={roadmap.slug}
                                            rating={roadmap.rating}
                                            totalRevenue={roadmap.total_income}
                                            onDelete={() => onDeleteRoadmap(roadmap.id)}
                                        />
                                    </div>
                                )
                            }).slice(0, limit)
                            : (<div className="text-center">No data</div>)
                    }
                    {
                        data.paths?.length > limit && (
                            <div className="text-center my-4">
                                <button onClick={() => setLimit(limit + 5)} className="btn btn-outline-secondary"><FontAwesomeIcon icon={faRedo} /> Load more</button>
                            </div>
                        )
                    }
                </div>
                <div className="tab-pane fade" id="pills-active-roadmap" role="tabpanel" aria-labelledby="pills-active-roadmap-tab">
                    {loading && [1, 2, 3].map(item => (
                        <SkeletonCardMentorCourse key={item} />
                    ))}
                    {
                        !loading && data.paths?.filter(item => item.status === 'publish').length !== 0
                            ? data.paths?.filter(path => {
                                if (path.path_name.toLowerCase().includes(searchValue.toLowerCase())) {
                                    return path.status === 'publish'
                                }
                                return undefined;

                            }).map((roadmap, index) => {
                                return (
                                    <div key={index} className="mb-3">
                                        <CardRoadmap
                                            id={roadmap.id}
                                            pathName={roadmap.path_name}
                                            status={roadmap.status}
                                            image={roadmap.image_url}
                                            slug={roadmap.path_slug}
                                            rating={roadmap.rating}
                                            totalRevenue={roadmap.total_income}
                                            onDelete={() => onDeleteRoadmap(roadmap.id)}
                                        />
                                    </div>
                                )
                            }).slice(0, limit)
                            : (<div className="text-center">No data</div>)
                    }
                    {
                        data.paths?.filter(item => item.status === 'publish').length > limit && (
                            <div className="text-center my-4">
                                <button onClick={() => setLimit(limit + 5)} className="btn btn-outline-secondary"><FontAwesomeIcon icon={faRedo} /> Load more</button>
                            </div>
                        )
                    }
                </div>
                <div className="tab-pane fade" id="pills-draft-roadmap" role="tabpanel" aria-labelledby="pills-draft-roadmap-tab">
                    {loading && [1, 2, 3].map(item => (
                        <SkeletonCardMentorCourse key={item} />
                    ))}
                    {
                        !loading && data.paths?.filter(item => item.status === 'draft').length !== 0
                            ? data.paths?.filter(path => {
                                if (path.path_name.toLowerCase().includes(searchValue.toLowerCase())) {
                                    return path.status === 'draft'
                                }
                                return undefined;

                            }).map((roadmap, index) => {
                                return (
                                    <div key={index} className="mb-3">
                                        <CardRoadmap
                                            id={roadmap.id}
                                            pathName={roadmap.path_name}
                                            status={roadmap.status}
                                            image={roadmap.image_url}
                                            slug={roadmap.slug}
                                            rating={roadmap.rating}
                                            totalRevenue={roadmap.total_income}
                                            onDelete={() => onDeleteRoadmap(roadmap.id)}
                                        />
                                    </div>
                                )
                            }).slice(0, limit)
                            : (<div className="text-center">No data</div>)
                    }
                    {
                        data.paths?.filter(item => item.status === 'draft').length > limit && (
                            <div className="text-center my-4">
                                <button onClick={() => setLimit(limit + 5)} className="btn btn-outline-secondary"><FontAwesomeIcon icon={faRedo} /> Load more</button>
                            </div>
                        )
                    }
                </div>
                <div className="tab-pane fade" id="pills-paused-roadmap" role="tabpanel" aria-labelledby="pills-paused-roadmap-tab">
                    {loading && [1, 2, 3].map(item => (
                        <SkeletonCardMentorCourse key={item} />
                    ))}
                    {
                        !loading && data.paths?.filter(item => item.status === 'paused').length !== 0
                            ? data.paths?.filter(path => {
                                if (path.path_name.toLowerCase().includes(searchValue.toLowerCase())) {
                                    return path.status === 'paused'
                                }
                                return undefined;

                            }).map((roadmap, index) => {
                                return (
                                    <div key={index} className="mb-3">
                                        <CardRoadmap
                                            id={roadmap.id}
                                            pathName={roadmap.path_name}
                                            status={roadmap.status}
                                            image={roadmap.image_url}
                                            slug={roadmap.slug}
                                            rating={roadmap.rating}
                                            totalRevenue={roadmap.total_income}
                                            onDelete={() => onDeleteRoadmap(roadmap.id)}
                                        />
                                    </div>
                                )
                            }).slice(0, limit)
                            : (<div className="text-center">No data</div>)
                    }
                    {
                        data.paths?.filter(item => item.status === 'paused').length > limit && (
                            <div className="text-center my-4">
                                <button onClick={() => setLimit(limit + 5)} className="btn btn-outline-secondary"><FontAwesomeIcon icon={faRedo} /> Load more</button>
                            </div>
                        )
                    }
                </div>
            </div>

        </section>
    );
};

export default SectionRoadmaps;
