import { Helmet } from 'react-helmet-async';
import Layout from "../../../components/global/Layout/Layout";
import SectionMyDiscussion from '../../../components/learn/discussion/SectionMyDiscussions';
import SectionAllDiscussion from '../../../components/learn/discussion/SectionAllDiscussions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAllDiscussion, getMyDiscussions } from '../../../features/discussion/discussionSlice';

const Discussions = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllDiscussion());
        dispatch(getMyDiscussions());
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <title>Questions</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <SectionMyDiscussion />
                        <SectionAllDiscussion />
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default Discussions;
