import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

// Get All Submission
export const getAllSubmission = createAsyncThunk('submission/getAllSubmission', async (id) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/entry/index/codepolitan_submission`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            });
            return response.data.results;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Detail Submission
export const getSubmissionDetail = createAsyncThunk('submission/getSubmissionDetail', async (id) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/entry/detail/codepolitan_submission/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            });
            return response.data.result;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Post Submission Data
export const postSubmissionData = createAsyncThunk('submission/postSubmissionData', async (inputState, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('title', inputState.title);
        data.append('category', 'kelasfullstack');
        data.append('github_link', inputState.github);
        data.append('screenshot', inputState.screenshotSubmission);

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/entry/insert/codepolitan_submission`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getAllSubmission())
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Update Submission Data
export const updateSubmissionData = createAsyncThunk('submission/updateSubmissionData', async (inputState, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('title', inputState.title);
        data.append('category', 'kelasfullstack');
        data.append('github_link', inputState.github);
        data.append('screenshot', inputState.screenshotSubmission);

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/entry/update/codepolitan_submission/${inputState.id}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getAllSubmission())
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Delete Submission
export const deleteSubmission = createAsyncThunk('submission/deleteSubmission', async (id, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/entry/delete/codepolitan_submission/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            });
            dispatch(getAllSubmission());
            return response.data.result;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Check Subscribe Expired
export const checkSubscribeExpired = createAsyncThunk('submission/checkSubscribeExpired', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/task-check`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const submissionSlice = createSlice({
    name: 'Submission Kelas Fullstack',
    initialState: {
        loadingSubmission: false,
        submissions: [],
        subscribeExpired: {}
    },
    extraReducers: {
        // Get All Submission
        [getAllSubmission.pending]: (state, action) => {
            state.loadingSubmission = true;
        },
        [getAllSubmission.fulfilled]: (state, action) => {
            state.loadingSubmission = false;
            state.submissions = action.payload
        },
        [getAllSubmission.rejected]: (state, action) => {
            state.loadingSubmission = false;
        },

        // Post Submission
        [postSubmissionData.pending]: (state, action) => {
            state.loadingSubmission = true;
        },
        [postSubmissionData.fulfilled]: (state, action) => {
            state.loadingSubmission = false;
        },
        [postSubmissionData.rejected]: (state, action) => {
            state.loadingSubmission = false;
        },

        // Update Submission
        [updateSubmissionData.pending]: (state, action) => {
            state.loadingSubmission = true;
        },
        [updateSubmissionData.fulfilled]: (state, action) => {
            state.loadingSubmission = false;
        },
        [updateSubmissionData.rejected]: (state, action) => {
            state.loadingSubmission = false;
        },

        // Check Subscribe Expired
        [checkSubscribeExpired.pending]: (state, action) => {
            state.loadingSubmission = true;
        },
        [checkSubscribeExpired.fulfilled]: (state, action) => {
            state.loadingSubmission = false;
            if (action.payload.error !== 'No Content') state.subscribeExpired = action.payload;
        },
        [checkSubscribeExpired.rejected]: (state, action) => {
            state.loadingSubmission = false;
        },
    }
});

export default submissionSlice.reducer;