import { useEffect } from "react";
import Footer from "../Footer/Footer";
import FormFeedback from "../FormFeedback/FormFeedback";
import Modal from "../Modal/Modal";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import SidebarMobile from "../Sidebar/SidebarMobile";
import AskMentor from "../AskMentor/AskMentor";

const Layout = ({ className, children }) => {
    useEffect(() => {
        window.scrollTo({ top: '0', behavior: 'smooth' })
    }, []);

    return (
        <>
            <div className="container-fluid">
                <div className="row p-0 vh-100">
                    <div className="col-xl-2 border-end border-2 d-none d-xl-block p-3">
                        <Sidebar />
                    </div>
                    <div className="col-xl-10 bg-light p-0">
                        <Navbar />
                        <main className={className ? className : "mx-lg-4"} id="main">{children}</main>
                        <Footer />
                    </div>
                </div>
            </div>
            {/* End Main Layout */}

            {/* Moblie Sidebar */}
            <SidebarMobile />
            {/* End of Mobile Sidebar */}

            {/* Feedback Button */}
            <div className="position-fixed top-50 end-0" style={{ zIndex: '1000', marginRight: '-70px', rotate: '270deg' }}>
                <button type="button" className="btn text-white btn-primary" data-bs-toggle="modal" data-bs-target="#feedbackModal">
                    Lapor Bug & Ulasan
                </button>
            </div>
            <Modal id="feedbackModal" title="Lapor Bug & Ulasan" backdrop="static">
                <FormFeedback />
            </Modal >
            {/* End Feedback Button */}
            {/* Ask Mentor */}
            <Modal id="askMentor" title="Tanya Mentor" backdrop="static">
                <AskMentor />
            </Modal >
            {/* End of Ask Mentor */}
        </>
    );
};

export default Layout;
