import Skeleton from 'react-loading-skeleton';

const SkeletonCardCourse = () => {
    return (
        <div className="card card-rounded">
            <Skeleton className="card-img-top" width="100%" height="150px" />
            <div className="card-body">
                <small>
                    <Skeleton className="w-50" />
                </small>
                <Skeleton className="w-100" count={2} />
                <Skeleton width={140} className="mt-4" />
            </div>
            <div className="card-footer bg-white" style={{ borderRadius: '0 0 15px 15px' }}>
                <Skeleton className="w-50" />
            </div>
        </div>
    );
};

export default SkeletonCardCourse;
