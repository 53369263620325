import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import CardLatest from "../CardLatest/CardLatest";

const SectionLatest = ({ loading, tutorials }) => {
    const [limit, setLimit] = useState(10);

    return (
        <section className="section">
            <h3 className="section-title mb-5 h4">Terbaru</h3>

            {loading && [...Array(6)].map(item => (
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row" style={{ minHeight: '150px' }}>
                            <div className="col-md-4">
                                <Skeleton width="100%" height="100%" />
                            </div>
                            <div className="col-md-8">
                                <Skeleton width="80%" count={2} />
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {!loading && tutorials?.map((tutorial, index) => {
                return (
                    <a className="link" href={`https://codepolitan.com/blog/${tutorial.slug}`} target="_blank" rel="noopener noreferrer" key={index}>
                        <CardLatest
                            type={tutorial.type}
                            thumbnail={tutorial.featured_image}
                            title={tutorial.title}
                            writer={tutorial.writer}
                            createdAt={tutorial.created_at}
                            excerpt={tutorial.excerpt}
                        />
                    </a>
                );
            }).slice(0, limit)}

            {!loading && tutorials?.length < 1 && (
                <p>Ups, artikel tidak ditemukan. Coba dengan kata kunci lain...</p>
            )}

            {!loading && tutorials?.length > limit && (
                <div className="text-center my-4">
                    <button onClick={() => setLimit(limit + 10)} className="btn btn-outline-secondary btn-sm">Load more</button>
                </div>
            )}
        </section>
    );
};

export default SectionLatest;
