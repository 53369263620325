import { faArrowDown, faArrowUp, faChevronDown, faChevronUp, faPen, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ListQuestion from "../../Lists/ListQuestion/ListQuestion";

const ItemLesson = ({ index, lesson, lessonLength, onEdit, onDelete, onMoveUpLesson, onMoveDownLesson, onEditQuiz, onSetQuizId, onEditQuestion, onDeleteQuestion }) => {
    const [openItem, setOpenItem] = useState(false);

    return (

        <div className="accordion-item border rounded-0 mb-3 overflow-auto">
            <div className="accordion-header bg-light p-2 text-nowrap" id="sub-headingTwo">
                <div className="d-flex">
                    <div className="p-2">
                        {index !== 0 && (
                            <button onClick={onMoveUpLesson} className="btn text-muted shadow-none btn-sm p-0">
                                <FontAwesomeIcon className="me-2" icon={faArrowUp} />
                            </button>
                        )}
                        {index !== lessonLength - 1 && (
                            <button onClick={onMoveDownLesson} className="btn text-muted shadow-none btn-sm p-0">
                                <FontAwesomeIcon className="me-2" icon={faArrowDown} />
                            </button>
                        )}
                    </div>
                    <span className="p-2 text-muted shadow-none">
                        {lesson.lesson_title}
                    </span>
                    <button onClick={lesson.type !== 'quiz' ? onEdit : onEditQuiz} className="btn text-muted shadow-none" data-bs-toggle="modal" data-bs-target="#lessonForm">
                        <FontAwesomeIcon icon={faPen} />
                    </button>
                    <button onClick={onDelete} className="btn text-muted shadow-none">
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                    {lesson.type === 'quiz' && lesson.questions.length < 1 && (
                        <button onClick={onSetQuizId} className="btn text-muted shadow-none" data-bs-toggle="modal" data-bs-target="#questionForm">
                            <FontAwesomeIcon className="text-primary me-2" icon={faPlusCircle} />
                            Add Question
                        </button>
                    )}
                    {lesson.type === 'quiz' && (
                        <>
                            <span className="ms-auto my-auto">{lesson.questions.length} Question</span>
                            <button onClick={() => setOpenItem(!openItem)} className="btn text-muted collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target={"#sub-collapse" + index} aria-expanded="false" aria-controls={"collapse" + index}>
                                <FontAwesomeIcon className="me-2" icon={!openItem ? faChevronDown : faChevronUp} />
                            </button>
                        </>
                    )}
                </div>
            </div>
            {
                lesson.type === 'quiz' && (
                    <div id={"sub-collapse" + index} className="accordion-collapse collapse" aria-labelledby={"sub-heading" + index}>
                        <div className="accordion-body">
                            <ListQuestion
                                data={lesson}
                                onEditQuestion={onEditQuestion}
                                onDeleteQuestion={onDeleteQuestion}
                            />

                            <div className="text-center">
                                {lesson.questions.length < 1 ? (
                                    <p className="text-muted">No data</p>
                                ) : (
                                    <button onClick={onSetQuizId} className="btn text-muted shadow-none my-3" data-bs-toggle="modal" data-bs-target="#questionForm">
                                        <FontAwesomeIcon className="text-primary me-2" icon={faPlusCircle} />
                                        Add Question
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )
            }
        </div >
    );
};

export default ItemLesson;
