import React from 'react'
import Skeleton from 'react-loading-skeleton'

const CardSkeleton = () => {
    return (
        <div className="card border-0 shadow-sm">
            <Skeleton height={230} />
            <div className="card-body px-3 py-2">
                <Skeleton count={3} />
            </div>
        </div>
    )
}

export default CardSkeleton