import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ButtonBackToTop.module.scss';

const ButtonBackToTop = ({ onClick }) => {
    return (
        <button onClick={onClick} type="button" className={`btn btn-primary shadow ${styles.float}`}>
            <FontAwesomeIcon icon={faChevronUp} />
        </button>
    );
};

export default ButtonBackToTop;
