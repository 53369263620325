import { Link } from "react-router-dom";

const SectionLastExercise = ({ data, loading }) => {
    return (
        <section className="mb-5">
            <h3 className="section-title mb-4">Your Last Exercise</h3>
            <div className="card card-rounded">
                <div className="card-body">
                    <div className="row justify-content-between">
                        <div className="col-lg-4 my-2 my-lg-auto">
                            <img height="100" width="100%" className="rounded-3 objectfit-cover" src={data.cover || "/assets/img/placeholder.jpg"} alt={data.title || 'thumbnail'} />
                        </div>
                        <div className="col-lg-5 my-2 my-lg-auto">
                            <h5>{data.title || 'Untitled'}</h5>
                            <p className="mb-0">Score: {data.score || 0}</p>
                        </div>
                        <div className="col-auto my-2 my-lg-auto">
                            <Link className="btn btn-primary" to={`/exercises/detail/${data.slug}`}>See Detail</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionLastExercise;