import { faEdit, faEye, faGear, faShare, faStar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter, formatPrice } from '../../../../utils/helper';
import ButtonShareSocmed from '../../../global/Buttons/ButtonShareSocmed/ButtonShareSocmed';
import Modal from '../../../global/Modal/Modal';
import styles from './CardRoadmap.module.scss';

const CardRoadmap = ({ pathName, status, image, slug, id, onDelete, rating, totalRevenue }) => {
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row justify-content-between">
                        <div className="col-auto p-2">
                            <img className={styles.card_img} src={image || "/assets/img/placeholder.jpg"} alt="Placeholder" />
                        </div>
                        <div className="col-lg-4 my-auto mb-3 mb-lg-auto p-2">
                            <h5 className="section-title">{pathName}</h5>
                            <p className="mb-auto text-muted">{capitalizeFirstLetter(status)} | 120 Siswa</p>
                        </div>
                        <div className="col-6 col-md-2 my-auto p-2">
                            <div className="d-flex">
                                <sup>Rp</sup>
                                <h5>{formatPrice(totalRevenue) || 0}</h5>
                            </div>
                            <p className="mb-auto text-muted">Penghasilan</p>
                        </div>
                        <div className="col-6 col-md-auto my-auto p-2">
                            <h5>
                                <strong>{rating || 0}</strong>
                                <FontAwesomeIcon className="ms-1 text-warning" icon={faStar} />
                            </h5>
                            <p className="mb-auto text-muted">Rating</p>
                        </div>
                        <div className="col-md-auto my-auto p-2 text-end">
                            <div className="dropdown dropdown-menu-end">
                                <a className="btn btn-outline-primary dropdown-toggle" href="!#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FontAwesomeIcon icon={faGear} />
                                </a>
                                <ul className="dropdown-menu">
                                    {status !== 'draft' && (
                                        <li>
                                            <a className="dropdown-item text-muted" href={`/learn/roadmaps/${slug}`} target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon fixedWidth className="text-primary me-2" icon={faEye} />
                                                Overview
                                            </a>
                                        </li>
                                    )}
                                    {status !== 'publish' && (
                                        <>
                                            <li>
                                                <Link className="dropdown-item text-muted" to={`/mentor/roadmaps/edit/overview/${id}`}>
                                                    <FontAwesomeIcon fixedWidth className="text-primary me-2" icon={faEdit} />
                                                    Edit
                                                </Link>
                                            </li>
                                            <li>
                                                <button onClick={onDelete} className="dropdown-item text-muted">
                                                    <FontAwesomeIcon fixedWidth className="text-primary me-2" icon={faTrash} />
                                                    Delete
                                                </button>
                                            </li>
                                        </>
                                    )}
                                    {status !== 'draft' && (
                                        <li>
                                            <button className="dropdown-item text-muted" data-bs-toggle="modal" data-bs-target={"#shareRoadmap" + id}>
                                                <FontAwesomeIcon fixedWidth className="text-primary me-2" icon={faShare} />
                                                Share
                                            </button>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal id={"shareRoadmap" + id} title="Share to:">
                <div className="text-center">
                    <ButtonShareSocmed type="facebook" link={`https://codepolitan.com/roadmap/${slug}`} />
                    <ButtonShareSocmed type="twitter" link={`https://codepolitan.com/roadmap/${slug}`} />
                    <ButtonShareSocmed type="linkedin" link={`https://codepolitan.com/roadmap/${slug}`} />
                </div>
            </Modal>
        </>
    );
};

export default CardRoadmap;
