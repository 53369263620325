import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    Legend,
    Tooltip,
    LineElement
} from 'chart.js';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getMentorStatisticYear } from '../../../../features/mentor/mentorSlice';
import { useSelector } from 'react-redux';
import { selectYear } from '../../../../utils/helper';
import InputSelect from '../../../global/Inputs/InputSelect/InputSelect';

ChartJS.register(Legend, Tooltip, LineElement);

const SectionEarningGraphic = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { statisticYear } = useSelector((state) => state.mentor);

    const dispatch = useDispatch();

    const getGradient = (ctx, chartArea) => {
        const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(1, '#14a7a0'); // Warna teal tua
        gradient.addColorStop(0.5, '#6ddbd9'); // Warna teal cerah
        gradient.addColorStop(0, '#c9f5f2'); // Warna teal terang
        return gradient;
    };

    let earningLabels = [];
    let earningData = [];

    statisticYear?.forEach(earning => {
        earningLabels.push(earning.date);
        earningData.push(earning.total_earning);
    });


    const earnings = {
        labels: earningLabels,
        datasets: [
            {
                label: 'Earnings',
                data: earningData,
                borderColor: '#ff869a',
                tension: 0.3,
                backgroundColor: (context) => {
                    const { ctx, chartArea } = context.chart;
                    if (!chartArea) return;
                    return getGradient(ctx, chartArea);
                },
                pointBackgroundColor: '#ff869a',
                pointRadius: 5,
                pointHoverRadius: 5,
                fill: true
            }
        ]
    };

    const options = {
        title: {
            display: true,
            text: 'Earnings and Transactions'
        }
    };

    const onSubmit = (data) => {
        dispatch(getMentorStatisticYear(data.year));
    };

    return (
        <section className="mt-5">
            <form className="d-grid d-md-flex gap-2 mb-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="me-auto mt-auto">
                    <h4 className="section-title">Grafik Penghasilan Pertahun</h4>
                </div>
                <div className="col-lg-2">
                    <InputSelect
                        name="year"
                        label="Select Year"
                        register={register}
                        errors={errors}
                    >
                        {selectYear.map((option) => {
                            return (
                                <option value={option} key={option}>{option}</option>
                            );
                        }).reverse()}
                    </InputSelect>
                </div>
                <div className="col-auto mt-auto text-end text-lg-start">
                    <button type="submit" className="btn btn-primary">Submit</button>
                </div>
            </form>

            <div className="card border-0 shadow-sm mb-5">
                <div className="card-body">
                    <Line
                        options={options}
                        data={earnings}
                    />
                </div>
            </div>
        </section>
    );
};

export default SectionEarningGraphic;
