import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CardInteractiveCoding = ({ thumbnail, title }) => {
    return (
        <div className="card border-0 shadow-sm">
            <img style={{ height: '12em', objectFit: 'cover' }} src={thumbnail || "/assets/img/placeholder.jpg"} className="card-img-top" alt={title} />
            <div className="card-body">
                <h6 className="card-text fw-bold" title={title}>{title?.slice(0, 35) + '...' || 'Untitled'}</h6>
                <div className="progress mt-3 mb-1" style={{ height: '10px' }} hidden>
                    <div className="progress-bar bg-primary" role="progressbar" style={{ width: '75%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div hidden>
                    <div className="float-start">
                        <small>Lanjutkan Belajar</small>
                    </div>
                    <div className="float-end">
                        <small>75%</small>
                    </div>
                </div>
            </div>
            <div className="card-footer bg-white text-center">
                <small>
                    <FontAwesomeIcon icon={faExternalLink} className="me-1" />
                    <span>Detail</span>
                </small>
            </div>
        </div>
    );
};

export default CardInteractiveCoding;
