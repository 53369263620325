import { faFireFlameCurved } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import Modal from "../../../global/Modal/Modal";

const SectionLevel = () => {
    const { myUser } = useSelector((state) => state.user);
    const percentageRank = Math.round((myUser?.point / myUser?.rank?.next_point) * 100);

    const ranks = [
        {
            name: 'ROOKIE',
            imgUrl: 'https://image.web.id/images/Rookie.png'
        },
        {
            name: 'PRO',
            imgUrl: 'https://image.web.id/images/Pro.png'
        },
        {
            name: 'MASTER',
            imgUrl: 'https://image.web.id/images/Masster.png'
        },
        {
            name: 'VETERAN',
            imgUrl: 'https://image.web.id/images/Veteran.png'
        },
        {
            name: 'LEGEND',
            imgUrl: 'https://image.web.id/images/Legend.png'
        },
        {
            name: 'MYTHIC',
            imgUrl: 'https://image.web.id/images/Mythic.png'
        },
    ];

    let currentRank = ranks.find(rank => myUser?.rank?.rank_name?.includes(rank.name));

    return (
        <>
            <section className="section mb-4 mb-xl-5">
                <h4 className="section-title mb-4">Level Kamu</h4>

                <div className="card border-0 shadow-sm rounded-2">
                    <div className="card-body">
                        <img className="rounded-circle d-block mx-auto mb-3" height={100} width={100} src={myUser?.rank?.rank_picture || "/assets/img/placeholder.jpg"} alt={myUser?.rank?.rank_name} />
                        <h5 className="text-center">{myUser?.rank?.rank_name}</h5>
                        <div className="progress my-3">
                            <div className="progress-bar bg-salmon d-inline" role="progressbar" style={{ width: `${percentageRank}%` }} aria-valuenow={percentageRank} aria-valuemin="0" aria-valuemax="100"><FontAwesomeIcon icon={faFireFlameCurved} className="d-inline" /> {myUser?.point} Exp</div>
                        </div>
                        {myUser?.rank?.next_point && (
                            <p className="text-muted text-center">Butuh <span className="fw-bolder" style={{ color: '#ff8b99' }}>{myUser?.rank?.next_point - myUser?.point}Exp</span> menuju {myUser?.rank?.next_rank}</p>
                        )}
                        <hr />
                        <div className="d-grid">
                            <button type="button" className="btn text-primary p-0" data-bs-toggle="modal" data-bs-target="#levelModal">Lihat Semua Level</button>
                        </div>
                    </div>
                </div>
            </section>
            <Modal id="levelModal" title="Level Rank CODEPOLITAN">
                <img className="img-fluid" src={currentRank?.imgUrl} alt={currentRank?.name} />
            </Modal>
        </>
    );
};

export default SectionLevel;
