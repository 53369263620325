import Skeleton from "react-loading-skeleton";

const SkeletonCardMyLearning = () => {
    return (
        <div className="card border-0 shadow-sm h-100">
            <Skeleton className="card-img-top" height={180} width={'100%'} />
            <div className="card-body">
                <Skeleton width={200} />
                <div>
                    <Skeleton className="mt-3" width={'100%'} />
                </div>
                <div className="mt-5">
                    <div className="float-start">
                        <Skeleton width={100} />
                    </div>
                    <div className="float-end">
                        <Skeleton width={100} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkeletonCardMyLearning;