import { faCaretDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useSelector } from "react-redux";
import styles from './SectionFIlters.module.scss';

const SectionFilters = ({ courseLabel, setCourseLabel, filter, setFilter, sort, setSort }) => {
    const [label, setLabel] = useState({
        term: 'Semua Teknologi',
        term_slug: '',
        thumbnail: '/assets/img/placeholder.jpg'
    });

    const { courseLabels, loadingBrowseCourse } = useSelector((state) => state.browseCourse);

    const levelItems = [
        {
            term: 'Semua Level',
            label: '',
        },
        {
            term: 'Pemula',
            label: 'level[eq]beginner',
        },
        {
            term: 'Menengah',
            label: 'level[eq]intermediate',
        },
        {
            term: 'Mahir',
            label: 'level[eq]advance',
        },
    ];

    const categoryItems = [
        {
            term: 'Semua Kategori',
            label: '',
        },
        {
            term: 'Kelas Gratis',
            label: 'free',
        },
        {
            term: 'Web Development',
            label: 'web',
        },
        {
            term: 'Mobile Development',
            label: 'mobile',
        },
        {
            term: 'Studi Kasus',
            label: 'project',
        },
        {
            term: 'Fundamental',
            label: 'fundamental',
        },
        {
            term: 'Pemula',
            label: 'beginner',
        },
        {
            term: 'Menengah',
            label: 'intermediate',
        },
        {
            term: 'Framework',
            label: 'framework',
        },
        {
            term: 'Frontend',
            label: 'frontend',
        },
        {
            term: 'Backend',
            label: 'backend',
        },
    ];

    const sortItems = [
        {
            term: 'Kelas Terbaru',
            label: 'created_at[desc]',
        },
        {
            term: 'Kelas Terpopuler',
            label: 'popular[desc]',
        },
        {
            term: 'Kelas Terlama',
            label: 'created_at[asc]',
        },
        {
            term: 'Harga Tertinggi',
            label: 'retail_price[desc]',
        },
        {
            term: 'Harga Terendah',
            label: 'retail_price[asc]',
        },
    ];

    return (
        <section>
            <div className="d-flex flex-column flex-md-row gap-2 bg-light py-3">
                <div className="btn-group">
                    <button type="button" className="btn d-flex justify-content-center bg-white" data-bs-toggle="dropdown" aria-expanded="false">
                        <img width={20} height={20} className="rounded-1 objectfit-cover my-auto me-2 d-none d-md-block" src={label.thumbnail} alt={label.term} />
                        <p className="my-auto d-none d-md-block">{label.term}</p>
                        <p className="my-auto d-md-none">Filter</p>
                        <FontAwesomeIcon className="my-auto ms-2 small" icon={faCaretDown} />
                    </button>
                    <div className={`dropdown-menu overflow-auto shadow p-3 ${styles.mega_dropdown}`}>
                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-3 p-2">
                                <button
                                    onClick={() => {
                                        setCourseLabel('');
                                        setLabel({
                                            term: 'Semua Teknologi',
                                            term_slug: '',
                                            thumbnail: '/assets/img/placeholder.jpg'
                                        });
                                    }}
                                    type="button"
                                    className="d-flex bg-transparent border-0 text-start dropdown-item"
                                >
                                    <img width={20} height={20} className="rounded-1 objectfit-cover my-auto me-2" src="/assets/img/placeholder.jpg" alt="Placeholder" />
                                    <p className={`my-auto ${courseLabel === '' ? 'fw-bolder' : null}`}>Semua Teknologi</p>
                                </button>
                            </div>
                            {!loadingBrowseCourse && courseLabels?.map((label, index) => {
                                return (
                                    <div className="col-12 col-md-4 col-lg-3 p-2" key={index}>
                                        <button
                                            onClick={() => {
                                                setCourseLabel(label.term_slug);
                                                setLabel(label)
                                            }}
                                            type="button"
                                            className="d-flex bg-transparent border-0 text-start dropdown-item"
                                        >
                                            <img width={20} height={20} className="rounded-1 objectfit-cover my-auto me-2" src={label.thumbnail || "/assets/img/placeholder.jpg"} alt="Placeholder" />
                                            <p className={`my-auto ${courseLabel === label.term_slug ? 'fw-bolder' : null}`}>{label.term}</p>
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="btn-group">
                    <button type="button" className="btn bg-white dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        Kategori
                    </button>
                    <ul className="dropdown-menu shadow">
                        {categoryItems?.map((item, index) => {
                            return (
                                <li key={index}>
                                    <button onClick={() => setCourseLabel(item.label)} type="button" className={`dropdown-item ${courseLabel === item.label ? 'fw-bolder' : null}`}>{item.term}</button>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="btn-group">
                    <button type="button" className="btn bg-white dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        Level
                    </button>
                    <ul className="dropdown-menu shadow">
                        {levelItems?.map((item, index) => {
                            return (
                                <li key={index}>
                                    <button onClick={() => setFilter(item.label)} type="button" className={`dropdown-item ${filter === item.label ? 'fw-bolder' : null}`}>{item.term}</button>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="btn-group ms-md-auto">
                    <button type="button" className="btn bg-white dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <FontAwesomeIcon className="small" icon={faFilter} /> Urutkan
                    </button>
                    <ul className="dropdown-menu shadow">
                        {sortItems?.map((item, index) => {
                            return (
                                <li key={index}>
                                    <button onClick={() => setSort(item.label)} type="button" className={`dropdown-item ${sort === item.label ? 'fw-bolder' : null}`}>{item.term}</button>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default SectionFilters;
