import Skeleton from "react-loading-skeleton"

const SkeletonItemLeaderboard = () => {
    return (
        <div className="card rounded-2 mb-2">
            <div className="card-body">
                <div className="row">
                    <div className="col col-md-6 my-auto">
                        <div className="row">
                            <div className="col-auto">
                                <Skeleton className="rounded-circle" style={{ width: '60px', height: '60px' }} />
                            </div>
                            <div className="col">
                                <Skeleton width="60%" />
                                <Skeleton width="40%" />
                            </div>
                        </div>
                    </div>
                    <div className="col-3 my-auto d-none d-md-block">
                        <Skeleton width="50%" />
                    </div>
                    <div className="col-3 my-auto d-none d-md-block">
                        <Skeleton width="50%" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkeletonItemLeaderboard