import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

// Get Detail Product
export const getMentorCourseProduct = createAsyncThunk('mentor/getMentorCourseProduct', async (id) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/course/detail/${id}`, {
                headers: {
                    Authorization: userToken
                }
            });

            return response.data.product;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Update Mentor Course Product
export const updateMentorCourseProduct = createAsyncThunk('mentor/updateMentorCourseProduct', async ({ id, payload }) => {
    try {

        let data = {
            "product_id": id,
            "normal_price": payload.normalPrice,
            "retail_price": payload.retailPrice
        };

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/course/updatePricing`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Detail Product
export const getMentorRoadmapProduct = createAsyncThunk('mentor/getMentorRoadmapProduct', async (id) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/course/detailRoadmap/${id}`, {
                headers: {
                    Authorization: userToken
                }
            });

            return response.data.product;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Update Mentor Roadmap Product
export const updateMentorRoadmapProduct = createAsyncThunk('mentor/updateMentorRoadmapProduct', async (data) => {
    const payload = JSON.stringify(data)
    try {
        if (userToken) {
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/course/updateRoadmapPricing`, payload, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

const mentorProductSlice = createSlice({
    name: 'mentorProduct',
    initialState: {
        mentorCourseProduct: [],
        mentorRoadmapProduct: [],
        loadingMentorProduct: false
    },
    extraReducers: {
        // Get Detail Product
        [getMentorCourseProduct.pending]: (state, action) => {
            state.loadingMentorProduct = true;
        },
        [getMentorCourseProduct.fulfilled]: (state, action) => {
            state.loadingMentorProduct = false;
            state.mentorCourseProduct = action.payload;
        },
        [getMentorCourseProduct.rejected]: (state, action) => {
            state.loadingMentorProduct = false;
        },

        // Update Course Product
        [updateMentorCourseProduct.pending]: (state, action) => {
            state.loadingMentorProduct = true;
        },
        [updateMentorCourseProduct.fulfilled]: (state, action) => {
            state.loadingMentorProduct = false;
        },
        [updateMentorCourseProduct.rejected]: (state, action) => {
            state.loadingMentorProduct = false;
        },

        // Get Detail Course Product
        [getMentorRoadmapProduct.pending]: (state, action) => {
            state.loadingMentorProduct = true;
        },
        [getMentorRoadmapProduct.fulfilled]: (state, action) => {
            state.loadingMentorProduct = false;
            state.mentorRoadmapProduct = action.payload;
        },
        [getMentorRoadmapProduct.rejected]: (state, action) => {
            state.loadingMentorProduct = false;
        },

        // Update Course Product
        [updateMentorRoadmapProduct.pending]: (state, action) => {
            state.loadingMentorProduct = true;
        },
        [updateMentorRoadmapProduct.fulfilled]: (state, action) => {
            state.loadingMentorProduct = false;
        },
        [updateMentorRoadmapProduct.rejected]: (state, action) => {
            state.loadingMentorProduct = false;
        },
    }
});

export default mentorProductSlice.reducer;
