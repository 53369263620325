import { faEye, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "../../../../utils/helper";

const CardPopular = ({ type, title, writer, createdAt, totalSeen }) => {
    return (
        <div className="card shadow-sm h-100">
            <div className="card-body">
                {type === 'post' && (<span className="badge bg-primary mb-2">Article</span>)}
                {type === 'tutorial' && (<span className="badge bg-danger mb-2">Tutorial</span>)}
                {type === 'event' && (<span className="badge bg-info mb-2">Event</span>)}
                <h5 className="card-text text-ellipsis-2 my-3">{title || 'Untitled'}</h5>
                <p className="mb-0">
                    <small className="text-muted">
                        <i>
                            <FontAwesomeIcon icon={faUser} /> {writer || 'Unknown'} &bull; {formatDate(createdAt)}
                        </i>
                    </small>
                </p>
                <p className="mb-0">
                    <small className="text-muted">
                        <FontAwesomeIcon icon={faEye} /> {totalSeen || 0}
                    </small>
                </p>
            </div>
        </div>
    );
};

export default CardPopular;