import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

const NotFound = () => {
    return (
        <>
            <Helmet>
                <title>404 Not Found - Codepolitan</title>
            </Helmet>
            <div className="container my-4 py-4">
                <div className="row">
                    <div className="col text-center">
                        <img className="img-fluid" src="/assets/img/oops.png" alt="Oops! Halaman Tidak ditemukan" />
                        <h1 style={{ fontWeight: 'bold', fontSize: '2rem' }}>404 - Page Not Found</h1>
                        <p className="lead text-muted mb-5">
                            Sepertinya halaman yang kamu cari tidak ditemukan.
                        </p>
                        <Link className="btn btn-primary px-5 py-3" style={{ backgroundColor: '#14a7a0', color: 'white', border: 'none' }} to="/">
                            Kembali
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotFound;
