import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './CardAdd.module.scss';

const CardAdd = ({ text, background }) => {
    return (
        <div className={`card ${background} border-0 h-100 rounded-2 ${styles.card_add}`}>
            <div className="card-body d-flex flex-column text-white text-center h-100 align-items-center justify-content-center">
                <FontAwesomeIcon size="5x" icon={faFolderPlus} />
                <p className="mt-3 fw-bolder">{text}</p>
            </div>
        </div>
    )
}

export default CardAdd;
