import { faCircleInfo, faComment, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const CardNotification = ({ type, message, date, rawdate, navbar, slug, uri, notifTime }) => {
    let badgeType = '';
    let iconType = '';
    let typeText = '';
    let link = '#';

    switch (type) {
        case 'forum-reply':
            badgeType = 'danger';
            iconType = faComment;
            typeText = 'Jawaban Pertanyaan';
            link = slug ? `${process.env.REACT_APP_URL}/forum/thread/${slug}` : '#';
            break;
        case 'forum-comment':
            badgeType = 'danger';
            iconType = faComment;
            typeText = 'Komentar Pertanyaan';
            link = slug ? `${process.env.REACT_APP_URL}/forum/thread/${slug}` : '#';
            break;
        case 'reply':
            badgeType = 'dark';
            iconType = faComment;
            typeText = 'Komentar Diskusi';
            link = slug ? `${process.env.REACT_APP_URL}/blog/${slug}` : uri || '#';
            break;
        case 'comment':
            badgeType = 'dark';
            iconType = faComment;
            typeText = 'Balasan Diskusi';
            link = slug ? `${process.env.REACT_APP_URL}/blog/${slug}` : uri || '#';
            break;
        case 'information':
            badgeType = 'info';
            iconType = faCircleInfo;
            typeText = 'Informasi';
            link = '#'; // No slug needed for information type
            break;
        case 'thread':
            badgeType = 'warning';
            iconType = faExclamationTriangle;
            typeText = 'Perhatian';
            link = '#'; // No slug needed for thread type
            break;
        default:
            break;
    }

    const handleClick = (e) => {
        if (!slug && !uri && type !== 'information' && type !== 'thread') {
            e.preventDefault(); // Prevent link navigation if no slug/uri and it's not 'information' or 'thread'
        }
    };

    return (
        <Link
            className="link"
            to={link}
            onClick={handleClick}
            style={{
                pointerEvents: slug || uri || type === 'information' || type === 'thread' ? 'auto' : 'none',
                cursor: slug || uri || type === 'information' || type === 'thread' ? 'pointer' : 'not-allowed',
            }}
        >
            <div className={`card ${rawdate > notifTime ? 'border-3 border-primary' : 'border-0'} ${navbar && 'bg-light'}`}>
                <div className="card-body">
                    <div className="py-2">
                        <span className={`badge border border-${badgeType} text-${badgeType} py-2`}>
                            <FontAwesomeIcon icon={iconType} /> {typeText}
                        </span>
                        <span className="text-muted mx-2">|</span>
                        <small>
                            <span className="text-muted">{date}</span>
                        </small>
                    </div>
                    <div className="my-2">
                        <p className="text-muted" dangerouslySetInnerHTML={{ __html: message }} />
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default CardNotification;
