// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

SwiperCore.use([Navigation, Pagination]);

const AskMentor = () => {
    return (
        <>
            <Swiper
                id="learnHome"
                spaceBetween={50}
                slidesPerView={1}
                navigation={true}
                pagination={{ clickable: true }}
                style={{ paddingBottom: '60px' }}
            >
                <SwiperSlide>
                    <p className="mb-3">Buka Kelas yang ingin ditanyakan. Klik pelajaran. Scroll sampai bawah dan temukan Buat Pertanyaan Baru.</p>
                    <img className="img-fluid rounded-3" src="https://image.web.id/images/Screenshot_2023-05-31_at_11.34.28.png" alt="Scroll Ke bawah" />
                </SwiperSlide>
                <SwiperSlide>
                    <p className="mb-3">Isi judul dan detail pertanyaan, lalu klik Post Discussion.</p>
                    <img className="img-fluid rounded-3" src="https://image.web.id/images/Screenshot_2023-05-31_at_11.35.19.png" alt="Scroll Ke bawah" />
                </SwiperSlide>
                <SwiperSlide>
                    <div className="text-center mt-5">
                        <img height={150} width={150} className="img-fluid" src="https://image.web.id/images/2022/06/15/ea2ea84daf797c37bd19ae032482121e.png" alt="Maskot" loading="lazy" />
                        <p className="mt-3">Mudah bukan?</p>
                        <button type="button" className="btn btn-primary text-white" data-bs-dismiss="modal">Ok, Sudah Paham</button>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
};

export default AskMentor;
