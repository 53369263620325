import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken, username } from "../../utils/config";

// Get Thread By Mentor
export const getThreadByMentor = createAsyncThunk('mentor/getThreadByMentor', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/forum/thread/mentor?page=1&mentor_id=42921`);
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Mentor Statistic Statistic
export const getMentorEarningStats = createAsyncThunk('mentor/getMentorEarningStats', async (payload) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/mentor/earning-chart${payload || ''}`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Mentor Statistic Statistic
export const getMentorTransactionStats = createAsyncThunk('mentor/getMentorTransactionStats', async (payload) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/mentor/transaction-chart${payload || ''}`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Mentor Statistic
export const getMentorStatistic = createAsyncThunk('mentor/getMentorStatistic', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/mentor/statistic`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Mentor Statistic Year
export const getMentorStatisticYear = createAsyncThunk('mentor/getMentorStatisticYear', async (year) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/mentor/statistic-year/${year}`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Mentor Transaction
export const getMentorTransaction = createAsyncThunk('mentor/getMentorTransaction', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/mentor/transaction`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Detail Profile Mentor
export const getMentorProfile = createAsyncThunk('mentor/getMentorProfile', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/mentor/${username}`);
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Update Mentor Profile
export const updateMentorProfile = createAsyncThunk('mentor/updateMentorProfile', async (data) => {
    try {
        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/mentor/settings`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            return response;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const mentorSlice = createSlice({
    name: 'mentor',
    initialState: {
        threads: [],
        transactions: [],
        statistic: [],
        statisticYear: [],
        earningStats: [],
        transactionStats: [],
        loadingMentor: false
    },
    extraReducers: {

        // Get Mentor Transaction
        [getMentorStatistic.pending]: (state, action) => {
            state.loadingMentor = true;
        },
        [getMentorStatistic.fulfilled]: (state, action) => {
            state.loadingMentor = false;
            state.statistic = action.payload.result
        },
        [getMentorStatistic.rejected]: (state, action) => {
            state.loadingMentor = false;
        },

        // Get Mentor Transaction
        [getMentorStatisticYear.pending]: (state, action) => {
            state.loadingMentor = true;
        },
        [getMentorStatisticYear.fulfilled]: (state, action) => {
            state.loadingMentor = false;
            state.statisticYear = action.payload.result
        },
        [getMentorStatisticYear.rejected]: (state, action) => {
            state.loadingMentor = false;
        },

        // Get Mentor Transaction
        [getMentorTransaction.pending]: (state, action) => {
            state.loadingMentor = true;
        },
        [getMentorTransaction.fulfilled]: (state, action) => {
            state.loadingMentor = false;
            state.transactions = action.payload.result
        },
        [getMentorTransaction.rejected]: (state, action) => {
            state.loadingMentor = false;
        },

        // Get Mentor Earnings
        [getMentorEarningStats.pending]: (state, action) => {
            state.loadingMentor = true;
        },
        [getMentorEarningStats.fulfilled]: (state, action) => {
            state.loadingMentor = false;
            state.earningStats = action.payload.result;
        },
        [getMentorEarningStats.rejected]: (state, action) => {
            state.loadingMentor = false;
        },

        // Get Mentor Transaction
        [getMentorTransactionStats.pending]: (state, action) => {
            state.loadingMentor = true;
        },
        [getMentorTransactionStats.fulfilled]: (state, action) => {
            state.loadingMentor = false;
            state.transactionStats = action.payload.result
        },
        [getMentorTransactionStats.rejected]: (state, action) => {
            state.loadingMentor = false;
        },

        // Get Threads Mentor
        [getThreadByMentor.pending]: (state, action) => {
            state.loadingMentor = true;
        },
        [getThreadByMentor.fulfilled]: (state, action) => {
            state.loadingMentor = false;
            state.threads = action.payload
        },
        [getThreadByMentor.rejected]: (state, action) => {
            state.loadingMentor = false;
        },

        // Get Detail Course
        [getMentorProfile.pending]: (state, action) => {
            state.loadingMentor = false;
        },
        [getMentorProfile.fulfilled]: (state, action) => {
            state.loadingMentor = false;
        },
        [getMentorProfile.rejected]: (state, action) => {
            state.loadingMentor = false;
        },

        // Update mentor profile
        [updateMentorProfile.pending]: (state, action) => {
            state.loadingMentor = true;
        },
        [updateMentorProfile.fulfilled]: (state, action) => {
            state.loadingMentor = false;
        },
        [updateMentorProfile.rejected]: (state, action) => {
            state.loadingMentor = false;
        },
    }
});

export default mentorSlice.reducer;
