import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import SectionBrowseExercise from '../../components/exercises/Sections/SectionBrowseExercise/SectionBrowseExercise';
import SectionExercisesByTopic from '../../components/exercises/Sections/SectionExercisesByTopic/SectionExercisesByTopic';
import SectionSlideExercises from '../../components/exercises/Sections/SectionSlideExercises/SectionSlideExercises';
import Layout from "../../components/global/Layout/Layout";
import { getAllQuizzes } from '../../features/quiz/quizSlice';

const Exercises = () => {
    const dispatch = useDispatch()
    const { quizzes, loadingQuiz } = useSelector((state) => state.quiz);

    useEffect(() => {
        dispatch(getAllQuizzes());
    }, [dispatch]);

    const exerciseTags = [
        {
            name: 'Laravel',
            thumbnail: 'https://i.ibb.co/Vt2Z7yh/Laravel.png',
            slug: 'laravel'
        },
        {
            name: 'VueJs',
            thumbnail: 'https://i.ibb.co/0ypkbcv/Vue.png',
            slug: 'vuejs'
        },
        {
            name: 'Java',
            thumbnail: 'https://i.ibb.co/S06TzGZ/Java.png',
            slug: 'java'
        },
        {
            name: 'ReactJs',
            thumbnail: 'https://i.ibb.co/hHG9Lfs/React.png',
            slug: 'react'
        },
        {
            name: 'JavaScript',
            thumbnail: 'https://i.ibb.co/RTYV8Kk/Js.png',
            slug: 'javascript'
        },
        {
            name: 'Kotlin',
            thumbnail: 'https://i.ibb.co/XCVNS0R/Kotlin.png',
            slug: 'kotlin'
        },
        {
            name: 'PHP',
            thumbnail: 'https://i.ibb.co/Nmm1RBR/Php.png',
            slug: 'php'
        },
        {
            name: 'MySQL',
            thumbnail: 'https://i.ibb.co/zSJjcNN/MySql.png',
            slug: 'mysql'
        },
        {
            name: 'Spring',
            thumbnail: 'https://i.ibb.co/9qjDbS0/Spring.png',
            slug: 'spring'
        },
        {
            name: 'Git',
            thumbnail: 'https://i.ibb.co/ZTnmzzF/Git.png',
            slug: 'git'
        },
        {
            name: 'Dart',
            thumbnail: 'https://i.ibb.co/VvbtWDh/Dart.png',
            slug: 'dart'
        },
        {
            name: 'Docker',
            thumbnail: 'https://i.ibb.co/w0LPb7L/Docker.png',
            slug: 'docker'
        },
    ];
    const beginnerQuizzes = quizzes?.filter(quiz => quiz.level === "Beginner");
    const intermediateQuizzes = quizzes?.filter(quiz => quiz.level === "Intermediate");
    const masterQuizzes = quizzes?.filter(quiz => quiz.level === "Master");

    return (
        <>
            <Helmet>
                <title>Exercises</title>
            </Helmet>
            <Layout>
                <section className="section mt-5" id="learnHome">
                    <div className="container-fluid">
                        <SectionExercisesByTopic
                            data={exerciseTags}
                            loading={loadingQuiz}
                        />
                        <SectionSlideExercises
                            title="Beginner Exercises"
                            data={beginnerQuizzes}
                            loading={loadingQuiz}
                        />
                        <SectionSlideExercises
                            title="Intermediate Exercises"
                            data={intermediateQuizzes}
                            loading={loadingQuiz}
                        />
                        <SectionSlideExercises
                            title="Master Exercises"
                            data={masterQuizzes}
                            loading={loadingQuiz}
                        />
                        <SectionBrowseExercise
                            data={quizzes}
                            loading={loadingQuiz}
                        />
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default Exercises;
