const InputTextArea = ({ name, label, isRequired, readOnly, register, errors, placeholder }) => {
    return (
        <>
            <label htmlFor={name} className="form-label fw-bolder">{label}{isRequired && <span className="text-danger">*</span>}</label>
            <textarea
                className="form-control"
                placeholder={placeholder || label}
                rows="3"
                readOnly={readOnly}
                {...register(name, { required: isRequired })}
            />
            {errors?.name && errors?.name?.type === 'required' && <span className="text-danger">This field is required</span>}
        </>
    );
};

export default InputTextArea;