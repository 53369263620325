import { mentormenus } from "../../global/Sidebar/sidebarmenus";
import SidebarItem from "./SidebarItem";

const Sidebar = () => {
    return (
        <div className="position-sticky mb-5" style={{ top: '100px' }}>
            <nav className="nav nav-pills flex-column border-0">
                {mentormenus.map((item, index) => {
                    return (
                        <SidebarItem
                            key={index}
                            title={item.title}
                            link={item.link}
                            icon={item.icon}
                        />
                    );
                })}
            </nav>
        </div>
    );
};

export default Sidebar;
