import { faComments, faEdit, faEye, faGear, faShare, faStar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter, formatPrice } from '../../../../utils/helper';
import ButtonShareSocmed from '../../../global/Buttons/ButtonShareSocmed/ButtonShareSocmed';
import Modal from '../../../global/Modal/Modal';
import styles from './CardCourse.module.scss';

const CardCourse = ({ thumbnail, title, status, totalStudent, totalRevenue, totalDiscussion, totalRating, slug, id, onDelete }) => {
    return (
        <>

            <div className="card">
                <div className="card-body">
                    <div className="row gx-3">
                        <div className="col-6 col-lg-auto">
                            <img className={styles.card_img} src={thumbnail || "/assets/img/placeholder.jpg"} alt={title || "Placeholder"} />
                        </div>
                        <div className="col-6 col-lg-3 my-auto">
                            <a className="link" href={`/learn/courses/detail/${slug}`} target="_blank" rel="noopener noreferrer">
                                <h5 className="section-title">{title || "Untitled"}</h5>
                            </a>
                            <p className="mb-auto text-muted">{status ? capitalizeFirstLetter(status) : "Unknown"} | {totalStudent || 0} Siswa</p>
                        </div>
                        <div className="col-12 col-lg-6 my-3 my-lg-auto">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <div className="d-flex">
                                        <sup>Rp</sup>
                                        <h5>{formatPrice(totalRevenue) || 0}</h5>
                                    </div>
                                    <p className="mb-auto text-muted">Penghasilan</p>
                                </div>
                                <div className="col">
                                    <h5>
                                        <strong>{totalDiscussion || 0}</strong>
                                        <FontAwesomeIcon className="ms-1" icon={faComments} />
                                    </h5>
                                    <p className="mb-auto text-muted">Diskusi</p>
                                </div>
                                <div className="col">
                                    <h5>
                                        <strong>{totalRating || 0}</strong>
                                        <FontAwesomeIcon className="ms-1 text-warning" icon={faStar} />
                                    </h5>
                                    <p className="mb-auto text-muted">Rating</p>
                                </div>
                                <div className="col-auto my-auto ms-auto">
                                    <div className="dropdown dropdown-menu-end">
                                        <a className="btn btn-outline-primary dropdown-toggle" href="!#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon icon={faGear} />
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a className="dropdown-item text-muted" href={`/learn/courses/detail/${slug}`} target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon fixedWidth className="text-primary me-2" icon={faEye} />
                                                    Preview
                                                </a>
                                            </li>
                                            {status !== 'publish' && (
                                                <>
                                                    <li>
                                                        <Link className="dropdown-item text-muted" to={`/mentor/courses/edit/overview/${id}`}>
                                                            <FontAwesomeIcon fixedWidth className="text-primary me-2" icon={faEdit} />
                                                            Edit
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <button onClick={onDelete} className="dropdown-item text-muted">
                                                            <FontAwesomeIcon fixedWidth className="text-primary me-2" icon={faTrash} />
                                                            Delete
                                                        </button>
                                                    </li>
                                                </>
                                            )}
                                            {status !== 'draft' && status !== 'invicible' && (
                                                <>
                                                    <li>
                                                        <a className="dropdown-item text-muted" href={`/learn/discussions`} target="_blank" rel="noopener noreferrer">
                                                            <FontAwesomeIcon fixedWidth className="text-primary me-2" icon={faComments} />
                                                            Discussions
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item text-muted" data-bs-toggle="modal" data-bs-target={"#shareCourse" + id}>
                                                            <FontAwesomeIcon fixedWidth className="text-primary me-2" icon={faShare} />
                                                            Share
                                                        </button>
                                                    </li>
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal id={"shareCourse" + id} title="Share to:">
                <div className="text-center">
                    <ButtonShareSocmed type="facebook" link={`https://codepolitan.com/course/intro/${slug}`} />
                    <ButtonShareSocmed type="twitter" link={`https://codepolitan.com/course/intro/${slug}`} />
                    <ButtonShareSocmed type="linkedin" link={`https://codepolitan.com/course/intro/${slug}`} />
                </div>
            </Modal>
        </>
    );
};

export default CardCourse;
