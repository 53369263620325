import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

// Get Menror Courses
export const getMentorCourses = createAsyncThunk('mentor/getMentorCourses', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/course`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data.results;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Detail Course
export const getMentorCourse = createAsyncThunk('mentor/getMentorCourse', async (id) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/course/detail/${id}`, {
                headers: {
                    Authorization: userToken
                }
            });

            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Post Mentor Course
export const postMentorCourse = createAsyncThunk('mentor/postMentorCourse', async (payload, { dispatch }) => {
    try {
        let data = {
            'course_title': payload.courseTitle,
            'slug': payload.courseSlug
        }

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/course/insert`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getMentorCourses());
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Update Mentor Course
export const updateMentorCourse = createAsyncThunk('mentor/updateMentorCourse', async (payload, { dispatch }) => {
    try {

        let data = {
            "id": payload.id,
            "course_title": payload.courseTitle,
            "slug": payload.courseSlug,
            "level": payload.level,
            "label": payload.label,
            "cover": payload.cover,
            "thumbnail": payload.thumbnail,
            "description": payload.description,
            "long_description": payload.longDescription,
            "total_module": payload.totalModule,
            "total_time": payload.totalTime,
            "author": payload.author,
            "author_contact": payload.authorContact,
            "author_email": payload.authorEmail,
            "sourcecode_url": payload.sourcecodeUrl,
            "preview_url": payload.previewUrl,
            "project_screenshot": payload.projectScreenshot,
            "preview_video": payload.previewVideo,
            "preview_video_cover": payload.previewVideoCover,
            "video_screenshots": payload.videoScreenshot,
            "tags": payload.tags
        };

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/course/update`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getMentorCourse(payload.id));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Delete Mentor Course
export const deleteMentorCourse = createAsyncThunk('mentor/deleteMentorCourse', async (id, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/course/deleteCourse?id=${id}`);
            dispatch(getMentorCourses());
            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

// getTopic + Lessons
export const getTopicAndLessons = createAsyncThunk('mentor/getTopicAndLessons', async (id) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/course/lessons/${id}`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Topic Detail
export const getTopic = createAsyncThunk('mentor/getTopic', async (id) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/course/getTopic?id=${id}`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

// Post Topic
export const postTopic = createAsyncThunk('mentor/postTopic', async (payload, { dispatch }) => {
    try {
        let data = {
            "course_id": payload.id,
            "topic_title": payload.topicTitle,
            "topic_order": payload.topicOrder,
            "free": payload.free === true ? "1" : "0"
        };

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/course/saveTopic`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getTopicAndLessons(payload.id));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Update Topic
export const updateTopic = createAsyncThunk('mentor/updateTopic', async (payload, { dispatch }) => {
    try {
        let data = {
            "course_id": payload.id,
            "topic_title": payload.topicTitle,
            "topic_order": payload.topicOrder,
            "free": payload.free
        };

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/course/saveTopic/${payload.topicId}`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getTopicAndLessons(payload.id));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Delete Topic
export const deleteTopic = createAsyncThunk('mentor/deleteTopic', async (id) => {
    try {
        if (userToken) {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/course/deleteTopic?id=${id}`, {
                headers: {
                    Authorization: userToken
                }
            });

            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

// Move Up Topic
export const moveUpTopic = createAsyncThunk('mentor/moveUpTopic', async (payload, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/course/moveup_topic?course_id=${payload.courseId}&topic_id=${payload.topicId}&position=${payload.position}`, null, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getTopicAndLessons(payload.courseId));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Move Down Topic
export const moveDownTopic = createAsyncThunk('mentor/moveDownTopic', async (payload, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/course/movedown_topic?course_id=${payload.courseId}&topic_id=${payload.topicId}&position=${payload.position}`, null, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getTopicAndLessons(payload.courseId));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Post Lesson
export const postLesson = createAsyncThunk('mentor/postLesson', async (payload, { dispatch }) => {
    try {
        let data = {
            "course_id": payload.courseId,
            "topic_id": payload.topicId,
            "free": payload.free === true ? "1" : "0",
            "lesson_title": payload.lessonTitle,
            "lesson_slug": payload.lessonSlug,
            "lesson_order": payload.lessonOrder,
            "type": payload.type,
            "youtube_id": payload.youtubeId,
            "vdocipher_id": payload.vdocipherId,
            "bunnystream_id": payload.bunnystreamId,
            "player": payload.player,
            "duration": payload.duration,
            "theory": payload.theory
        };

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/course/saveLesson`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getTopicAndLessons(payload.courseId));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Lesson Detail
export const getLesson = createAsyncThunk('mentor/getLesson', async (id) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/course/getLesson?lesson_id=${id}`, {
                headers: {
                    Authorization: userToken
                }
            });

            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

// Update Lesson
export const updateLesson = createAsyncThunk('mentor/updateLesson', async (payload, { dispatch }) => {
    try {
        let data = {
            "course_id": payload.courseId,
            "topic_id": payload.topicId,
            "free": payload.free === true ? "1" : "0",
            "lesson_title": payload.lessonTitle,
            "lesson_slug": payload.lessonSlug,
            "lesson_order": payload.lessonOrder,
            "type": payload.type,
            "youtube_id": payload.youtubeId,
            "vdocipher_id": payload.vdocipherId,
            "bunnystream_id": payload.bunnystreamId,
            "player": payload.player,
            "duration": payload.duration,
            "theory": payload.theory
        };

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/course/saveLesson/${payload.lessonId}`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getTopicAndLessons(payload.courseId));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Delete Lesson or Quiz
export const deleteLesson = createAsyncThunk('mentor/deleteLesson', async (id) => {
    try {
        if (userToken) {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/course/deleteLesson?id=${id}`);
            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

// Move Up Lesson
export const moveUpLesson = createAsyncThunk('mentor/moveUpLesson', async (payload, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/course/moveup_lesson?topic_id=${payload.topicId}&lesson_id=${payload.lessonId}&position=${payload.position}`, null, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getTopicAndLessons(payload.courseId));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Move Down Topic
export const moveDownLesson = createAsyncThunk('mentor/moveDownLesson', async (payload, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/course/movedown_lesson?topic_id=${payload.topicId}&lesson_id=${payload.lessonId}&position=${payload.position}`, null, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getTopicAndLessons(payload.courseId));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Post Quiz
export const postQuiz = createAsyncThunk('mentor/postQuiz', async (payload, { dispatch }) => {
    try {
        let data = {
            "course_id": payload.courseId,
            "topic_id": payload.topicId,
            "title": payload.quizTitle,
            "slug": payload.quizSlug,
            "level": payload.quizLevel,
            "category": payload.quizCategory,
            "type": payload.questionType,
            "cover": payload.quizCover,
            "label": payload.quizLabel,
            "subtitle": payload.quizSubtitle,
            "duration": payload.quizDuration,
            "description": payload.quizDescription,
            "kkm": payload.quizKKM,
            "total_show": payload.quizTotalShow,
            "random_question": payload.free === true ? "1" : "0",
            "status": payload.quizStatus
        };

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/quiz/insert`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getTopicAndLessons(payload.courseId));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Detail Quiz
export const getQuiz = createAsyncThunk('mentor/getQuiz', async (id) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/quiz/getQuiz/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

// Update Quiz
export const updateQuiz = createAsyncThunk('mentor/updateQuiz', async (payload) => {
    try {
        let data = {
            "title": payload.quizTitle,
            "slug": payload.quizSlug,
            "level": payload.quizLevel,
            "category": payload.quizCategory,
            "type": payload.questionType,
            "cover": payload.quizCover,
            "label": payload.quizLabel,
            "subtitle": payload.quizSubtitle,
            "duration": payload.quizDuration,
            "description": payload.quizDescription,
            "kkm": payload.quizKKM,
            "total_show": payload.quizTotalShow,
            "random_question": payload.randomQuestion,
            "status": payload.quizStatus
        };

        if (userToken) {
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/quiz/updateQuiz/${payload.quizId}`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Post Question
export const postQuestion = createAsyncThunk('mentor/postQuestion', async (payload) => {
    try {
        let data = {
            "group_id": payload.quizId,
            "question_title": payload.questionTitle,
            "question_content": payload.questionContent,
            "hint": payload.questionHint,
            "options": payload.options
        };

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/quiz/saveQuestion`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get detail question
export const getQuestion = createAsyncThunk('mentor/getQuestion', async (id) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/quiz/getQuestion/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });

            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

// Update Question
export const updateQuestion = createAsyncThunk('mentor/updateQuestion', async (payload) => {
    try {
        let data = {
            "id": payload.questionId,
            "question_title": payload.questionTitle,
            "question_content": payload.questionContent,
            "hint": payload.questionHint,
            "options": payload.options
        };

        if (userToken) {
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/quiz/updateQuestion`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Delete Question
export const deleteQuestion = createAsyncThunk('mentor/deleteQuestion', async ({ groupId, questionId }) => {
    try {
        if (userToken) {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/quiz/deleteQuestion?group_id=${groupId}&question_id=${questionId}`);
            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

const mentorCourseSlice = createSlice({
    name: 'mentorCourse',
    initialState: {
        mentorCourses: [],
        mentorCourse: [],
        topicAndLessons: [],
        postMentorCourseResult: {},
        updateMentorCourseResult: {},
        loadingMentorCourse: false
    },
    extraReducers: {
        // Get Mentor Courses
        [getMentorCourses.pending]: (state, action) => {
            state.loadingMentorCourse = true;
        },
        [getMentorCourses.fulfilled]: (state, action) => {
            state.loadingMentorCourse = false;
            state.mentorCourses = action.payload;
        },
        [getMentorCourses.rejected]: (state, action) => {
            state.loadingMentorCourse = false;
        },

        // Get Detail Course
        [getMentorCourse.pending]: (state, action) => {
            state.loadingMentorCourse = true;
        },
        [getMentorCourse.fulfilled]: (state, action) => {
            state.loadingMentorCourse = false;
            state.mentorCourse = action.payload;
        },
        [getMentorCourse.rejected]: (state, action) => {
            state.loadingMentorCourse = false;
        },

        // Post new course
        [postMentorCourse.pending]: (state, action) => {
            state.loadingMentorCourse = true;
        },
        [postMentorCourse.fulfilled]: (state, action) => {
            state.loadingMentorCourse = false;
            state.postMentorCourseResult = action.payload;
        },
        [postMentorCourse.rejected]: (state, action) => {
            state.loadingMentorCourse = false;
        },

        // Update course
        [updateMentorCourse.pending]: (state, action) => {
            state.loadingMentorCourse = true;
        },
        [updateMentorCourse.fulfilled]: (state, action) => {
            state.loadingMentorCourse = false;
            state.updateMentorCourseResult = action.payload;
        },
        [updateMentorCourse.rejected]: (state, action) => {
            state.loadingMentorCourse = false;
        },

        // Get Topic and Lessons
        [getTopicAndLessons.pending]: (state, action) => {
            state.loadingMentorCourse = true;
        },
        [getTopicAndLessons.fulfilled]: (state, action) => {
            state.loadingMentorCourse = false;
            state.topicAndLessons = action.payload;
        },
        [getTopicAndLessons.rejected]: (state, action) => {
            state.loadingMentorCourse = false;
        },

        // Get Topic and Lessons
        [getTopic.pending]: (state, action) => {
            state.loadingMentorCourse = true;
        },
        [getTopic.fulfilled]: (state, action) => {
            state.loadingMentorCourse = false;
            // state.topicAndLessons = action.payload;
        },
        [getTopic.rejected]: (state, action) => {
            state.loadingMentorCourse = false;
        },

        // Post Topic
        [postTopic.pending]: (state, action) => {
            state.loadingMentorCourse = true;
        },
        [postTopic.fulfilled]: (state, action) => {
            state.loadingMentorCourse = false;
            // state.postMentorCourseResult = action.payload;
        },
        [postTopic.rejected]: (state, action) => {
            state.loadingMentorCourse = false;
        },

        // Update Topic
        [updateTopic.pending]: (state, action) => {
            state.loadingMentorCourse = true;
        },
        [updateTopic.fulfilled]: (state, action) => {
            state.loadingMentorCourse = false;
            // state.postMentorCourseResult = action.payload;
        },
        [updateTopic.rejected]: (state, action) => {
            state.loadingMentorCourse = false;
        },

        // Delete Topic
    }
});

export default mentorCourseSlice.reducer;
