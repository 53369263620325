import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

export const getEducations = createAsyncThunk('user/getEducations', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/entry/index/user_education`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data.results;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const getEducation = createAsyncThunk('user/getEducation', async (id, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/entry/detail/user_education/${id}`, {
                headers: {
                    Authorization: userToken
                }
            });
            dispatch(getEducations());
            return response.data.result;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const postEducation = createAsyncThunk('user/postEducation', async (payload, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('level', payload.level);
        data.append('ipk', payload.ipk);
        data.append('major', payload.major);
        data.append('institution', payload.institution);
        data.append('category', payload.category);
        data.append('description', payload.description);
        data.append('status', 'publish');
        data.append('graduated_at', payload.graduated_at);

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/entry/insert/user_education`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getEducations());
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const updateEducation = createAsyncThunk('user/updateEducation', async (payload, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('level', payload.level);
        data.append('ipk', payload.ipk);
        data.append('major', payload.major);
        data.append('institution', payload.institution);
        data.append('category', payload.category);
        data.append('description', payload.description);
        data.append('status', 'publish');
        data.append('graduated_at', payload.graduated_at);

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/entry/update/user_education/${payload.id}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getEducations());
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const deleteEducation = createAsyncThunk('user/deleteEducation', async (id, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/entry/delete/user_education/${id}`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getEducations());
            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

const initialState = {
    educations: [],
    education: {},
    loadingEducation: false
};

const educationsSlice = createSlice({
    name: 'educations',
    initialState,
    extraReducers: {
        // Get Educations Array
        [getEducations.pending]: (state, action) => {
            state.loadingEducation = true;
        },
        [getEducations.fulfilled]: (state, action) => {
            state.loadingEducation = false;
            state.educations = action.payload;
        },
        [getEducations.rejected]: (state, action) => {
            state.loadingEducation = false;
        },

        // Get Single Education
        [getEducation.pending]: (state, action) => {
            state.loadingEducation = true;
        },
        [getEducation.fulfilled]: (state, action) => {
            state.loadingEducation = false;
            state.education = action.payload;
        },
        [getEducation.rejected]: (state, action) => {
            state.loadingEducation = false;
        },

        // Post Education
        [postEducation.pending]: (state, action) => {
            state.loadingEducation = true;
        },
        [postEducation.fulfilled]: (state, action) => {
            state.loadingEducation = false;
        },
        [postEducation.rejected]: (state, action) => {
            state.loadingEducation = false;
        },

        // Update Education
        [updateEducation.pending]: (state, action) => {
            state.loadingEducation = true;
        },
        [updateEducation.fulfilled]: (state, action) => {
            state.loadingEducation = false;
        },
        [updateEducation.rejected]: (state, action) => {
            state.loadingEducation = false;
        },

        // Delete Education
        [deleteEducation.pending]: (state, action) => {
            state.loadingEducation = true;
        },
        [deleteEducation.fulfilled]: (state, action) => {
            state.loadingEducation = false;
        },
        [deleteEducation.rejected]: (state, action) => {
            state.loadingEducation = false;
        },
    }
});

export default educationsSlice.reducer;
