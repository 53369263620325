import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import Layout from "../../../components/global/Layout/Layout";

// Sweetalert
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { removeHTMLTags } from "../../../utils/helper";
import Sidebar from "../../../components/Settings/Sidebar/Sidebar";
import DropdownMenu from "../../../components/Settings/DropdownMenu/DropdownMenu";
import { useDispatch } from "react-redux";
import { getUserByUsername } from "../../../features/user/userSlice";
import { userToken } from "../../../utils/config";

const ChangePasswordSetting = () => {
    const MySwal = withReactContent(Swal);

    const { myUser, user } = useSelector((state) => state.user);

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [inputState, setInputState] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    useEffect(() => {
        if (myUser?.username) {
            dispatch(getUserByUsername(myUser?.username));
        }
    }, [dispatch, myUser?.username]);

    useEffect(() => {
        // Check user state readiness
        if (user) {
            setInputState({
                // General Settings
                email: user?.email,
                username: user?.username,
            });
        };
    }, [user]);

    // Handle input changes
    const handleChange = (e) => {
        const value = e.target.value;
        setInputState({
            ...inputState,
            [e.target.name]: value
        });
    };

    // Change Password
    const handleChangePassword = async (e) => {
        e.preventDefault();
        setLoading(true);

        let response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/user/password/update`,
            headers: {
                "Content-Type": "application/json",
                Authorization: userToken
            },
            data: {
                'old_password': inputState.oldPassword,
                'new_password': inputState.newPassword
            },
        });

        if (response.data.status === "success") {
            setLoading(false);
            setInputState({
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            })
            // Alert Success
            await MySwal.fire({
                title: <strong>Success!</strong>,
                text: removeHTMLTags(response.data.message) + ' You will be redirected to Login page',
                icon: 'success',
                confirmButtonText: 'Close'
            });
            // navigate('https://www.codepolitan.com/logout', { replace: true })
            window.location.href = 'https://www.codepolitan.com/logout';
        } else {
            setLoading(false);
            // Alert Filed
            await MySwal.fire({
                title: <strong>Oops!</strong>,
                text: removeHTMLTags(response.data.message),
                icon: 'error',
                confirmButtonText: 'Close'
            });
            console.log(response.data);
        };
    };

    return (
        <>
            <Helmet>
                <title>Ganti Password</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <div className="row my-5">
                            <div className="col-lg-2 d-none d-lg-block">
                                <Sidebar />
                            </div>
                            <div className="col-lg-10">
                                <div className="d-lg-none mb-3">
                                    <DropdownMenu />
                                </div>
                                <div className="bg-white p-3">
                                    <div className="card border-0 mb-3">
                                        <div className="card-header border-0">
                                            <h5 className="text-muted my-2">Ganti Password</h5>
                                        </div>
                                        <div className="card-body">
                                            <form onSubmit={handleChangePassword}>
                                                <div className="mb-3">
                                                    <label htmlFor="oldPassword" className="form-label">Password Lama</label>
                                                    <input name="oldPassword" onChange={handleChange} value={inputState.oldPassword} type="password" className="form-control" id="oldPassword" placeholder="Old Password" required />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="newPassword" className="form-label">Password Baru</label>
                                                    <input name="newPassword" onChange={handleChange} value={inputState.newPassword} type="password" className="form-control" id="newPassword" placeholder="New Password" required />
                                                    {inputState.newPassword && inputState.newPassword.length < 8 && (
                                                        <small className="text-danger"><i>Password minimal 8 karakter</i></small>
                                                    )}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="confirmPassword" className="form-label">Konfirmasi Password</label>
                                                    <input name="confirmPassword" onChange={handleChange} value={inputState.confirmPassword} type="password" className="form-control" id="confrimPassword" placeholder="Confirm Password" required />
                                                    {inputState.newPassword !== inputState.confirmPassword && (
                                                        <small className="text-danger"><i>Password tidak sama</i></small>
                                                    )}
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <button className="btn btn-primary" type="submit" disabled={loading && inputState.newPassword !== inputState.confirmPassword}>Ganti Password</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout >
        </>
    );
};

export default ChangePasswordSetting;
