import { useSelector } from "react-redux";
import CardDiscussion from "../../discussion/CardDiscussion";
import SkeletonCardDiscussion from "../../../skeletons/SkeletonCardDiscussion/SkeletonCardDiscussion";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const SectionDiscussions = () => {
    const { discussions, loadingDiscussion } = useSelector(state => state.discussion);

    return (
        <section className="section my-5">
            <h4 className="section-title mb-4">Pertanyaan</h4>

            {loadingDiscussion ? [...Array(3)].map((item, index) => {
                return (
                    <SkeletonCardDiscussion key={index} />
                );
            }) : null}

            {!loadingDiscussion ? discussions?.map((item, index) => {
                return (
                    <Link key={index} className="link" to={`${process.env.REACT_APP_URL}/forum/thread/${item.slug}`}>
                        <CardDiscussion
                            avatar={item.avatar}
                            name={item.name}
                            rank={item?.rank?.rank_name}
                            job={item.jobs}
                            role={item.role_name}
                            subject={item.subject}
                            mark={item.mark}
                            date={item.date}
                            courseTitle={item.course_title}
                            courseSlug={item.course_slug}
                            lessonTitle={item.lesson_title}
                            lessonId={item.lesson_id}
                            totalAnswer={item.total_answer}
                            tags={item.tags}
                        />
                    </Link>
                );
            }).slice(0, 3) : null}
            <div className="text-center mt-4">
                <Link className="link text-primary" to="/learn/discussions">Lihat semua pertanyaan <FontAwesomeIcon icon={faArrowRight} /></Link>
            </div>
        </section>
    );
};

export default SectionDiscussions;
