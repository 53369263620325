import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './CardMyExercise.module.scss';

const CardMyExercise = ({ thumbnail, title, status, score, kkm, level, label, duration }) => {
  let labels = label?.split(',');

  return (
    <div className="card h-100">
      <div className="card-body">
        <div className={styles.img_wrapper}>
          <img className="card-img objectfit-cover" src={thumbnail || "/assets/img/placeholder.jpg"} alt={title || 'Untitled'} />
        </div>
        <div className={styles.info_wrapper}>
          <h5 className="card-text mt-3 fs-6">{title || 'Untitled'}</h5>
          <div className="row text-muted mb-3">
            <div className="col-6">
              <p className="m-auto"><FontAwesomeIcon fixedWidth icon={faChartSimple} /> {level || 'Unknown'}</p>
              <p className="m-auto"><FontAwesomeIcon fixedWidth icon={faClock} /> {duration || 0} min</p>
            </div>
            <div className="col-6">
              <p className="m-auto">Score: {score || 0}</p>
              <p className="m-auto">KKM: {kkm || 0}</p>
            </div>
          </div>
          {labels?.map((item, index) => {
            return (
              <span className="badge bg-light text-dark border me-1 mb-1 text-capitalize" key={index}>{item}</span>
            );
          })}
        </div>
      </div>
      <div className={`card-footer bg-white ${styles.info_wrapper}`}>
        <span className="link text-primary float-end">{status === 'Completed' ? 'Klaim Sertifikat' : 'Coba Lagi'}</span>
      </div>
    </div>
  );
};

export default CardMyExercise;
