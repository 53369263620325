import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

// Get All Events
export const getAllEvents = createAsyncThunk('lesson/getAllEvents', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/webinar/latest?page=1&limit=100`);
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const eventSlice = createSlice({
    name: 'event',
    initialState: {
        events: [],
        loadingEvent: false,
    },
    extraReducers: {
        // Get All Events
        [getAllEvents.pending]: (state, action) => {
            state.loadingEvent = true;
        },
        [getAllEvents.fulfilled]: (state, action) => {
            state.loadingEvent = false;
            state.events = action.payload;
        },
        [getAllEvents.rejected]: (state, action) => {
            state.loadingEvent = false;
        }
    }
});

export default eventSlice.reducer;
