import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

export const getRegistrationDetail = createAsyncThunk('polarDb/getRegistrationDetail', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/entry/detail/polardb_registrar`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            });
            return response.data.result;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Post Registration Data
export const postRegistrationData = createAsyncThunk('polarDb/postRegistrationData', async (inputState, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('name', inputState.name);
        data.append('whatsapp_number', inputState.whatsappNumber);
        data.append('address', inputState.address);
        data.append('occupation', inputState.occupation);
        data.append('job', inputState.job);
        data.append('institution', inputState.institution);
        data.append('alibaba_account_id', inputState.alibabaAccountId);
        data.append('alibaba_account_screenshot', inputState.screenshotAlibabaAccount);
        data.append('tianchi_nickname', inputState.tianchiNickname);
        data.append('tianchi_account_screenshot', inputState.screenshotTianchiAccount);
        data.append('tianchi_joining_screenshot', inputState.screenshotTianchiJoining);

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/entry/insert/polardb_registrar`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getRegistrationDetail());
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Update Registration Data
export const updateRegistrationData = createAsyncThunk('polarDb/updateRegistrationData', async (inputState, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('name', inputState.name);
        data.append('whatsapp_number', inputState.whatsappNumber);
        data.append('address', inputState.address);
        data.append('occupation', inputState.occupation);
        data.append('job', inputState.job);
        data.append('institution', inputState.institution);
        data.append('alibaba_account_id', inputState.alibabaAccountId);
        data.append('alibaba_account_screenshot', inputState.screenshotAlibabaAccount);
        data.append('tianchi_nickname', inputState.tianchiNickname);
        data.append('tianchi_account_screenshot', inputState.screenshotTianchiAccount);
        data.append('tianchi_joining_screenshot', inputState.screenshotTianchiJoining);

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/entry/update/polardb_registrar/${inputState.id}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getRegistrationDetail());
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const polarDbSlice = createSlice({
    name: 'polarDb',
    initialState: {
        registrationDetail: [],
        loadingPolarDb: false,
    },
    extraReducers: {
        [getRegistrationDetail.pending]: (state, action) => {
            state.loadingPolarDb = true;
        },
        [getRegistrationDetail.fulfilled]: (state, action) => {
            state.loadingPolarDb = false;
            state.registrationDetail = action.payload;
        },
        [getRegistrationDetail.rejected]: (state, action) => {
            state.loadingPolarDb = false;
        },

        // Post polarDb Data
        [postRegistrationData.pending]: (state, action) => {
            state.loadingPolarDb = true;
        },
        [postRegistrationData.fulfilled]: (state, action) => {
            state.loadingPolarDb = false;
        },
        [postRegistrationData.rejected]: (state, action) => {
            state.loadingPolarDb = false;
        },

        // Update polarDb Data
        [updateRegistrationData.pending]: (state, action) => {
            state.loadingPolarDb = true;
        },
        [updateRegistrationData.fulfilled]: (state, action) => {
            state.loadingPolarDb = false;
        },
        [updateRegistrationData.rejected]: (state, action) => {
            state.loadingPolarDb = false;
        },
    }
});

export default polarDbSlice.reducer;