import { faArrowRightFromBracket, faArrowUpRightFromSquare, faChartSimple, faCheckCircle, faClock, faQuestionCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Breadcrumb from '../../../components/global/Breadcrumb/Breadcrumb';
import CardCourse from '../../../components/global/Cards/CardCourse/CardCourse';
import Layout from "../../../components/global/Layout/Layout";
import SkeletonCardCourse from '../../../components/skeletons/SkeletonCardCourse';
import { getCoursesByTag } from '../../../features/courses/courseSlice';
import { getMyCertificate, getQuiz, getQuizzesByGroup, getResultInfoByGroup } from '../../../features/quiz/quizSlice';
import { capitalizeFirstLetter } from '../../../utils/helper';

const ExerciseDetail = () => {
    const navigate = useNavigate();
    const { slug } = useParams();
    const dispatch = useDispatch();

    const { quizzesByGroup, quiz, resultInfo } = useSelector((state) => state.quiz);
    const { coursesByTag, loadingCourse } = useSelector((state) => state.courses);

    useEffect(() => {
        dispatch(getQuiz(slug))
        dispatch(getQuizzesByGroup(slug))
        dispatch(getResultInfoByGroup(quiz?.id))

        const quizLabel = quiz?.label?.split(',');
        if (quizLabel) dispatch(getCoursesByTag(quizLabel[0]));
    }, [dispatch, slug, quiz?.id, quiz?.label]);

    const MySwal = withReactContent(Swal);

    const claimCertificate = (id) => {
        dispatch(getMyCertificate(id)).then(response => {
            if (response.payload.status === 'failed') {
                MySwal.fire({
                    title: capitalizeFirstLetter(response.payload.status),
                    text: response.payload.message,
                    icon: 'info',
                    showCloseButton: true,
                    showConfirmButton: false
                })
            } else {
                window.open(`https://codepolitan.com/c/${response.payload.code}`, '_blank');
            }
        })
    };

    const getStarted = () => {
        MySwal.fire({
            title: 'Get Started',
            text: 'Sudah siap untuk memulai latihan ini? Klik jawaban terbaik jika itu pertanyaan pilihan ganda. Ketik kode yang paling tepat dalam pertanyaan essay. Klik tombol dibawah ini jika sudah siap.',
            icon: 'question',
            confirmButtonText: 'Mulai',
            confirmButtonColor: '#14a7a0'
        }).then((result) => {
            if (result.isConfirmed) {
                navigate(`/exercise/${quiz?.slug}`);
            };
        });
    };

    return (
        <>
            <Helmet>
                <title>Exercise Detail</title>
            </Helmet>
            <Layout>
                <section className="section mt-5" id="learnHome">
                    <div className="container-fluid">
                        <Breadcrumb>
                            <li className="breadcrumb-item">
                                <Link className="link text-primary" to="/exercises">
                                    <strong>Exercises</strong>
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                <strong className="text-primary">
                                    {quiz?.title}
                                </strong>
                            </li>
                        </Breadcrumb>
                        <section className="my-5">
                            <div className="card bg-primary text-white card-rounded">
                                <div className="card-body p-4 p-lg-5">
                                    <h4 className="section-title text-white">{quiz?.title}</h4>
                                    <p>Buktikan skill coding kamu dengan menyelesaikan exercise ini dan dapatkan sertifikatnya!</p>
                                    {Object.keys(resultInfo).length !== 0 && resultInfo.score >= quiz?.kkm ? (
                                        <button onClick={() => claimCertificate(resultInfo?.id)} type="button" className="btn btn-pink"><FontAwesomeIcon icon={faArrowRightFromBracket} /> Klaim Sertifikat</button>
                                    ) : (
                                        <button onClick={getStarted} type="button" className="btn btn-pink"><FontAwesomeIcon icon={faArrowRightFromBracket} /> Get Started</button>
                                    )}
                                </div>
                            </div>
                        </section>
                        {
                            Object.keys(resultInfo).length !== 0 && resultInfo.score >= quiz?.kkm && (
                                <section className="my-5">
                                    <div className="card card-rounded border-0">
                                        <div className="card-body p-4 p-lg-5">
                                            <div className="row text-center text-lg-start justify-content-center">
                                                <div className="col-4 col-lg-2 mb-3 mb-lg-auto">
                                                    <img className="img-fluid" src="/assets/img/exercises/trophy.png" alt="Trophy" />
                                                </div>
                                                <div className="col-lg-10 my-auto">
                                                    <h4 className="section-title">Yeay! Kamu Berhasil</h4>
                                                    <p className="text-muted">Waktu pengerjaan kamu berada di 30% teratas dari 1.7M programmer yang mengambil Exercise ini</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )
                        }
                        <section className="my-5">
                            <h4 className="section-title mb-4">Overview</h4>

                            <div className="row">
                                {/* Main Content */}
                                <div className="col-xl-8">
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="bg-light p-3">
                                                <h5 className="card-title mb-0">Deskripsi</h5>
                                            </div>
                                            <div className="p-3">
                                                <p className="text-muted">{quiz?.description}</p>
                                                {/* <p className="text-muted">Exercise ini dibuat oleh tim Codepolitan sesuai standar sertifikasi HTML resmi, supaya membuat kamu terbiasa untuk mengikuti sertifikasi resmi HTML.</p> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="bg-primary text-white p-3">
                                                <h5 className="card-title mb-0">Exercises Table</h5>
                                                <span className="me-3">Level {quiz?.level}</span>
                                                <span className="me-3">38 Quiz</span>
                                                <span className="me-3">{quiz?.duration} Menit</span>
                                                <span className="me-3">{quiz?.total_student} Siswa</span>
                                            </div>
                                            <table className="table table-striped my-3">
                                                <tbody>
                                                    {
                                                        quizzesByGroup?.questions?.map((quizGroup, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{quizGroup.title ?? 'Undefined'}</td>
                                                                    <td className="text-primary text-end">{quiz?.duration} Menit</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="bg-light p-3">
                                                <h5 className="card-title mb-0">Rekomendasi Kelas untuk Exercise ini</h5>
                                            </div>
                                            <div className="row my-3">
                                                {loadingCourse ? [1, 2].map((item) => {
                                                    return (
                                                        <div className="col-md-6" key={item}>
                                                            <SkeletonCardCourse />
                                                        </div>
                                                    );
                                                }) : null}
                                                {!loadingCourse && coursesByTag?.length < 1 ? (
                                                    <p className="text-muted text-center my-5">Belum ada rekomendasi kelas untuk exercise ini.</p>
                                                ) : null}
                                                {!loadingCourse && coursesByTag?.map((course, index) => {
                                                    return (
                                                        <div className="col-md-6 mb-3" key={index}>
                                                            <Link className="link" to={`/learn/courses/detail/${course.slug}`}>
                                                                <CardCourse
                                                                    thumbnail={course.thumbnail}
                                                                    author={course.author}
                                                                    title={course.title}
                                                                    level={course.level}
                                                                    totalStudents={course.total_student}
                                                                    totalModules={course.total_module}
                                                                    totalTimes={course.total_time}
                                                                    totalRating={course.total_rating}
                                                                    totalFeedback={course.total_feedback}
                                                                    normalBuyPrice={course.buy?.normal_price || course.normal_price}
                                                                    retailBuyPrice={course.buy?.retail_price || course.retail_price}
                                                                    normalRentPrice={course.rent?.normal_price}
                                                                    retailRentPrice={course.rent?.retail_price}
                                                                />
                                                            </Link>
                                                        </div>
                                                    );
                                                }).slice(0, 2)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End of Main Content */}

                                {/* Sidebar Score */}
                                <div className="col-xl-4">
                                    <div className="sticky-top" style={{ top: 100, zIndex: 2 }}>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="bg-light p-3">
                                                    <h5 className="card-title mb-0">Score Kamu</h5>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center my-3 text-white">
                                                    <div className="d-flex flex-column justify-content-center align-items-center bg-primary rounded-circle fw-bold" style={{ width: '90px', height: '90px' }}>
                                                        <span className="display-6">{resultInfo?.score ?? '0'}</span>
                                                        <span>Score</span>
                                                    </div>
                                                </div>
                                                <div className="row small">
                                                    <div className="col-4">
                                                        <FontAwesomeIcon className="me-2" icon={faQuestionCircle} />
                                                        <span>{quiz?.total_question ?? '0'} Soal</span>
                                                    </div>
                                                    {
                                                        Object.keys(resultInfo).length === 0
                                                            ? (
                                                                <>
                                                                    <div className="col-4">
                                                                        <FontAwesomeIcon className="text-primary me-2" icon={faClock} />
                                                                        <span>{quiz?.duration} Menit</span>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <FontAwesomeIcon className="text-info me-2" icon={faChartSimple} />
                                                                        <span>{quiz?.level}</span>
                                                                    </div>

                                                                </>
                                                            )
                                                            : (
                                                                <>
                                                                    <div className="col-4">
                                                                        <FontAwesomeIcon className="text-primary me-2" icon={faCheckCircle} />
                                                                        <span>{resultInfo?.total_right} Benar</span>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <FontAwesomeIcon className="text-danger me-2" icon={faXmarkCircle} />
                                                                        <span>{resultInfo?.total_wrong} Salah</span>
                                                                    </div>
                                                                </>
                                                            )
                                                    }
                                                </div>
                                                <hr />
                                                <div className="d-flex justify-content-between fw-bold mb-2">
                                                    <span>Nilai Ambang Score</span>
                                                    <span>Score &gt; {quiz?.kkm}</span>
                                                </div>
                                                <div className="d-flex justify-content-between fw-bold mb-2">
                                                    <span>Waktu</span>
                                                    <span>{quiz?.duration} Mnt</span>
                                                </div>
                                                <div className="d-flex justify-content-between fw-bold">
                                                    <span>Percobaan</span>
                                                    <span>3x / 24 jam</span>
                                                </div>
                                                <hr />
                                                <div className="text-end">
                                                    {Object.keys(resultInfo).length !== 0 && resultInfo.score >= quiz?.kkm ? (
                                                        <button onClick={() => claimCertificate(resultInfo?.id)} type="button" className="btn btn-primary"><FontAwesomeIcon className="me-1" icon={faArrowUpRightFromSquare} /> Klaim Sertifikat</button>
                                                    ) : (
                                                        <button onClick={getStarted} type="button" className="btn btn-primary"><FontAwesomeIcon className="me-1" icon={faArrowUpRightFromSquare} /> Take Quiz</button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End of Sidebar Score */}
                            </div>
                        </section>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default ExerciseDetail;
