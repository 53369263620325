import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SectionLatestSales = () => {
    const { transactions } = useSelector(state => state.mentor);

    return (
        <section className="card border-0 shadow-sm rounded mt-4 mb-5">
            <div className="card-body">
                <div className="fw-bold">Penjualan Terakhir</div>
                <hr className="m-0 mt-3" />
                <ul className="list-group list-group-flush pt-2">
                    {transactions?.length === 0
                        ? (<div className="text-center text-muted my-2">Belum ada transaksi</div>)
                        : transactions?.slice(0, 5).map((transaction, index) => {
                            return (
                                <li key={index} className="list-group-item">
                                    <div className="row">
                                        <div className="col-auto d-flex align-items-center justify-content-center p-0">
                                            <FontAwesomeIcon className="text-primary" fixedWidth icon={faClipboardCheck} />
                                        </div>
                                        <div className="col-9">
                                            {/* <div>{transaction.product}</div> */}
                                            <small>{transaction.customer} telah membeli kelas <span>{transaction.product}</span></small>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                </ul>
                <hr />
                <div className="text-end">
                    <Link to="/mentor/earnings" className="text-primary fw-bold fst-italic text-decoration-none">
                        <span>Lihat semua &rarr;</span>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default SectionLatestSales;
