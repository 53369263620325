import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import Layout from "../../components/global/Layout/Layout";
import SectionIncomeSummary from '../../components/mentor/Sections/SectionIncomeSummary/SectionIncomeSummary';
import SectionLatestDiscussion from '../../components/mentor/Sections/SectionLatestDiscussion/SectionLatestDiscussion';
import { getMentorEarningStats, getMentorStatistic, getMentorTransaction, getMentorTransactionStats, getThreadByMentor } from '../../features/mentor/mentorSlice';
import SectionLatestSales from '../../components/mentor/Sections/SectionLatestSales/SectionLatestSales';
import SectionGraphics from '../../components/mentor/Sections/SectionGraphics/SectionGraphics';
import Sidebar from '../../components/mentor/Sidebar/Sidebar';

const Mentor = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getThreadByMentor());
        dispatch(getMentorTransaction());
        dispatch(getMentorEarningStats());
        dispatch(getMentorTransactionStats());
        dispatch(getMentorStatistic());
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <title>Mentor</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            {/* Sidebar */}
                            <div className="col-lg-2">
                                <Sidebar />
                            </div>
                            {/* End of Sidebar */}

                            {/* Main Content */}
                            <div className="col-lg-10 text-muted px-4 px-lg-5">
                                <SectionIncomeSummary />
                                <SectionGraphics />
                                <SectionLatestSales />
                                <SectionLatestDiscussion />
                            </div>
                            {/* End of Main Content */}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default Mentor;
