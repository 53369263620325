import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ path, auth, role, children }) => {
    if (!auth) {
        window.location.href = `${process.env.REACT_APP_URL}/login`;
        return null;
    } else if (path.includes('mentor') && path !== '/join-mentor' && role !== '10' && role !== '1') {
        return <Navigate to="/learn" replace />;
    } else if (path.includes('affiliator') && role !== '12' && role !== '1') {
        return <Navigate to="/learn" replace />;
    }
    return children;
};

export default ProtectedRoute;