import { faBook, faPlusSquare, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import CardMyExercise from "../../Cards/CardMyExercise/CardMyExercise";

const SectionMyExercises = ({ data, loading }) => {
  const [limit, setLimit] = useState(6);

  const completedExercises = data?.filter(item => item.status === 'Completed');
  const failedExercises = data?.filter(item => item.status === 'Failed');

  return (
    <section>
      <div className="row justify-content-between mb-4">
        <div className="col-auto">
          <h3 className="section-title">My Exercises</h3>
        </div>
        <div className="col-auto my-auto">
          <Link className="link text-primary" to="/exercises">
            <FontAwesomeIcon className="me-2" icon={faPlusSquare} />
            Tambah Exercise
          </Link>
        </div>
      </div>

      <ul className="nav nav-pills border-0 text-nowrap overflow-auto flex-nowrap my-3" role="tablist">
        <li className="nav-item" role="presentation">
          <button className="nav-link px-4 active" id="pills-my-exercise-all-tab" data-bs-toggle="pill" data-bs-target="#pills-my-exercise-all" type="button" role="tab" aria-controls="pills-my-exercise-all" aria-selected="true">Semua ({data?.length || 0})</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link px-4" id="pills-my-exercise-completed-tab" data-bs-toggle="pill" data-bs-target="#pills-my-exercise-completed" type="button" role="tab" aria-controls="pills-my-exercise-completed" aria-selected="false">Completed ({completedExercises?.length || 0})</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link px-4" id="pills-my-exercise-failed-tab" data-bs-toggle="pill" data-bs-target="#pills-my-exercise-failed" type="button" role="tab" aria-controls="pills-my-exercise-failed" aria-selected="false">Failed ({failedExercises?.length || 0})</button>
        </li>
      </ul>

      <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active" id="pills-my-exercise-all" role="tabpanel" aria-labelledby="pills-my-exercise-all-tab">
          <div className="row">
            {!loading && data?.length === 0 && (
              <div className="text-center bg-white py-4">
                <img src="/assets/img/icon-not-found.png" width="30%" alt="Icon" />
                <h2 className="fw-bold mt-n3">Sayang sekali</h2>
                <p className="lead">Kamu belum memiliki exercise, pilih exercise sesuai <br /> keinginan kamu sekarang juga disini.</p>
                <a href="http://codepolitan.com/exercise" target="_blank noreferrer" className="btn btn-primary btn-rounded"><FontAwesomeIcon icon={faBook} /> Pilih Exercise Sekarang</a>
              </div>
            )}
            {!loading && data?.map((quiz) => {
              return (
                <div className="col-md-6 col-xl-4 p-2" key={quiz.id}>
                  <Link className="link" to={`/exercises/detail/${quiz.slug}`}>
                    <CardMyExercise
                      thumbnail={quiz.cover}
                      title={quiz.title}
                      score={quiz.skor}
                      kkm={quiz.kkm}
                      level={quiz.level}
                      label={quiz.label}
                      status={quiz.status}
                      duration={quiz.duration}
                    />
                  </Link>
                </div>
              );
            }).slice(0, limit)}
          </div>
          {data?.length > limit ? (
            <div className="text-center mt-4">
              <button onClick={() => setLimit(limit + 6)} type="button" className="btn btn-outline-secondary"><FontAwesomeIcon icon={faRedo} /> Load more</button>
            </div>
          ) : null}
        </div>
        <div className="tab-pane fade" id="pills-my-exercise-completed" role="tabpanel" aria-labelledby="pills-my-exercise-completed-tab">
          <div className="row">
            {!loading && completedExercises.length === 0 && (
              <div className="text-center bg-white py-4">
                <h5>Belum ada exercise yang selesai</h5>
              </div>
            )}
            {completedExercises.map((quiz) => {
              return (
                <div className="col-md-6 col-xl-4 p-2" key={quiz.id}>
                  <Link className="link" to={`/exercises/detail/${quiz.slug}`}>
                    <CardMyExercise
                      id={quiz.id}
                      thumbnail={quiz.cover}
                      title={quiz.title}
                      score={quiz.skor}
                      kkm={quiz.kkm}
                      level={quiz.level}
                      label={quiz.label}
                      status={quiz.status}
                      duration={quiz.duration}
                      slug={quiz.slug}
                    />
                  </Link>
                </div>
              );
            }).slice(0, limit)}
          </div>
          {completedExercises?.length > limit ? (
            <div className="text-center mt-4">
              <button onClick={() => setLimit(limit + 6)} type="button" className="btn btn-outline-secondary"><FontAwesomeIcon icon={faRedo} /> Load more</button>
            </div>
          ) : null}
        </div>
        <div className="tab-pane fade" id="pills-my-exercise-failed" role="tabpanel" aria-labelledby="pills-my-exercise-failed-tab">
          <div className="row">
            {!loading && failedExercises.length === 0 && (
              <div className="text-center bg-white py-4">
                <h5>Belum ada exercise yang gagal</h5>
              </div>
            )}
            {failedExercises?.map((quiz) => {
              return (
                <div className="col-md-6 col-xl-4 p-2" key={quiz.id}>
                  <Link className="link" to={`/exercises/detail/${quiz.slug}`}>
                    <CardMyExercise
                      thumbnail={quiz.cover}
                      title={quiz.title}
                      score={quiz.skor}
                      kkm={quiz.kkm}
                      level={quiz.level}
                      label={quiz.label}
                      status={quiz.status}
                      duration={quiz.duration}
                      slug={quiz.slug}
                    />
                  </Link>
                </div>
              );
            }).slice(0, limit)}
          </div>
          {failedExercises?.length > limit ? (
            <div className="text-center mt-4">
              <button onClick={() => setLimit(limit + 6)} type="button" className="btn btn-outline-secondary"><FontAwesomeIcon icon={faRedo} /> Load more</button>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default SectionMyExercises;
