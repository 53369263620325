import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = ({ theme }) => {
    const year = new Date().getFullYear();

    return (
        <footer className={`${theme === 'dark' ? 'bg-dark' : 'bg-light'} text-center py-4`}>
            <span className={theme === 'dark' ? 'bg-dark' : 'text-muted'}>&copy; {year} Codepolitan. All rights reserved. <a className="btn btn-outline-primary btn-sm border-0 rounded-2" href="https://codepolitan-status.statuspage.io/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon size="sm" fixedWidth icon={faCircle} /> System status</a></span>
        </footer>
    );
};

export default Footer;
