import Skeleton from "react-loading-skeleton";

const SkeletonCardInteractiveCoding = () => {
    return (
        <div className="card border-0 shadow-sm">
            <Skeleton className="card-img-top" height={180} width={'100%'} />
            <div className="card-body">
                <Skeleton count={2} width={'100%'} />
            </div>
            <div className="card-footer bg-white text-center">
                <small>
                    <Skeleton width={100} />
                </small>
            </div>
        </div>
    );
};

export default SkeletonCardInteractiveCoding;
