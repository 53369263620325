import { faArrowDown, faArrowUp, faChevronDown, faChevronUp, faPen, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ListLesson from "../../Lists/ListLesson/ListLesson";

const ItemTopic = ({ index, topic, topicLength, onEdit, onDelete, onSetTopicId, onMoveUpTopic, onMoveDownTopic, onEditLesson, onDeleteLesson, onMoveUpLesson, onMoveDownLesson, onEditQuiz, onSetQuizId, onEditQuestion, onDeleteQuestion }) => {

    const [openItem, setOpenItem] = useState(false);

    return (
        <div className="accordion-item border rounded-0 mb-2 overflow-auto">
            <div className="accordion-header p-2 text-nowrap" id={"heading" + index}>
                <div className="d-flex">
                    <div className="p-2">
                        {index !== 0 && (
                            <button onClick={onMoveUpTopic} className="btn text-muted shadow-none btn-sm p-0">
                                <FontAwesomeIcon className="me-2" icon={faArrowUp} />
                            </button>
                        )}
                        {index !== topicLength - 1 && (
                            <button onClick={onMoveDownTopic} className="btn text-muted shadow-none btn-sm p-0">
                                <FontAwesomeIcon className="me-2" icon={faArrowDown} />
                            </button>
                        )}
                    </div>
                    <span className="p-2 text-muted shadow-none">
                        {topic.topic_title}
                    </span>
                    <button onClick={onEdit} className="btn text-muted shadow-none" data-bs-toggle="modal" data-bs-target="#topicForm">
                        <FontAwesomeIcon icon={faPen} />
                    </button>
                    <button onClick={onDelete} className="btn text-muted shadow-none">
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                    {topic.lessons.length < 1 && (
                        <button onClick={onSetTopicId} className="btn text-muted shadow-none" data-bs-toggle="modal" data-bs-target="#lessonForm">
                            <FontAwesomeIcon className="text-primary me-2" icon={faPlusCircle} />
                            Add Lesson / Quiz
                        </button>
                    )}
                    <span className="my-auto ms-auto">{topic.lessons.length} Lesson</span>
                    <button onClick={() => setOpenItem(!openItem)} className="btn text-muted collapsed bg-white shadow-none" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index} aria-expanded="false" aria-controls={"collapse" + index}>
                        <FontAwesomeIcon className="me-2" icon={!openItem ? faChevronDown : faChevronUp} />
                    </button>
                </div>
            </div>
            <div id={"collapse" + index} className="accordion-collapse collapse" aria-labelledby={"heading" + index}>
                <div className="accordion-body">
                    {/* Looping Lesson & Quiz */}
                    <ListLesson
                        data={topic.lessons}
                        onEditLesson={onEditLesson}
                        onDeleteLesson={onDeleteLesson}
                        onMoveUpLesson={onMoveUpLesson}
                        onMoveDownLesson={onMoveDownLesson}
                        onEditQuiz={onEditQuiz}
                        onEditQuestion={onEditQuestion}
                        onSetQuizId={onSetQuizId}
                        onDeleteQuestion={onDeleteQuestion}
                    />
                    {/* End Looping Lesson & Quiz */}

                    <div className="text-center">
                        {topic.lessons.length > 0 ? (
                            <button onClick={onSetTopicId} className="btn text-muted shadow-none my-3" data-bs-toggle="modal" data-bs-target="#lessonForm">
                                <FontAwesomeIcon className="text-primary me-2" icon={faPlusCircle} />
                                Add Lesson / Quiz
                            </button>
                        ) : (<p className="text-muted">No data</p>)}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ItemTopic;