import Skeleton from "react-loading-skeleton";

const SkeletonCardNote = () => {
    return (
        <>
            <div className="col-md-4">
                <div className="card h-100">
                    <div className="card-header">
                        <Skeleton count={1} className="bg-white" width={200} />
                    </div>
                    <div className="card-body text-muted">
                        <Skeleton count={2} className="bg-white" width={200} />
                    </div>
                    <div className="card-footer bg-white border-1 text-end">
                        <div className="d-flex float-end">
                            <Skeleton count={1} className="bg-white" width={200} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-8">
                <div className="card h-100">
                    <div className="card-body text-muted">
                        <Skeleton count={2} className="bg-white" width={200} />
                    </div>
                    <div className="card-footer bg-white border-1 text-end">
                        <div className="d-flex float-end">
                            <Skeleton count={1} className="bg-white" width={200} />
                            <Skeleton count={1} className="bg-white" width={200} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SkeletonCardNote;
