import Skeleton from "react-loading-skeleton";
import CardPopular from "../CardPopular/CardPopular";

const SectionPopular = ({ loading, tutorials }) => {
    return (
        <section className="card section my-5">
            <div className="card-body p-4">
                <h3 className="section-title h4">Populer Minggu Ini</h3>

                <div className="row mt-3">
                    {loading && [...Array(6)].map(item => (
                        <div className="col-md-6 col-lg-4 mb-3" key={item}>
                            <div className="card">
                                <div className="card-body">
                                    <Skeleton count={2} width="80%" />
                                </div>
                            </div>
                        </div>
                    ))}
                    {!loading && tutorials?.map((tutorial, index) => {
                        return (
                            <div className="col-md-6 col-lg-4 mb-3" key={index}>
                                <a className="link" href={`http://codepolitan.com/blog/${tutorial.slug}`} target="_blank" rel="noopener noreferrer">
                                    <CardPopular
                                        type={tutorial.type}
                                        title={tutorial.title}
                                        writer={tutorial.writer}
                                        createdAt={tutorial.created_at}
                                        totalSeen={tutorial.total_seen}
                                    />
                                </a>
                            </div>
                        );
                    }).slice(0, 6)}
                    {!loading && tutorials?.length < 1 && (
                        <p>Ups, artikel tidak ditemukan. Coba dengan kata kunci lain...</p>
                    )}
                </div>
            </div>
        </section>
    );
};

export default SectionPopular;