import ItemLesson from "../../Items/ItemLesson/ItemLesson";

const ListLesson = ({ data, onEditLesson, onDeleteLesson, onMoveUpLesson, onMoveDownLesson, onEditQuiz, onEditQuestion, onSetQuizId, onDeleteQuestion }) => {
    return (
        <div className="accordion" id="sub-customAccordion">
            {data?.map((lesson, index) => {
                return (
                    <ItemLesson
                        key={index}
                        index={index}
                        lesson={lesson}
                        lessonLength={data?.length}
                        onEdit={() => onEditLesson(lesson.id)}
                        onDelete={() => onDeleteLesson(lesson.id)}
                        onMoveUpLesson={() => onMoveUpLesson(lesson.topic_id, lesson.id, lesson.lesson_order)}
                        onMoveDownLesson={() => onMoveDownLesson(lesson.topic_id, lesson.id, lesson.lesson_order)}
                        onEditQuiz={() => onEditQuiz(lesson.quiz_id)}
                        onSetQuizId={() => onSetQuizId(lesson.quiz_id)}
                        onEditQuestion={onEditQuestion}
                        onDeleteQuestion={onDeleteQuestion}
                    />
                );
            })}
        </div>
    );
};

export default ListLesson;
