import { faClock, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "../../../../utils/helper";

const CardEvent = ({ title, schedule, location, type, thumbnail }) => {
    return (
        <div className="card rounded-3 shadow-sm border-0">
            <img className="card-img-top" src={thumbnail || '/assets/img/placeholder.jpg'} style={{ height: '13em', objectFit: 'cover', borderTopRightRadius: '15px', borderTopLeftRadius: '15px' }} alt={title} />
            <div className="card-body text-muted">
                {type?.toLowerCase() === 'online' && (
                    <span className="small fw-bolder text-primary text-uppercase">event online</span>
                )}
                {type?.toLowerCase() === 'offline' && (
                    <span className="small fw-bolder text-pink text-uppercase">event offline</span>
                )}
                <h6 className="card-title text-ellipsis-2 my-2">{title}</h6>
                <small>
                    <p className="mb-1"><FontAwesomeIcon fixedWidth icon={faClock} /> {formatDate(schedule)}</p>
                    <p className="mb-1"><FontAwesomeIcon fixedWidth icon={faLocationDot} /> {location}</p>
                </small>
            </div>
        </div>
    );
};

export default CardEvent;
