import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import Layout from '../../../components/global/Layout/Layout'
import SectionEarningsSummary from '../../../components/mentor/Sections/SectionEarningsSummary/SectionEarningsSummary'
import SectionTransactionHistory from '../../../components/mentor/Sections/SectionTransactionHistory/SectionTransactionHistory'
import { getMentorStatistic, getMentorStatisticYear, getMentorTransaction, getMentorTransactionStats } from '../../../features/mentor/mentorSlice'
import SectionStatistics from '../../../components/mentor/Sections/SectionStatistics/SectionStatistics'
import Sidebar from '../../../components/mentor/Sidebar/Sidebar'
import SectionEarningGraphic from '../../../components/mentor/Sections/SectionEarningsGraphic/SectionEarningsGraphic'

const Earnings = () => {
    const dispatch = useDispatch();
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        dispatch(getMentorStatisticYear(currentYear));
        dispatch(getMentorTransaction());
        dispatch(getMentorStatistic());
        dispatch(getMentorTransactionStats());
    }, [dispatch, currentYear]);

    return (
        <>
            <Helmet>
                <title>Mentor</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            {/* Sidebar */}
                            <div className="col-lg-2">
                                <Sidebar />
                            </div>
                            {/* End of Sidebar */}

                            {/* Main Content */}
                            <div className="col-lg-10 text-muted px-4 px-lg-5">
                                <SectionEarningsSummary />
                                <SectionEarningGraphic />
                                <SectionStatistics />
                                <SectionTransactionHistory />
                            </div>
                            {/* End of Main Content */}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Earnings