import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Helmet } from 'react-helmet-async';
import Layout from "../../../components/global/Layout/Layout";
import { useParams } from "react-router-dom";
import { capitalizeFirstLetter, formatPrice } from "../../../utils/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from "react-to-print";
import { userToken } from "../../../utils/config";

const InvoiceDetail = () => {
    const [orderDetail, setOrderDetail] = useState(null);
    const [loading, setLoading] = useState(false);

    const { orderId } = useParams();
    const ref = useRef();

    useEffect(() => {
        const getOrderDetail = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/payment/detail/${orderId}`, {
                    headers: {
                        Authorization: userToken
                    }
                })
                setLoading(false);
                setOrderDetail(response.data);
            } catch (error) {
                throw new Error(error);
            }
        }
        getOrderDetail();
    }, [orderId]);

    return (
        <>
            <Helmet>
                <title>Detil Transaksi</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between">
                            <h2 className="section-title h4">Detil Transaksi</h2>
                            <ReactToPrint
                                trigger={() => <button className="btn btn-outline-secondary btn-sm">Download <FontAwesomeIcon icon={faDownload} /></button>}
                                content={() => ref.current}
                            />
                        </div>
                        <hr />
                        <div className="p-3" ref={ref}>
                            <div className="row justify-content-between p-3 bg-white mb-5 placeholder-glow">
                                <div className="col-lg-4">
                                    <h6>Prepared for</h6>
                                    <h5>{orderDetail?.name || <span className="placeholder col-6 rounded-pill" />}</h5>
                                    <p className="text-muted">{orderDetail?.address || <span className="placeholder col-8 rounded-pill" />}</p>
                                </div>
                                <div className="col-lg-4">
                                    <h6>Prepared by</h6>
                                    <h5>Codepolitan</h5>
                                    <p className="text-muted">Komp.Permata, Jl. Permata Raya I No.3, Tanimulya, Kec. Ngamprah, Kabupaten Bandung Barat, Jawa Barat 40552</p>
                                </div>
                                <div className="col-lg-4 text-lg-end">
                                    <h6>Invoice</h6>
                                    <p className="text-muted">#{orderDetail?.order_code}</p>
                                    <div className="d-block mb-3">
                                        <span className={orderDetail?.transaction_status === "expired" ? "badge bg-danger" : "badge bg-primary"}>{orderDetail && capitalizeFirstLetter(orderDetail?.transaction_status)}</span>
                                    </div>
                                </div>
                            </div>
                            <h4>Invoice Summary</h4>
                            <div className="row">
                                <div className="col">
                                    <div className="card border-0 card-rounded my-4">
                                        <div className="card-body px-0 overflow-auto">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Qty</th>
                                                        <th>Price</th>
                                                        <th>Subtotal</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {loading && [1, 2, 3].map((item) => {
                                                        return (
                                                            <tr className="placeholder-glow" key={item}>
                                                                <td><span className="placeholder col-6 rounded-pill" /></td>
                                                                <td><span className="placeholder col-3 rounded-pill" /></td>
                                                                <td className="text-end"><span className="placeholder col-6 rounded-pill" /></td>
                                                                <td className="text-end"><span className="placeholder col-6 rounded-pill" /></td>
                                                            </tr>
                                                        );
                                                    })}
                                                    {!loading && orderDetail?.items.map((item, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <tr className="text-muted">
                                                                    <td>{item.product_name}</td>
                                                                    <td>{item.qty}</td>
                                                                    <td className="text-end">Rp{formatPrice(item.price)}</td>
                                                                    <td className="text-end">Rp{formatPrice(item.subtotal)}</td>
                                                                </tr>
                                                                <tr className="text-danger text-end">
                                                                    <td colSpan={3}>
                                                                        Expedition Cost
                                                                    </td>
                                                                    <td>
                                                                        Rp{formatPrice(item.expedition_cost) || 0}
                                                                    </td>
                                                                </tr>
                                                                <tr className="text-muted text-end">
                                                                    <td colSpan={3}>
                                                                        Unique Code
                                                                    </td>
                                                                    <td>
                                                                        Rp 0
                                                                    </td>
                                                                </tr>
                                                                <tr className="text-muted text-end">
                                                                    <td colSpan={3}>
                                                                        <strong>
                                                                            Total
                                                                        </strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>
                                                                            Rp{formatPrice(item.subtotal)}
                                                                        </strong>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default InvoiceDetail;
