import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/global/Layout/Layout";

// Sweetalert
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getUserByUsername, updateAvatarProfile } from "../../../features/user/userSlice";
import { useState, useEffect } from "react";
import { uploadImage } from "../../../utils/helper";
import Sidebar from "../../../components/Settings/Sidebar/Sidebar";
import DropdownMenu from "../../../components/Settings/DropdownMenu/DropdownMenu";

const Avatar = () => {
    const MySwal = withReactContent(Swal);
    const [loading, setLoading] = useState(false);
    const [avatar, setAvatar] = useState('');
    const [loadingAvatar, setLoadingAvatar] = useState(false);

    const { myUser, user } = useSelector((state) => state.user);

    const dispatch = useDispatch();

    useEffect(() => {
        if (myUser?.username) {
            dispatch(getUserByUsername(myUser?.username));
        }
    }, [dispatch, myUser?.username]);

    const avatars = [
        'https://image.web.id/images/Avatar.jpg',
        'https://image.web.id/images/Avatar15.jpg',
        'https://image.web.id/images/Avatar14.jpg',
        'https://image.web.id/images/Avatar13.jpg',
        'https://image.web.id/images/Avatar12.jpg',
        'https://image.web.id/images/Avatar11.jpg',
        'https://image.web.id/images/Avatar10.jpg',
        'https://image.web.id/images/Avatar9.jpg',
        'https://image.web.id/images/Avatar8.jpg',
        'https://image.web.id/images/Avatar7.jpg',
        'https://image.web.id/images/Avatar6.jpg',
        'https://image.web.id/images/Avatar5.jpg',
        'https://image.web.id/images/avatar4.jpg',
        'https://image.web.id/images/avatar3.jpg',
        'https://image.web.id/images/avatar2.jpg',
        'https://image.web.id/images/avatar1.jpg'
    ];

    const uploadAvatar = (avatar) => {
        MySwal.fire({
            title: 'Update',
            text: 'Update your profile picture?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            reverseButtons: true
        }).then(result => {
            if (result.isConfirmed) {
                setLoading(true);
                dispatch(updateAvatarProfile(avatar))
                    .then((response) => {
                        setLoading(false);
                        MySwal.fire({
                            icon: response.payload.status === 'success' ? 'success' : 'error',
                            title: response.payload.message,
                            showConfirmButton: false,
                            timer: 2000,
                            didOpen: () => {
                                Swal.hideLoading();
                            },
                        })
                    })
                    .catch(() => {
                        setLoading(false);
                        MySwal.fire({
                            icon: 'error',
                            title: 'Avatar failed to upload',
                            showConfirmButton: false,
                            timer: 2000,
                            didOpen: () => {
                                Swal.hideLoading();
                            },
                        })
                    })

            }
        })
    }

    if (loading) {
        MySwal.fire({
            html: '<strong>Please Wait</strong>',
            didOpen: () => {
                Swal.showLoading();
            },
            showConfirmButton: false
        })
    }


    return (
        <>
            <Helmet>
                <title>Avatar</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <div className="row my-5">
                            <div className="col-lg-2 d-none d-lg-block">
                                <Sidebar />
                            </div>
                            <div className="col-lg-10">
                                <div className="d-lg-none mb-3">
                                    <DropdownMenu />
                                </div>
                                <div className="bg-white p-3">
                                    <div className="card border-0 mb-3">
                                        <div className="card-header border-0">
                                            <h5 className="text-muted my-2">Pilih Avatar</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="row justify-content-center">
                                                {avatars.map((avatar, index) => {
                                                    return (
                                                        <div className="col-4 col-md-2 p-2 mb-3" key={index}>
                                                            <img onClick={() => uploadAvatar(avatar)} width="80" height="80" role="button" className={`d-block mx-auto rounded-circle ${avatar === user?.avatar && 'border border-primary border-4'}`} src={avatar || "/assets/img/placeholder.jpg"} alt={avatar} />
                                                        </div>
                                                    );
                                                })}

                                                {!loadingAvatar && avatar && (
                                                    <div className="col-4 col-md-2 p-2 mb-3">
                                                        <img onClick={() => uploadAvatar(avatar)} width="80" height="80" role="button" className={`d-block mx-auto rounded-circle ${avatar === user?.avatar && 'border border-primary border-4'}`} src={avatar || "/assets/img/placeholder.jpg"} alt={avatar} />
                                                    </div>
                                                )}

                                                <div className="col-4 col-md-2 p-2 mb-3">
                                                    <input className="form-control" onChange={(e) => uploadImage(e, setAvatar, setLoadingAvatar)} type="file" id="avatar" hidden />
                                                    <label className="d-flex flex-column justify-content-center align-items-center bg-light rounded-circle text-muted" role="button" htmlFor="avatar" style={{ width: '80px', height: '80px', margin: 'auto' }}>
                                                        <FontAwesomeIcon size="xl" icon={faPlus} />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout >
        </>
    );
};

export default Avatar;
