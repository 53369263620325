import { Link } from 'react-router-dom';
import styles from '../CardMyRoadmap/CardMyRoadmap.module.scss';

const CardLatestPurchase = ({ type, title, thumbnail, slug }) => {
    if (type === 'course') {
        return (
            <div className="card border-0 shadow-sm h-100 rounded-2 h-100">
                <img style={{ height: '10em', objectFit: 'cover', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} src={thumbnail || "/assets/img/placeholder.jpg"} className="card-img-top" loading="lazy" alt={title} />
                <div className="card-body">
                    <h5 className="card-text mt-2 fw-bold fs-6">{title || 'Untitled'}</h5>
                </div>
                <div className="card-footer bg-transparent">
                    <div className="d-flex justify-content-between">
                        <Link className="btn btn-outline-secondary btn-sm" to="/learn/courses/detail/panduan-member-codepolitan">Panduan</Link>
                        <a className="btn btn-primary btn-sm" href={slug} target="_blank" rel="noopener noreferrer">Mulai Belajar</a>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="card card-rounded position-relative">
            <img style={{ height: '20em', objectFit: 'cover' }} src={thumbnail || "/assets/img/placeholder.jpg"} className="card-rounded position-relative" alt={title} />
            <div className={`card-body px-4 text-white position-absolute bottom-0 w-100 d-flex flex-column justify-content-end ${styles.bg_gradient}`}>
                    <h5 className="fw-bold m-0 fs-6"><small>{title || 'Untitled'}</small></h5>
                    <hr />
                    <div className="d-flex justify-content-between">
                        <Link className="btn btn-outline-light btn-sm" to="/learn/courses/detail/panduan-member-codepolitan">Panduan</Link>
                        <a className="btn btn-primary btn-sm" href={slug} target="_blank" rel="noopener noreferrer">Mulai Belajar</a>
                    </div>
            </div>
        </div>
    );
};

export default CardLatestPurchase;
