import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

// Get Popular Courses
export const getPopularCourses = createAsyncThunk('courses/getPopularCourses', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/course/popular`);
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Free Courses
export const getFreeCourses = createAsyncThunk('courses/getFreeCourses', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/course?limit=200&course_label=free&page=1`);
            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

// Get My Courses
export const getMyCourses = createAsyncThunk('courses/getMyCourses', async (page) => {
    try {
        if (!userToken) return [];

        const endpoints = ['paid', 'free'];
        const requests = endpoints.map(mode =>
            axios.get(`${process.env.REACT_APP_API_URL}/lesson/progress/user?mode=${mode}&page=${page}&limit=50`, {
                headers: { Authorization: userToken }
            })
        );

        const responses = await Promise.all(requests);
        let myCourses = [];

        responses.forEach(response => {
            if (!response.data.error) {
                myCourses.push(...response.data);
            }
        });

        // Remove duplicate courses
        const filteredCourses = Array.from(new Set(myCourses.map(course => course.id)))
            .map(id => myCourses.find(course => course.id === id));

        return filteredCourses;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Courses by tag
export const getCoursesByTag = createAsyncThunk('courses/getCoursesByTag', async (tag) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/course/tag/${tag}`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Course by slug
export const getCourse = createAsyncThunk('courses/getCourse', async (slug) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/course/detailComprehensifByUser/${slug}`, {
                headers: {
                    Authorization: userToken
                }
            });

            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const courseSlice = createSlice({
    name: 'course',
    initialState: {
        myCourses: [],
        popularCourses: [],
        freeCourses: [],
        coursesByTag: [],
        course: {},
        loadingCourse: false,
        coursePage: 1
    },
    reducers: {
        incrementCoursePage: (state) => {
            state.coursePage += 1;
        }
    },
    extraReducers: {
        // Get Popular
        [getPopularCourses.pending]: (state, action) => {
            state.loadingCourse = true;
        },
        [getPopularCourses.fulfilled]: (state, action) => {
            state.loadingCourse = false;
            if (action.payload.error !== 'No Content') state.popularCourses = action.payload;
        },
        [getPopularCourses.rejected]: (state, action) => {
            state.loadingCourse = false;
        },

        // Get Free Courses
        [getFreeCourses.pending]: (state, action) => {
            state.loadingCourse = true;
        },
        [getFreeCourses.fulfilled]: (state, action) => {
            state.loadingCourse = false;
            state.freeCourses = action.payload;
        },
        [getFreeCourses.rejected]: (state, action) => {
            state.loadingCourse = false;
        },

        // Get My Courses
        [getMyCourses.pending]: (state, action) => {
            state.loadingCourse = true;
        },
        [getMyCourses.fulfilled]: (state, action) => {
            state.loadingCourse = false;
            action.payload.error !== 'No Content' && (state.myCourses = action.payload)
        },
        [getMyCourses.rejected]: (state, action) => {
            state.loadingCourse = false;
        },

        // Get Courses by Tag
        [getCoursesByTag.pending]: (state, action) => {
            state.loadingCourse = true;
        },
        [getCoursesByTag.fulfilled]: (state, action) => {
            state.loadingCourse = false;
            state.coursesByTag = action.payload;
        },
        [getCoursesByTag.rejected]: (state, action) => {
            state.loadingCourse = false;
        },

        // Get Single Course
        [getCourse.pending]: (state, action) => {
            state.loadingCourse = true;
        },
        [getCourse.fulfilled]: (state, action) => {
            state.loadingCourse = false;
            state.course = action.payload;
        },
        [getCourse.rejected]: (state, action) => {
            state.loadingCourse = false;
        }
    }
});

export const { incrementCoursePage } = courseSlice.actions;
export default courseSlice.reducer;
