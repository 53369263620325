import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.scss';
import App from './App';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-loading-skeleton/dist/skeleton.css'
import { Provider } from 'react-redux';
import store from './app/store';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </Provider>
);
