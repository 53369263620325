import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

// Get All Notes
export const getAllNotes = createAsyncThunk('lesson/getAllNotes', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/dashboard/notes/get_all`, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: userToken
                }
            });

            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Notes by Lesson
export const getNotes = createAsyncThunk('lesson/getNotes', async (lessonId) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/entry/index/notes?filter[lesson_id]=${lessonId}`, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: userToken
                }
            });

            return response.data.results;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Single Note
export const getNote = createAsyncThunk('lesson/geNote', async (id) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/entry/detail/notes/${id}`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data.result;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const postNote = createAsyncThunk('lesson/postNote', async ({ lessonId, noteMinute, noteTitle, noteDescription }, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('lesson_id', lessonId);
        data.append('minute', noteMinute);
        data.append('title', noteTitle);
        data.append('description', noteDescription);

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/entry/insert/notes`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getNotes(lessonId));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const updateNote = createAsyncThunk('lesson/updateNote', async ({ id, lessonId, noteMinute, noteTitle, noteDescription }, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('lesson_id', lessonId);
        data.append('minute', noteMinute);
        data.append('title', noteTitle);
        data.append('description', noteDescription);

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/entry/update/notes/${id}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getNotes(lessonId));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const deleteNote = createAsyncThunk('lesson/deleteNote', async ({ id, lessonId }, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/entry/delete/notes/${id}`, {
                headers: {
                    Authorization: userToken
                }
            });
            dispatch(getNotes(lessonId));
            dispatch(getAllNotes());
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const initialState = {
    allNotes: [],
    notes: [],
    note: {},
    postNoteResult: {},
    updateNoteResult: {},
    deleteNoteResult: {},
    loadingNotes: false
};

const noteSlice = createSlice({
    name: 'notes',
    initialState,
    reducers: {
        resetNoteState: () => initialState
    },
    extraReducers: {
        // Get All Notes
        [getAllNotes.pending]: (state, action) => {
            state.loadingNotes = true;
        },
        [getAllNotes.fulfilled]: (state, action) => {
            state.loadingNotes = false;
            state.allNotes = action.payload ? action.payload : [];
        },
        [getAllNotes.rejected]: (state, action) => {
            state.loadingNotes = false;
        },

        // Get Notes
        [getNotes.pending]: (state, action) => {
            state.loadingNotes = true;
        },
        [getNotes.fulfilled]: (state, action) => {
            state.loadingNotes = false;
            state.notes = action.payload;
        },
        [getNotes.rejected]: (state, action) => {
            state.loadingNotes = false;
        },

        // Get Note
        [getNote.pending]: (state, action) => {
            state.loadingNotes = true;
        },
        [getNote.fulfilled]: (state, action) => {
            state.loadingNotes = false;
            state.note = action.payload;
        },
        [getNote.rejected]: (state, action) => {
            state.loadingNotes = false;
        },

        // post note
        [postNote.pending]: (state, action) => {
            state.loadingNotes = true;
        },
        [postNote.fulfilled]: (state, action) => {
            state.loadingNotes = false;
            state.postNoteResult = action.payload;
        },
        [postNote.rejected]: (state, action) => {
            state.loadingNotes = false;
        },

        // update note
        [updateNote.pending]: (state, action) => {
            state.loadingNotes = true;
        },
        [updateNote.fulfilled]: (state, action) => {
            state.loadingNotes = false;
            state.updateNoteResult = action.payload;
        },
        [updateNote.rejected]: (state, action) => {
            state.loadingNotes = false;
        },

        // delete note
        [deleteNote.pending]: (state, action) => {
            state.loadingNotes = true;
        },
        [deleteNote.fulfilled]: (state, action) => {
            state.loadingNotes = false;
            state.deleteNoteResult = action.payload;
        },
        [deleteNote.rejected]: (state, action) => {
            state.loadingNotes = false;
        },
    }
});

export const { resetNoteState } = noteSlice.actions;
export default noteSlice.reducer;
