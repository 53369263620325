import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

export const getLatestPayments = createAsyncThunk('payment/getLatestPayments', async () => {
    try {

        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/payment/latest`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const getPaymentOrders = createAsyncThunk('payment/getPaymentOrders', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/payment/orders?limit=1000`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            });
            return response.data.results;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const paymentSlice = createSlice({
    name: 'payment',
    initialState: {
        latestPayments: [],
        paymentOrders: [],
        loadingPayment: false
    },
    extraReducers: {
        [getLatestPayments.pending]: (state, action) => {
            state.loadingPayment = true;
        },
        [getLatestPayments.fulfilled]: (state, action) => {
            state.loadingPayment = false;
            state.latestPayments = action.payload;
        },
        [getLatestPayments.rejected]: (state, action) => {
            state.loadingPayment = false;
        },

        // Get Payment Orders
        [getPaymentOrders.pending]: (state, action) => {
            state.loadingPayment = true;
        },
        [getPaymentOrders.fulfilled]: (state, action) => {
            state.loadingPayment = false;
            state.paymentOrders = action.payload;
        },
        [getPaymentOrders.rejected]: (state, action) => {
            state.loadingPayment = false;
        },
    }
});

export default paymentSlice.reducer;
