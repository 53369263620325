import SkeletonCardInteractiveCoding from '../../../skeletons/SkeletonCardInteractiveCoding'
import CardInteractiveCoding from '../../Card/CardInteractiveCoding/CardInteractiveCoding'

const SectionInteractiveCoding = ({ loading, data }) => {
    return (
        <section className="section my-5">
            <h4 className="section-title mb-5">Playground</h4>

            <div className="row my-3">
                {loading && [1, 2, 3, 4].map(item => (
                    <div key={item} className="col-sm-6 col-md-4 col-xl-3 p-2">
                        <SkeletonCardInteractiveCoding />
                    </div>
                ))}
                {!loading && data?.map((item, index) => {
                    return (
                        <div key={index} className="col-sm-6 col-md-4 col-xl-3 p-2">
                            <a className="link" href={`https://www.codepolitan.com/interactive-coding/${item.course_slug}`} target="_blank" rel="noopener noreferrer">
                                <CardInteractiveCoding
                                    thumbnail={item.course_cover}
                                    title={item.course_title}
                                />
                            </a>
                        </div>
                    )
                })}
            </div>
        </section >
    )
}

export default SectionInteractiveCoding