const CardContinueLearning = ({ title, percentage, thumbnail }) => {
    return (
        <div className="card rounded-2">
            <div className="card-body">
                <div className="row mb-3">
                    <div className="col-auto pe-0">
                        <img width={45} height={45} className="objectfit-cover rounded-2" src={thumbnail} alt={title} />
                    </div>
                    <div className="col my-auto">
                        <h5 className="h6">{title}</h5>
                    </div>
                </div>
                <div className="progress" style={{ height: '7px' }}>
                    <div className="progress-bar" role="progressbar" style={{ width: `${percentage}%` }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                </div>
                <div className="row justify-content-between small">
                    <div className="col-auto">
                        <p className="text-muted my-2">Lanjutkan Belajar</p>
                    </div>
                    <div className="col-auto">
                        <p className="text-muted my-2">{percentage}%</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardContinueLearning;
