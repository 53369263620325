// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import { useSelector } from 'react-redux';
import CardContinueLearning from '../../Card/CardContinueLearning/CardContinueLearning';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

const SectionContinueLearning = () => {
    const { latestLearn, loadingLearn } = useSelector((state) => state.learn);

    return (
        <section className="section" hidden={!loadingLearn && latestLearn?.length < 1}>
            <h4 className="section-title mb-4">Lanjutkan Belajar</h4>

            <Swiper
                // install Swiper modules
                modules={[Pagination]}
                pagination={{ clickable: true }}
                spaceBetween={10}
                slidesPerView={2.3}
                breakpoints={{
                    // when window width is >= 414px
                    200: {
                        slidesPerView: 1.3,
                    },
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 2.1,
                    },
                }}
                style={{ paddingBottom: '60px' }}
            >
                {loadingLearn && [1, 2, 3].map(item => (
                    <SwiperSlide key={item}>
                        <div className="card rounded-2">
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col-3">
                                        <Skeleton className="rounded-2" height={50} width="100%" />
                                    </div>
                                    <div className="col-9 my-auto">
                                        <Skeleton width="60%" />
                                    </div>
                                </div>
                                <Skeleton height={10} width="100%" />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}

                {!loadingLearn && latestLearn?.filter((course => course.course_title !== 'Panduan Member CODEPOLITAN' && course.percentage < 100)).map((course, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <Link className="link" to={`/learn/courses/detail/${course.slug}`}>
                                <CardContinueLearning
                                    title={course.course_title}
                                    percentage={course.percentage}
                                    thumbnail={course.cover}
                                />
                            </Link>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </section>
    );
};

export default SectionContinueLearning;