import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

// Get Popular Courses
export const getInteractiveCodingCourses = createAsyncThunk('courses/getInteractiveCodingCourses', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/intcoding/course`);
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const interactiveCodingCourseSlice = createSlice({
    name: 'interactiveCodingCourse',
    initialState: {
        interactiveCodingCourses: [],
        loadingInteractiveCourse: false
    },
    extraReducers: {
        // Get My Courses
        [getInteractiveCodingCourses.pending]: (state, action) => {
            state.loadingInteractiveCourse = true;
        },
        [getInteractiveCodingCourses.fulfilled]: (state, action) => {
            state.loadingInteractiveCourse = false;
            state.interactiveCodingCourses = action.payload;
        },
        [getInteractiveCodingCourses.rejected]: (state, action) => {
            state.loadingInteractiveCourse = false;
        }
    }
});

export default interactiveCodingCourseSlice.reducer;
