import ItemQuestion from "../../Items/ItemQuestion/ItemQuestion";

const ListQuestion = ({ data, onEditQuestion, onDeleteQuestion }) => {
    return (
        <ul className="list-group overflow-auto">
            {/* Looping Question */}
            {data.questions.map((question, index) => {
                return (
                    <ItemQuestion
                        key={index}
                        index={index}
                        title={question.question_title}
                        onEdit={() => onEditQuestion(question.id)}
                        onDelete={() => onDeleteQuestion(data.quiz_id, question.id)}
                    />
                );
            })}
            {/* End of Looping Questions */}
        </ul>
    );
};

export default ListQuestion;