import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken, username } from "../../utils/config";

export const getMyUser = createAsyncThunk('user/getMyUser', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/profile/detail/${username}`, {
                headers: {
                    Authorization: userToken
                }
            });
            localStorage.setItem('availableForWork', response.data.available_for_work);
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const getUserByUsername = createAsyncThunk('user/getUserByUsername', async (username) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/profile/detail/${username}`, {
                headers: {
                    Authorization: userToken
                }
            });
            localStorage.setItem('availableForWork', response.data.available_for_work);
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const updateWorkStatus = createAsyncThunk('user/updateWorkStatus', async (workStatus, { dispatch }) => {
    try {
        let data = {
            "available_for_work": workStatus
        };

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/profile/update_work`, data, {
                headers: {
                    Authorization: userToken
                }
            });
            dispatch(getMyUser());
            dispatch(getUserByUsername(username));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const updateProfile = createAsyncThunk('user/updateProfile', async (payload, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/profile/update`, payload, {
                headers: {
                    Authorization: userToken
                }
            });
            dispatch(getMyUser());
            dispatch(getUserByUsername(username));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const updateSocmed = createAsyncThunk('user/updateSocmed', async (payload, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/profile/update_social_media`, payload, {
                headers: {
                    Authorization: userToken
                }
            });
            dispatch(getMyUser());
            dispatch(getUserByUsername(username));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const updateJob = createAsyncThunk('user/updateJob', async (payload, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/profile/update_job`, payload, {
                headers: {
                    Authorization: userToken
                }
            });
            dispatch(getMyUser());
            dispatch(getUserByUsername(username));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const updateShortDescription = createAsyncThunk('user/updateShortDescription', async (payload, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/profile/update_short_description`, payload, {
                headers: {
                    Authorization: userToken
                }
            });
            dispatch(getMyUser());
            dispatch(getUserByUsername(username));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const updateAvatarProfile = createAsyncThunk('user/updateAvatarProfile', async (avatar, { dispatch }) => {
    let data = {
        "profile_picture": avatar
    };

    try {
        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/profile/updateProfilePicture`, JSON.stringify(data), {
                headers: {
                    Authorization: userToken
                }
            });
            dispatch(getMyUser());
            dispatch(getUserByUsername(username))
            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState: {
        myUser: [],
        user: [],
        workStatusResult: {},
        loadingUser: false
    },
    extraReducers: {
        [getMyUser.pending]: (state, action) => {
            state.loadingUser = true;
        },
        [getMyUser.fulfilled]: (state, action) => {
            state.loadingUser = false;
            state.myUser = action.payload;
        },
        [getMyUser.rejected]: (state, action) => {
            state.loadingUser = false;
        },

        [getUserByUsername.pending]: (state, action) => {
            state.loadingUser = true;
        },
        [getUserByUsername.fulfilled]: (state, action) => {
            state.loadingUser = false;
            state.user = action.payload;
        },
        [getUserByUsername.rejected]: (state, action) => {
            state.loadingUser = false;
        },

        [updateWorkStatus.pending]: (state, action) => {
            state.loadingUser = true;
        },
        [updateWorkStatus.fulfilled]: (state, action) => {
            state.loadingUser = false;
            state.workStatusResult = action.payload;
        },
        [updateWorkStatus.rejected]: (state, action) => {
            state.loadingUser = false;
        },


        [updateSocmed.pending]: (state, action) => {
            state.loadingUser = true;
        },
        [updateSocmed.fulfilled]: (state, action) => {
            state.loadingUser = false;
        },
        [updateSocmed.rejected]: (state, action) => {
            state.loadingUser = false;
        },

        [updateJob.pending]: (state, action) => {
            state.loadingUser = true;
        },
        [updateJob.fulfilled]: (state, action) => {
            state.loadingUser = false;
        },
        [updateJob.rejected]: (state, action) => {
            state.loadingUser = false;
        },

        [updateAvatarProfile.pending]: (state, action) => {
            state.loadingUser = true;
        },
        [updateAvatarProfile.fulfilled]: (state, action) => {
            state.loadingUser = false;
        },
        [updateAvatarProfile.rejected]: (state, action) => {
            state.loadingUser = false;
        }
    }
});

export default userSlice.reducer;
