import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

export const getSkillOptions = createAsyncThunk('user/getSkillOptions', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/entry/index/skills?perpage=1000`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data.results;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const getSkills = createAsyncThunk('user/getSkills', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/entry/index/user_skill`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data.results;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const getSkill = createAsyncThunk('user/getSkill', async (id, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/entry/detail/user_skill/${id}`, {
                headers: {
                    Authorization: userToken
                }
            });
            dispatch(getSkills());
            return response.data.result;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const postSkill = createAsyncThunk('user/postSkill', async (payload, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('skill', payload.skill);
        data.append('category', 'skill');
        data.append('level', payload.level);

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/entry/insert/user_skill`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getSkills());
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const updateSkill = createAsyncThunk('user/updateSkill', async (payload, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('skill', payload.skill);
        data.append('category', 'skill');
        data.append('level', payload.level);

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/entry/update/user_skill/${payload.id}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getSkills());
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const deleteSkill = createAsyncThunk('user/deleteSkill', async (id, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/entry/delete/user_skill/${id}`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getSkills());
            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

const initialState = {
    skillOptions: [],
    skills: [],
    skill: {},
    postSkillAlert: {},
    updateSkillAlert: {},
    deleteSkillAlert: {},
    loadingSkills: false,
    loadingSkill: false
};

const skillSlice = createSlice({
    name: 'skill',
    initialState,
    reducers: {
        resetSkillState: () => initialState
    },
    extraReducers: {
        //Get Skill Options
        [getSkillOptions.pending]: (state, action) => {
            state.loadingSkills = true;
        },
        [getSkillOptions.fulfilled]: (state, action) => {
            state.loadingSkills = false;
            state.skillOptions = action.payload;
        },
        [getSkillOptions.rejected]: (state, action) => {
            state.loadingSkills = false;
        },

        // Get Portfolios Array
        [getSkills.pending]: (state, action) => {
            state.loadingSkills = true;
        },
        [getSkills.fulfilled]: (state, action) => {
            state.loadingSkills = false;
            state.skills = action.payload;
        },
        [getSkills.rejected]: (state, action) => {
            state.loadingSkills = false;
        },

        // Get Single Portfolio
        [getSkill.pending]: (state, action) => {
            state.loadingSkill = true;
        },
        [getSkill.fulfilled]: (state, action) => {
            state.loadingSkill = false;
            state.skill = action.payload;
        },
        [getSkill.rejected]: (state, action) => {
            state.loadingSkill = false;
        },

        // Post Portfolio
        [postSkill.pending]: (state, action) => {
            state.loadingSkills = true;
        },
        [postSkill.fulfilled]: (state, action) => {
            state.loadingSkills = false;
            state.postSkillAlert = action.payload;
        },
        [postSkill.rejected]: (state, action) => {
            state.loadingSkills = false;
        },

        // Update Portfolio
        [updateSkill.pending]: (state, action) => {
            state.loadingSkills = true;
        },
        [updateSkill.fulfilled]: (state, action) => {
            state.loadingSkills = false;
            state.updateSkillAlert = action.payload;
        },
        [updateSkill.rejected]: (state, action) => {
            state.loadingSkills = false;
        },

        // Update Portfolio
        [deleteSkill.pending]: (state, action) => {
            state.loadingSkills = true;
        },
        [deleteSkill.fulfilled]: (state, action) => {
            state.loadingSkills = false;
            state.deleteSkillAlert = action.payload;
        },
        [deleteSkill.rejected]: (state, action) => {
            state.loadingSkills = false;
        },
    }
});

export const { resetSkillState } = skillSlice.actions;
export default skillSlice.reducer;
