import { formatPrice } from "../../../../utils/helper";
import { useSelector } from "react-redux";

const SectionStatistics = () => {
    const { statisticYear } = useSelector((state) => state.mentor);

    const { total_order, total_transaction, total_earning, total_earning_after_tax, tax } = statisticYear?.reduce((acc, statistic) => {
        acc.total_order += statistic.total_order || 0;
        acc.total_transaction += statistic.total_transaction || 0;
        acc.total_earning += statistic.total_earning || 0;
        acc.total_earning_after_tax += statistic.total_earning_after_tax || 0;
        acc.tax += statistic.tax || 0;
        return acc;
    }, { total_order: 0, total_transaction: 0, total_earning: 0, total_earning_after_tax: 0, tax: 0 });

    return (
        <section className="my-4">
            <h4 className="section-title mb-3">Tabel Statistik Pertahun</h4>

            <div className="card border-0 rounded shadow-sm">
                <div className="card-body overflow-auto">
                    <table className="table table-striped mb-0">
                        <thead>
                            <tr>
                                <th>Month</th>
                                <th>Order</th>
                                <th>Transactions</th>
                                <th>Earnings</th>
                                <th>Tax</th>
                                <th>Earning after tax</th>
                            </tr>
                        </thead>
                        <tbody>
                            {statisticYear?.map((statistic, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{statistic.date}</td>
                                        <td>{statistic.total_order}</td>
                                        <td>Rp {formatPrice(statistic.total_transaction)}</td>
                                        <td>Rp {formatPrice(statistic.total_earning)}</td>
                                        <td className="text-danger">Rp {formatPrice(statistic.tax)}</td>
                                        <td className="text-primary">Rp {formatPrice(statistic.total_earning_after_tax)}</td>
                                    </tr>
                                );
                            }).reverse()}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td className="fw-bolder">Total</td>
                                <td className="fw-bolder">{total_order || 0}</td>
                                <td className="fw-bolder">Rp {formatPrice(total_transaction) || 0}</td>
                                <td className="fw-bolder">Rp {formatPrice(total_earning) || 0}</td>
                                <td className="fw-bolder text-danger">Rp {formatPrice(tax) || 0}</td>
                                <td className="fw-bolder text-primary">Rp {formatPrice(total_earning_after_tax) || 0}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div >
        </section >
    );
};

export default SectionStatistics;
