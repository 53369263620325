import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../utils/helper";

const SectionSuggestedArticles = () => {
    const { tutorials, loadingTutorial } = useSelector(state => state.tutorials);

    return (
        <section className="section mb-4 mb-xl-5">
            <h4 className="section-title mb-4">Thread Terbaru</h4>

            {!loadingTutorial ? tutorials?.map((tutorial, index) => {
                return (
                    <div className="card rounded-2 mb-3" key={index}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <a className="link" href={`${process.env.REACT_APP_URL}/blog/${tutorial.slug}`} target="_blank" rel="noopener noreferrer">
                                        <h5 className="fs-6 fw-bold mb-0">{tutorial.title}</h5>
                                    </a>
                                </div>
                            </div>
                            <hr />
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0 text-muted small"><FontAwesomeIcon icon={faUser} /> <i>{tutorial.writer}</i></p>
                                </div>
                                <div className="col-auto">
                                    <i className="small text-muted">{formatDate(tutorial.created_at)}</i>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }).slice(0, 5) : null}
        </section>
    );
};

export default SectionSuggestedArticles;
