import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Layout from "../../../components/global/Layout/Layout";
import { getDetailMentorRoadmap } from '../../../features/mentor/mentorRoadmapSlice';

// Sweetalert
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { updateMentorRoadmapProduct } from '../../../features/mentor/mentorProductSlice';
import Sidebar from '../../../components/mentor/Sidebar/Sidebar';

const EditRoadmapPricing = () => {
    const MySwal = withReactContent(Swal);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();
    const { register, reset, handleSubmit, setValue, formState: { errors } } = useForm();

    const [isPaid, setIsPaid] = useState(false)
    const [productId, setProductId] = useState(null)

    useEffect(() => {
        dispatch(getDetailMentorRoadmap(id)).then(res => {
            const roadmap = res.payload.product
            if (roadmap) {
                setProductId(roadmap.id)
                reset({
                    normal_price: roadmap.normal_price,
                    retail_price: roadmap.retail_price,
                })
                setIsPaid(roadmap.normal_price > 0 ? true : false)
            }
        })
    }, [dispatch, id, reset])

    useEffect(() => {
        if (!isPaid) {
            setValue('normal_price', 0);
            setValue('retail_price', 0);
        };
    }, [isPaid, setValue]);

    const onSubmit = (data) => {
        data.product_id = productId
        dispatch(updateMentorRoadmapProduct(data)).then(response => {
            if (response.payload.status === "success") {
                // Alert Success
                MySwal.fire({
                    title: <strong>Success!</strong>,
                    text: response.payload.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2500
                });
                navigate('/mentor/roadmaps');
            } else {
                MySwal.fire({
                    title: <strong>Oops!</strong>,
                    text: response.payload.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                });
            }
        })
    };

    return (
        <>
            <Helmet>
                <title>Edit Roadmap</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            {/* Sidebar */}
                            <div className="col-lg-2">
                                <Sidebar />
                            </div>
                            {/* End of Sidebar */}

                            {/* Main Content */}
                            <div className="col-lg-10 text-muted px-4 px-lg-5">
                                <h3 className="section-title mb-5">Edit Roadmap</h3>

                                <ul className="nav nav-pills text-nowrap overflow-auto flex-nowrap my-4" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link px-4" to={`/mentor/roadmaps/edit/overview/${id}`}>Overview</Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link px-4" to={`/mentor/roadmaps/edit/curriculum/${id}`}>Curriculum</Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link px-4 active" to={`/mentor/roadmaps/edit/pricing/${id}`}>Pricing</Link>
                                    </li>
                                </ul>

                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="bg-white border p-3">
                                            <div className="card border-0">
                                                <div className="card-header">
                                                    <h5 className="section-title my-auto">Pricing</h5>
                                                </div>
                                                <div className="card-body">
                                                    <form onSubmit={handleSubmit(onSubmit)}>
                                                        <div className="mb-3">
                                                            <div className="d-flex my-3">
                                                                <label className="h5">Gratis</label>
                                                                <div className="form-check form-switch ms-3 me-2">
                                                                    <input
                                                                        onChange={(e) => setIsPaid(e.target.checked)}
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        checked={isPaid}
                                                                    />
                                                                </div>
                                                                <label className="h5">Berbayar</label>
                                                            </div>
                                                            <p className="text-muted">Sistem harga di codepolitan adalah sistem bagi hasil 70% untuk kamu yang membuat roadmap, dan 30% untuk pihak Codepolitan</p>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6 mb-3">
                                                                <label htmlFor="normalPrice" className="form-label fw-bolder">Harga Normal</label>
                                                                <div className="input-group">
                                                                    <span className="input-group-text bg-white text-muted border-end-0">Rp</span>
                                                                    <input name="normalPrice" type="number" className="form-control border-start-0" placeholder="0" {...register('normal_price', { required: true })} />
                                                                </div>
                                                                {errors.normal_price && errors.normal_price.type === 'required' && <span className="text-danger">This field is required</span>}
                                                            </div>
                                                            <div className="col-lg-6 mb-3">
                                                                <label htmlFor="retailPrice" className="form-label fw-bolder">Harga Retail</label>
                                                                <div className="input-group">
                                                                    <span className="input-group-text bg-white text-muted border-end-0">Rp</span>
                                                                    <input name="retailPrice" type="number" className="form-control border-start-0" placeholder="0" {...register('retail_price', { required: true })} />
                                                                </div>
                                                                {errors.retail_price && errors.retail_price.type === 'required' && <span className="text-danger">This field is required</span>}
                                                            </div>
                                                        </div>
                                                        <div className="my-3 float-end">
                                                            <button type="submit" className="btn btn-primary btn-rounded px-4 py-2">Save & exit</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 d-none d-lg-block">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <FontAwesomeIcon className="text-primary me-3" size="3x" icon={faLightbulb} />
                                                    <h5 className="text-muted my-auto">Setting Harga Roadmap</h5>
                                                </div>
                                                <p className="text-muted my-3">Berikan harga yang sesuai dengan benefit yang kamu berikan disini, ini akan sangat mempengaruhi strategi penjualan roadmap kamu.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* End of Main Content */}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default EditRoadmapPricing;
