import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BannerHeader = ({ title, subtitle, image, children, setShowBanner, dismissible }) => {
    return (
        <div className="overflow-hidden">
            <div className="card border-0 p-2 position-relative" style={{ minHeight: '10em' }}>
                <div className="card-body position-relative" style={{ zIndex: '10' }}>
                    <h4 className="text-secondary" style={{ fontWeight: '600px' }}>{title || "Untitled"}</h4>
                    <p className="text-muted w-75 mb-3">{subtitle}</p>
                    <div className="my-3">
                        {children}
                    </div>
                </div>
                <img src="/assets/img/learn/Vector 23.png" className="w-50 position-absolute" style={{ right: '-3%', bottom: '-10%' }} alt="" />
                <img src={image} className="position-absolute d-lg-none" style={{ width: '31%', right: '3%', bottom: '0%' }} alt="" />
                <img src={image} className="position-absolute d-none d-lg-block" style={{ width: '31%', right: '3%', bottom: '0%', top: '0%' }} alt="" />
                {dismissible && (<button onClick={setShowBanner} className="btn position-absolute" style={{ zIndex: '99', right: '1%' }}><FontAwesomeIcon size="xl" icon={faTimes} /></button>)}
            </div>
        </div>
    );
};

export default BannerHeader;
