import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

export const getOccupations = createAsyncThunk('user/getOccupations', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/entry/index/user_occupation`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data.results;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const getOccupation = createAsyncThunk('user/getOccupation', async (id, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/entry/detail/user_occupation/${id}`, {
                headers: {
                    Authorization: userToken
                }
            });
            dispatch(getOccupations());
            return response.data.result;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const postOccupation = createAsyncThunk('user/postOccupation', async (payload, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('title', payload.title);
        data.append('company', payload.company);
        data.append('description', payload.description);
        data.append('date_start', payload.date_start);
        data.append('date_end', payload.date_end ? payload.date_end : undefined);
        data.append('letter', payload.letter);

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/entry/insert/user_occupation`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getOccupations());
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const updateOccupation = createAsyncThunk('user/updateOccupation', async (payload, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('title', payload.title);
        data.append('company', payload.company);
        data.append('description', payload.description);
        data.append('date_start', payload.date_start);
        data.append('date_end', payload.date_end);
        data.append('letter', payload.letter);

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/entry/update/user_occupation/${payload.id}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getOccupations());
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const deleteOccupation = createAsyncThunk('user/deleteOccupation', async (id, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/entry/delete/user_occupation/${id}`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getOccupations());
            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

const initialState = {
    occupations: [],
    occupation: {},
    loadingOccupation: false
};

const occupationSlice = createSlice({
    name: 'occupation',
    initialState,
    extraReducers: {
        // Get Portfolios Array
        [getOccupations.pending]: (state, action) => {
            state.loadingOccupation = true;
        },
        [getOccupations.fulfilled]: (state, action) => {
            state.loadingOccupation = false;
            state.occupations = action.payload;
        },
        [getOccupations.rejected]: (state, action) => {
            state.loadingOccupation = false;
        },

        // Get Single Portfolio
        [getOccupation.pending]: (state, action) => {
            state.loadingOccupation = true;
        },
        [getOccupation.fulfilled]: (state, action) => {
            state.loadingOccupation = false;
            state.occupation = action.payload;
        },
        [getOccupation.rejected]: (state, action) => {
            state.loadingOccupation = false;
        },

        // Post Portfolio
        [postOccupation.pending]: (state, action) => {
            state.loadingOccupation = true;
        },
        [postOccupation.fulfilled]: (state, action) => {
            state.loadingOccupation = false;
        },
        [postOccupation.rejected]: (state, action) => {
            state.loadingOccupation = false;
        },

        // Update Portfolio
        [updateOccupation.pending]: (state, action) => {
            state.loadingOccupation = true;
        },
        [updateOccupation.fulfilled]: (state, action) => {
            state.loadingOccupation = false;
        },
        [updateOccupation.rejected]: (state, action) => {
            state.loadingOccupation = false;
        },

        // Update Portfolio
        [deleteOccupation.pending]: (state, action) => {
            state.loadingOccupation = true;
        },
        [deleteOccupation.fulfilled]: (state, action) => {
            state.loadingOccupation = false;
        },
        [deleteOccupation.rejected]: (state, action) => {
            state.loadingOccupation = false;
        },
    }
});

export default occupationSlice.reducer;
