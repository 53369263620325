import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { getAllEvents } from '../../../features/events/eventSlice';
import Layout from "../../../components/global/Layout/Layout";
import SectionEvents from '../../../components/learn/Section/SectionEvents/SectionEvents';

const Webinar = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllEvents());
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <title>Events</title>
            </Helmet>
            <Layout>
                <section id="learnWebinar" className="section mt-5">
                    <div className="container-fluid">
                        <SectionEvents />
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default Webinar;
