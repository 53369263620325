const SkeleteonCardNotification = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="py-2">
                    <h5 className="card-title placeholder-glow">
                        <span className="placeholder rounded-pill col-2 me-2" />
                        <span className="placeholder rounded-pill col-3" />
                    </h5>
                    <p className="card-text placeholder-glow">
                        <span className="placeholder rounded-pill col-12" />
                        <span className="placeholder rounded-pill col-4" />
                    </p>
                </div>
                <div className="my-2">
                    <span></span>
                </div>
            </div>
        </div>
    )
}

export default SkeleteonCardNotification;
