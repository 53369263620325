import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "../../../../utils/helper";

const CardLatest = ({ thumbnail, title, writer, createdAt, excerpt, type }) => {
    return (
        <div className="card mb-3">
            <div className="row g-0">
                <div className="col-md-4">
                    <img src={thumbnail || '/assets/img/placeholder.jpg'} className="img-fluid rounded-start h-100 w-100 objectfit-cover" alt="..." />
                </div>
                <div className="col-md-8 my-auto">
                    <div className="card-body">
                        {type === 'post' && (<span className="badge bg-primary mb-2">Article</span>)}
                        {type === 'tutorial' && (<span className="badge bg-danger mb-2">Tutorial</span>)}
                        {type === 'event' && (<span className="badge bg-info mb-2">Event</span>)}
                        <h5 className="card-text text-ellipsis-3 mt-3">{title || 'Untitled'}</h5>
                        <small className="text-muted mb-3">
                            <i>
                                <FontAwesomeIcon icon={faUser} /> {writer || 'Unknown'} &bull; {formatDate(createdAt)}
                            </i>
                        </small>
                        <p className="mt-3 text-truncate">
                            <small className="text-muted">
                                {excerpt}
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardLatest;
