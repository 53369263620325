import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Banner = ({query, setQuery}) => {
    return (
        <div
            className="card mb-5 border-0"
            style={{
                background: `url('https://image.web.id/images/Group-4007.png')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}>
            <div className="card-body p-5 my-5">
                <h2 className="section-title text-center mb-4 w-75 mx-auto">Artikel dan Tutorial</h2>
                <p className="text-muted text-center">Temukan tutorial pemrograman praktis dan tepat guna, serta informasi terbaru seputar pemrograman disini.</p>
                <div className="row justify-content-center mt-4">
                    <div className="col-lg-6">
                        <div className="input-group shadow-sm mb-3">
                            <span className="input-group-text bg-white text-muted border-end-0" id="basic-addon1">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <input onChange={(e) => setQuery(e.target.value)} value={query} className="form-control border-start-0" type="search" placeholder="Cari apa yang kamu perlukan disini..." />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner