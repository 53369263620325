import { useSelector } from "react-redux";
import SkeletonCardCourse from "../../../skeletons/SkeletonCardCourse";
import CardLatestPurchase from "../../Card/CardLatestPurchase/CardLatestPurchase";

const SectionlatestPurchase = () => {
  const { latestPayments, loadingPayment } = useSelector((state) => state.payment);

  return (
    <section className="section mb-4 mb-xl-5" hidden={latestPayments?.length < 1}>
      <h4 className="section-title mb-4">Pembelian Terbaru</h4>
      <div className="row">
        {loadingPayment && (
          <div className="col">
            <SkeletonCardCourse />
          </div>
        )}
        {!loadingPayment ? (
          <div className="col">
            <CardLatestPurchase
              type={latestPayments[0]?.type}
              title={latestPayments[0]?.title}
              thumbnail={latestPayments[0]?.cover}
              slug={latestPayments[0]?.slug}
            />
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default SectionlatestPurchase;
