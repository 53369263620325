import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getFaqs = createAsyncThunk('banner/getFaqs', async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/entry/index/faq`);
        return response.data.results;
    } catch (error) {
        throw new Error(error);
    }
});

const faqSlice = createSlice({
    name: 'faqs',
    initialState: {
        faqs: [],
        loadingFaq: false
    },
    extraReducers: {
        [getFaqs.pending]: (state, action) => {
            state.loadingFaq = true;
        },
        [getFaqs.fulfilled]: (state, action) => {
            state.loadingFaq = false;
            state.faqs = action.payload;
        },
        [getFaqs.rejected]: (state, action) => {
            state.loadingFaq = false;
        }
    }
});

export default faqSlice.reducer;
