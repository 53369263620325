import { Link } from "react-router-dom";
import CardExerciseTopic from "../../Cards/CardExerciseTopic/CardExerciseTopic";

const SectionExercisesByTopic = ({ data, loading }) => {
    return (
        <section className="mb-5">
            <h4 className="section-title mb-4">Exercises by Topik</h4>
            <div className="row">
                {!loading && data.map((item, index) => {
                    return (
                        <div className="col-md-6 col-lg-4 col-xl-3 p-2" key={index}>
                            <Link className="link" to={`/exercises/tag/${item.slug}`}>
                                <CardExerciseTopic
                                    name={item.name}
                                    thumbnail={item.thumbnail}
                                />
                            </Link>
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

export default SectionExercisesByTopic;
