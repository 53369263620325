import { faCheckCircle, faClock, faFileDownload, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './SectionSidebar.module.scss';
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { formatPrice } from "../../../../utils/helper";

const SectionSidebar = () => {
    const { slug } = useParams();
    const { course } = useSelector((state) => state.courses);
    const { currentCourseState } = useSelector((state) => state.completedCourse);

    const buy = course?.products?.find(product => product.tags === 'buy');
    const rent = course?.products?.find(product => product.tags === 'rent');

    const normalBuyPrice = buy?.normal_price;
    const retailBuyPrice = buy?.retail_price;

    const buyDiscount = normalBuyPrice - retailBuyPrice;
    const buyDiscountPercentage = (buyDiscount / normalBuyPrice) * 100;

    const normalRentPrice = rent?.normal_price;
    const retailRentPrice = rent?.retail_price;

    const rentDiscount = normalRentPrice - retailRentPrice;
    const rentDiscountPercentage = (rentDiscount / normalRentPrice) * 100;

    const roadmapOnly = course?.products?.find(product => product.retail_price === -1);
    const buyOnly = course?.products?.find(product => product.retail_price === -2);
    const freeCourse = course?.products?.find(product => product.retail_price === 0);

    const coursePrecentage = (course?.total_marked / course?.total_module) * 100;

    return (
        <section className={styles.sidebar}>
            <div className="card border-0 rounded-2">
                <div className="card-img-top">
                    <div className="ratio ratio-16x9">
                        <iframe style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} src={`https://www.youtube.com/embed/${course?.course?.preview_video}?rel=0`} title="YouTube video" allowFullScreen />
                    </div>
                </div>

                {/* Kelas Belum Aktif */}
                {!course?.purchased && !roadmapOnly && !freeCourse && buy && (
                    <div className="card-body p-0">
                        <nav>
                            <div className="nav nav-tabs flex-row" id="navTabSidebarCourseDetail" role="tablist">
                                <button className="flex-fill text-center nav-link active" id="nav-buy-tab" data-bs-toggle="tab" data-bs-target="#nav-buy" type="button" role="tab" aria-controls="nav-buy" aria-selected="true">
                                    <span className="fw-bolder">Beli</span>
                                    <p className="mb-0">Rp {formatPrice(retailBuyPrice)}</p>
                                </button>
                                <button className={`flex-fill text-center nav-link ${buyOnly && 'disabled'}`} id="nav-rent-tab" data-bs-toggle="tab" data-bs-target="#nav-rent" type="button" role="tab" aria-controls="nav-rent" aria-selected="false">
                                    <span className="fw-bolder">Sewa</span>

                                    <p className="mb-0">{buyOnly ? 'Tidak Tersedia' : `Rp ${formatPrice(retailRentPrice)}`}</p>

                                </button>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane show active" id="nav-buy" role="tabpanel" aria-labelledby="nav-buy-tab">
                                <div className="p-3">
                                    {retailBuyPrice < normalBuyPrice && (
                                        <del className="text-danger">Rp {formatPrice(normalBuyPrice)}</del>
                                    )}
                                    <div className="d-flex">
                                        <p className="h3 my-auto">Rp {formatPrice(retailBuyPrice)}</p>
                                        {retailBuyPrice < normalBuyPrice && (
                                            <p className="text-muted fst-italic ms-2 my-auto">{Math.floor(buyDiscountPercentage)}% Off</p>
                                        )}
                                    </div>
                                </div>
                                {retailBuyPrice < normalBuyPrice && (
                                    <div className="mb-3">
                                        <span className="badge bg-pink py-1 me-2" style={{ borderRadius: '0 20px 20px 0' }}>Hemat Rp {formatPrice(buyDiscount)}</span>
                                        <span style={{ fontSize: '0.6rem' }} className="text-danger">
                                            <FontAwesomeIcon icon={faClock} />
                                            <span className="ms-1">Diskon sampai hari ini</span>
                                        </span>
                                    </div>
                                )}
                                <p className="text-center text-muted">Beli sekali, akses selamanya</p>
                                <div className="d-grid px-3 pb-3">
                                    <a className="btn btn-primary" href={`http://pay.codepolitan.com?slug=${buy?.product_slug}`} target="_blank" rel="noopener noreferrer">Beli Kelas</a>
                                </div>
                            </div>
                            <div className="tab-pane" id="nav-rent" role="tabpanel" aria-labelledby="nav-rent-tab">
                                <div className="p-3">
                                    {retailRentPrice < normalRentPrice && (
                                        <del className="text-danger">Rp {formatPrice(normalRentPrice)}</del>
                                    )}
                                    <div className="d-flex">
                                        <p className="h3 my-auto">Rp {formatPrice(retailRentPrice)}</p>
                                        {retailRentPrice < normalRentPrice && (
                                            <p className="text-muted fst-italic ms-2 my-auto">{Math.floor(rentDiscountPercentage)}% Off</p>
                                        )}
                                    </div>
                                </div>
                                {retailRentPrice < normalRentPrice && (
                                    <div className="mb-3">
                                        <span className="badge bg-pink py-1 me-2" style={{ borderRadius: '0 20px 20px 0' }}>Hemat Rp {formatPrice(rentDiscount)}</span>
                                        <span style={{ fontSize: '0.6rem' }} className="text-danger">
                                            <FontAwesomeIcon icon={faClock} />
                                            <span className="ms-1">Diskon sampai hari ini</span>
                                        </span>
                                    </div>
                                )}
                                <p className="text-center text-muted">Sewa kelas dan akses selama 1 bulan</p>
                                <div className="d-grid px-3 pb-3">
                                    <a className="btn btn-primary" href={`http://pay.codepolitan.com?slug=${rent?.product_slug}`} target="_blank" rel="noopener noreferrer">Sewa Kelas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Tidak Dijual Satuan */}
                {!buy && !freeCourse && !course?.purchased && !roadmapOnly && (
                    <div className="card-body">
                        <h5>Tidak Dijual Satuan</h5>
                        <p>Kelas ini tidak dijual satuan</p>
                    </div>
                )}

                {/* Kelas Sudah Aktif */}
                {course?.purchased || freeCourse ? (
                    <div className="card-body">
                        {currentCourseState?.status === "start" && (
                            <h5>Mulai Belajar</h5>
                        )}
                        {currentCourseState?.status === "progress" && (
                            <h5>Lanjutkan Belajar</h5>
                        )}
                        {currentCourseState?.status === "finish" && course?.course?.enable_certificate !== 0 && (
                            <h5>Kelas Telah Selesai</h5>
                        )}

                        <div className="progress mt-3" style={{ height: '8px' }}>
                            <div className="progress-bar" role="progressbar" style={{ width: `${coursePrecentage <= 100 ? Math.floor(coursePrecentage) : 100 || 0}%` }} aria-valuenow={coursePrecentage <= 100 ? Math.floor(coursePrecentage) : 100 || 0} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                        <div className="text-muted text-end mt-2">
                            <p className="small">{coursePrecentage <= 100 ? Math.floor(coursePrecentage) : 100 || 0}% Progres belajar</p>
                        </div>
                        <div className="d-grid mt-3">
                            {currentCourseState?.status === "start" && (
                                <a className="btn btn-primary btn-rounded" href={`/learn/courses/${slug}/lessons/${currentCourseState?.lesson_id}`}>Masuk Kelas</a>
                            )}
                            {currentCourseState?.status === "progress" && (
                                <a className="btn btn-primary btn-rounded" href={`/learn/courses/${slug}/lessons/${currentCourseState?.lesson_id}`}>Lanjutkan Kelas</a>
                            )}
                            {currentCourseState?.status === "finish" && course?.course?.enable_certificate !== 0 && (
                                <Link className="btn btn-primary btn-rounded" to={`/learn/courses/${slug}/claim-certificate`}>Lihat Sertifikat</Link>
                            )}
                        </div>
                    </div>
                ) : null}

                {/* Beli Lewat Roadmap */}
                {!course?.purchased && roadmapOnly && course?.course?.title !== 'Mengenal Pemrograman Komputer' && (
                    <div className="card-body">
                        <h5>Beli Lewat Roadmap</h5>
                        <p>Kelas ini dibundling dalam Roadmap</p>
                        <div className="d-grid mt-3">
                            <a className="btn btn-primary btn-rounded" href="http://codepolitan.com/roadmap" target="_blank" rel="noopener noreferrer">Lihat Roadmap</a>
                        </div>
                    </div>
                )}
            </div>

            <div className="card border-0 mt-3">
                <div className="card-body">
                    <h5 className="text-muted mb-3">Yang Akan Kamu Dapatkan</h5>
                    <div className="d-flex mb-2">
                        <FontAwesomeIcon className="text-primary my-auto me-2" icon={faCheckCircle} />
                        <p className="my-auto text-muted">{course?.course?.total_module || 0} Modul</p>
                    </div>
                    <div className="d-flex mb-2">
                        <FontAwesomeIcon className="text-primary my-auto me-2" icon={faCheckCircle} />
                        <p className="my-auto text-muted">{course?.course?.total_time || 0} Jam Materi</p>
                    </div>
                    <div className="d-flex mb-2">
                        <FontAwesomeIcon className="text-primary my-auto me-2" icon={faCheckCircle} />
                        <p className="my-auto text-muted">Forum Tanya Jawab</p>
                    </div>
                    <div className="d-flex mb-2">
                        <FontAwesomeIcon className="text-primary my-auto me-2" icon={faCheckCircle} />
                        <p className="my-auto text-muted">Sertifikat</p>
                    </div>
                    {course?.course?.sourcecode_url && (
                        <div className="d-flex mb-2">
                            <FontAwesomeIcon className="text-primary my-auto me-2" icon={faCheckCircle} />
                            <p className="my-auto text-muted">Download Source Code</p>
                        </div>
                    )}
                </div>
            </div>

            {slug !== 'panduan-member-codepolitan' && (
                <div className="d-grid gap-2 mt-3">
                    {course?.course?.sourcecode_url && (
                        <a href={course?.course?.sourcecode_url} className="btn btn-primary" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon fixedWidth className="me-2" icon={faFileDownload} />Download Source Code</a>
                    )}
                    <a href="/learn/courses/detail/panduan-member-codepolitan" className="btn btn-outline-secondary" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon fixedWidth className="me-2" icon={faQuestionCircle} />Lihat Panduan Belajar</a>
                </div>
            )}
        </section>
    );
};

export default SectionSidebar;
