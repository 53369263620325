import { faBook, faChartSimple, faClock, faCode, faShare, faStar, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import ButtonShareSocmed from "../../../../components/global/Buttons/ButtonShareSocmed/ButtonShareSocmed"
import { useState } from "react"
import { useSelector } from "react-redux";

const SectionHeader = ({ type, loading, courseData, courseFeedbacks, ratingValue, lessonData }) => {
    const [showShare, setShowShare] = useState(false);
    const { theme } = useSelector((state) => (state.theme));

    return (
        <section className="section my-3">
            {type === "course" && (
                <div className={`card ${theme === 'dark' && 'bg-soft-dark text-white'}`}>
                    <div className="card-body text-muted p-4">
                        <div className="row mb-3">
                            <div className="col-auto mb-3 mb-lg-auto">
                                <img height="100" width="auto" loading="lazy" className="border border-3 rounded-3 objectfit-cover" src={courseData?.course?.thumbnail || '/assets/img/placeholder.jpg'} alt={courseData?.title} />
                            </div>
                            <div className="col-lg-9 my-auto">
                                {loading ?
                                    (
                                        <>
                                            <Skeleton count={1} className="bg-white" width="40%" />
                                            <Skeleton count={1} className="bg-white" width="100%" />
                                        </>
                                    ) : (
                                        <>
                                            <h1 className={`section-title ${theme === 'dark' && 'text-white'} h4`}>{courseData?.course?.title}</h1>
                                            <p className={theme === 'dark' ? 'text-white' : 'text-muted'} >{courseData?.course?.description}</p>
                                            {courseData?.course?.sourcecode_url ? (
                                                <a className={`btn btn-outline-${theme === 'dark' ? 'primary' : 'secondary'} btn-sm`} href={courseData?.course?.sourcecode_url} target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon className="me-2" icon={faCode} />
                                                    Download Source Code
                                                </a>
                                            ) : null}
                                        </>
                                    )}
                            </div>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-auto mb-3 mb-md-auto">
                                {loading ? (
                                    <Skeleton count={1} className="bg-white mt-2" width="80%" />
                                ) : (
                                    <>
                                        <span className="text-warning me-3">
                                            <FontAwesomeIcon icon={faStar} />
                                            <strong className="ms-1 me-2">{ratingValue.toFixed(1)}</strong>
                                            <span className="text-muted">({courseFeedbacks?.length} Penilaian)</span>
                                        </span>
                                        <span className="me-3">
                                            <FontAwesomeIcon icon={faUsers} />
                                            <span className="text-muted ms-2">{courseData?.total_student} Siswa</span>
                                        </span>
                                        <span className="me-3">
                                            <FontAwesomeIcon icon={faChartSimple} />
                                            <span className="text-muted ms-2 text-capitalize">Level {(courseData?.course?.level)}</span>
                                        </span>
                                        <span className="me-3">
                                            <FontAwesomeIcon icon={faBook} />
                                            <span className="text-muted ms-2">{courseData?.course?.total_module} Modul</span>
                                        </span>
                                        <span className="me-3">
                                            <FontAwesomeIcon icon={faClock} />
                                            <span className="text-muted ms-2">{courseData?.course?.total_time} Jam</span>
                                        </span>
                                    </>
                                )}
                            </div>
                            <div className="col-auto">
                                <button onClick={() => setShowShare(!showShare)} className="btn btn-primary btn-rounded px-3"><FontAwesomeIcon icon={faShare} /> Share</button>
                            </div>
                            {
                                showShare && (
                                    <div className="row my-2">
                                        <div className="col text-center">
                                            <ButtonShareSocmed type="facebook" link={`https://codepolitan.com/course/intro/${courseData?.course?.slug}`} />
                                            <ButtonShareSocmed type="twitter" link={`https://codepolitan.com/course/intro/${courseData?.course?.slug}`} />
                                            <ButtonShareSocmed type="linkedin" link={`https://codepolitan.com/course/intro/${courseData?.course?.slug}`} />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            )}
            {type === "lesson" && (
                <div className="card bg-transparent border-0">
                    <div className="card-body text-muted px-0">
                        {loading ? (<Skeleton count={1} className="bg-white" width="50%" />) : (
                            <h1 className={`section-title h4 ${theme === 'dark' && 'text-white'}`}>{lessonData?.lesson_title}</h1>
                        )}
                    </div>
                </div>
            )}
        </section>
    );
};

export default SectionHeader;
