import { faArrowRight, faClock, faSwatchbook } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './CardMyLearning.module.scss';

const CardMyLearning = ({ thumbnail, courseTitle, totalModule, totalTime, marked }) => {
    let coursePercentage = Math.floor((marked / totalModule) * 100);

    return (
        <div className={`card border-0 shadow-sm h-100 rounded-2 position-relative ${styles.card_mylearning}`}>
            <div className="position-absolute" style={{ zIndex: 2, right: '10px', top: '5px' }}>
                <span className="badge bg-primary text-light">Kelas</span>
            </div>
            <img style={{ height: '10em', objectFit: 'cover', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} src={thumbnail || "/assets/img/placeholder.jpg"} className="card-img-top" loading="lazy" alt={courseTitle} />
            <div className="card-body text-muted">
                <h5 className="card-text mt-2 fw-bolder h6">{courseTitle || 'Untitled'}</h5>
                <div className="row mb-2">
                    <div className="col-auto">
                        <small><FontAwesomeIcon fixedWidth icon={faSwatchbook} /> {totalModule || 0} modul</small>
                    </div>
                    <div className="col-auto">
                        <small><FontAwesomeIcon fixedWidth icon={faClock} /> {totalTime || 0} jam</small>
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="col-9 my-auto">
                        <div className="progress" style={{ height: '10px' }}>
                            <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${coursePercentage}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" />
                        </div>
                    </div>
                    <div className="col my-auto">
                        <small>{coursePercentage > 100 ? 100 : coursePercentage}%</small>
                    </div>
                </div>
            </div>
            <div className="card-footer bg-transparent">
                <div className="text-end">
                    <small>
                        <span className="text-center text-primary">
                            {coursePercentage === 0 && 'Mulai Belajar'}
                            {coursePercentage !== 0 && coursePercentage < 100 && 'Lanjut Belajar'}
                            {coursePercentage >= 100 && 'Lihat Kelas'}
                            <FontAwesomeIcon className="ms-1" icon={faArrowRight} />
                        </span>
                    </small>
                </div>
            </div>
        </div>
    )
}

export default CardMyLearning;
