import { faRedo, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { deleteMentorCourse } from "../../../../features/mentor/mentorCourseSlice";
import { capitalizeFirstLetter, removeHTMLTags } from "../../../../utils/helper";
import SkeletonCardMentorCourse from "../../../skeletons/SkeletonCardMentorCourse";
import CardCourse from "../../Cards/CardCourse/CardCourse";

const SectionCourses = ({ loading, data }) => {
    const [limit, setLimit] = useState(10);
    const [searchValue, setSearchValue] = useState('');

    const MySwal = withReactContent(Swal);

    const dispatch = useDispatch();

    const onDeleteCourse = (courseId) => {
        MySwal.fire({
            title: 'Delete Course?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result => {
            if (result.isConfirmed) {
                dispatch(deleteMentorCourse(courseId)).then((response) => {
                    // Alert
                    MySwal.fire({
                        title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                        text: removeHTMLTags(response.payload.message),
                        icon: response.payload.status === 'success' ? 'success' : 'error',
                        showConfirmButton: false,
                        timer: 2000
                    });
                });
            }
        }));
    };

    return (
        <section className="section mb-4">
            <div className="row justify-content-between">
                <div className="col-auto mb-3 mb-lg-auto">
                    <h3 className="section-title">Courses</h3>
                </div>
                <div className="col-auto col-lg-3">
                    <div className="input-group mb-3">
                        <span className="input-group-text bg-white border-1 text-muted border-end-0" id="basic-addon1"><FontAwesomeIcon icon={faSearch} /></span>
                        <input onChange={(e) => setSearchValue(e.target.value)} value={searchValue} type="text" className="form-control border-1 border-start-0" placeholder="Cari course..." />
                    </div>
                </div>
            </div>

            <ul className="nav nav-pills border-0 text-nowrap overflow-auto flex-nowrap my-4" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link px-4 active" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true">All Courses</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link px-4" id="pills-active-tab" data-bs-toggle="pill" data-bs-target="#pills-active" type="button" role="tab" aria-controls="pills-active" aria-selected="false">Active</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link px-4" id="pills-draft-tab" data-bs-toggle="pill" data-bs-target="#pills-draft" type="button" role="tab" aria-controls="pills-draft" aria-selected="false">Draft</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link px-4" id="pills-invicible-tab" data-bs-toggle="pill" data-bs-target="#pills-invicible" type="button" role="tab" aria-controls="pills-invicible" aria-selected="false">Invicible</button>
                </li>
            </ul>

            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                    {loading && [1, 2, 3].map(item => (
                        <SkeletonCardMentorCourse key={item} />
                    ))}
                    {!loading && data?.filter(course => {
                        if (course.course_title.toLowerCase().includes(searchValue.toLowerCase())) {
                            return course;
                        }
                        return undefined;

                    }).length < 1 && (
                            <h5 className="text-center">No data</h5>
                        )}
                    {!loading && data?.filter(course => {
                        if (course.course_title.toLowerCase().includes(searchValue.toLowerCase())) {
                            return course;
                        }

                        return undefined;
                    }).map((course, index) => {
                        return (
                            <div className="mb-3" key={index}>
                                <CardCourse
                                    id={course.id}
                                    thumbnail={course.thumbnail}
                                    title={course.course_title}
                                    status={course.status}
                                    totalStudent={course.total_student}
                                    totalRevenue={course.total_income}
                                    totalDiscussion={course.total_discussion}
                                    totalRating={course.rating}
                                    slug={course.slug}
                                    onDelete={() => onDeleteCourse(course.id)}
                                />
                            </div>
                        );
                    }).slice(0, limit)}
                    {!loading && data?.filter(course => {
                        if (course.course_title.toLowerCase().includes(searchValue.toLowerCase())) {
                            return course;
                        }
                        return undefined;

                    }).length > limit && (
                            <div className="text-center my-4">
                                <button onClick={() => setLimit(limit + 10)} className="btn btn-outline-secondary"><FontAwesomeIcon icon={faRedo} /> Load more</button>
                            </div>
                        )}
                </div>
                <div className="tab-pane fade" id="pills-active" role="tabpanel" aria-labelledby="pills-active-tab">
                    {loading && [1, 2, 3].map(item => (
                        <SkeletonCardMentorCourse key={item} />
                    ))}
                    {!loading && data?.filter(course => {
                        if (course.course_title.toLowerCase().includes(searchValue.toLowerCase())) {
                            return course.status === 'publish';
                        }
                        return undefined;

                    }).length < 1 && (
                            <h5 className="text-center">No data</h5>
                        )}

                    {!loading && data?.filter(course => {
                        if (course.course_title.toLowerCase().includes(searchValue.toLowerCase())) {
                            return course.status === 'publish';
                        }
                        return undefined;

                    }).map((course, index) => {
                        return (
                            <div className="mb-3" key={index}>
                                <CardCourse
                                    id={course.id}
                                    thumbnail={course.thumbnail}
                                    title={course.course_title}
                                    status={course.status}
                                    totalStudent={course.total_student}
                                    totalRevenue={course.total_income}
                                    totalDiscussion={course.total_discussion}
                                    totalRating={course.rating}
                                    slug={course.slug}
                                    onDelete={() => onDeleteCourse(course.id)}
                                />
                            </div>
                        );
                    }).slice(0, limit)}
                    {!loading && data?.filter(course => {
                        if (course.course_title.toLowerCase().includes(searchValue.toLowerCase())) {
                            return course.status === 'publish';
                        }
                        return undefined;

                    }).length > limit && (
                            <div className="text-center my-4">
                                <button onClick={() => setLimit(limit + 10)} className="btn btn-outline-secondary"><FontAwesomeIcon icon={faRedo} /> Load more</button>
                            </div>
                        )}
                </div>

                <div className="tab-pane fade" id="pills-draft" role="tabpanel" aria-labelledby="pills-draft-tab">
                    {loading && [1, 2, 3].map(item => (
                        <SkeletonCardMentorCourse key={item} />
                    ))}
                    {!loading && data?.filter(course => {
                        if (course.course_title.toLowerCase().includes(searchValue.toLowerCase())) {
                            return course.status === 'draft';
                        }
                        return undefined;

                    }).length < 1 && (
                            <h5 className="text-center">No data</h5>
                        )}

                    {!loading && data?.filter(course => {
                        if (course.course_title.toLowerCase().includes(searchValue.toLowerCase())) {
                            return course.status === 'draft';
                        }
                        return undefined;

                    }).map((course, index) => {
                        return (
                            <div className="mb-3" key={index}>
                                <CardCourse
                                    id={course.id}
                                    thumbnail={course.thumbnail}
                                    title={course.course_title}
                                    status={course.status}
                                    totalStudent={course.total_student}
                                    totalRevenue={course.total_income}
                                    totalDiscussion={course.total_discussion}
                                    totalRating={course.rating}
                                    slug={course.slug}
                                    onDelete={() => onDeleteCourse(course.id)}
                                />
                            </div>
                        );
                    }).slice(0, limit)}
                    {!loading && data?.filter(course => {
                        if (course.course_title.toLowerCase().includes(searchValue.toLowerCase())) {
                            return course.status === 'draft';
                        }
                        return undefined;

                    }).length > limit && (
                            <div className="text-center my-4">
                                <button onClick={() => setLimit(limit + 10)} className="btn btn-outline-secondary"><FontAwesomeIcon icon={faRedo} /> Load more</button>
                            </div>
                        )}
                </div>

                <div className="tab-pane fade" id="pills-invicible" role="tabpanel" aria-labelledby="pills-invicible-tab">
                    {loading && [1, 2, 3].map(item => (
                        <SkeletonCardMentorCourse key={item} />
                    ))}
                    {!loading && data?.filter(course => {
                        if (course.course_title.toLowerCase().includes(searchValue.toLowerCase())) {
                            return course.status === 'invicible';
                        }
                        return undefined;

                    }).length < 1 && (
                            <h5 className="text-center">No data</h5>
                        )}
                    {!loading && data?.filter(course => {
                        if (course.course_title.toLowerCase().includes(searchValue.toLowerCase())) {
                            return course.status === 'invicible';
                        }

                        return undefined;
                    }).map((course, index) => {
                        return (
                            <div className="mb-3" key={index}>
                                <CardCourse
                                    id={course.id}
                                    thumbnail={course.thumbnail}
                                    title={course.course_title}
                                    status={course.status}
                                    totalStudent={course.total_student}
                                    totalRevenue={course.total_income}
                                    totalDiscussion={course.total_discussion}
                                    totalRating={course.rating}
                                    slug={course.slug}
                                    onDelete={() => onDeleteCourse(course.id)}
                                />
                            </div>
                        );
                    }).slice(0, limit)}
                    {!loading && data?.filter(course => {
                        if (course.course_title.toLowerCase().includes(searchValue.toLowerCase())) {
                            return course.status === 'invicible';
                        }
                        return undefined;

                    }).length > limit && (
                            <div className="text-center my-4">
                                <button onClick={() => setLimit(limit + 10)} className="btn btn-outline-secondary"><FontAwesomeIcon icon={faRedo} /> Load more</button>
                            </div>
                        )}
                </div>
            </div>

        </section>
    );
};

export default SectionCourses;
