import { faSwatchbook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import BannerHeader from '../../../components/global/Banner/BannerHeader';
import Layout from "../../../components/global/Layout/Layout";
import Modal from '../../../components/global/Modal/Modal';
import SectionCourses from '../../../components/mentor/Sections/SectionCourses';
import { getMentorCourses, postMentorCourse } from '../../../features/mentor/mentorCourseSlice';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter, removeHTMLTags } from '../../../utils/helper';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import slugify from 'react-slugify';
import { getMentorRoadmap } from '../../../features/mentor/mentorRoadmapSlice';
import Sidebar from '../../../components/mentor/Sidebar/Sidebar';

const Courses = () => {
    const MySwal = withReactContent(Swal);

    const { mentorCourses, loadingMentorCourse } = useSelector((state) => state.mentorCourse);

    const { register, watch, setValue, handleSubmit, formState: { errors } } = useForm();

    const watchCourseTitleValue = watch('courseTitle');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getMentorCourses());
        dispatch(getMentorRoadmap());
    }, [dispatch]);

    useEffect(() => {
        setValue('courseSlug', slugify(watchCourseTitleValue));
    }, [setValue, watchCourseTitleValue]);

    const onSubmitCourse = (data) => {
        dispatch(postMentorCourse(data))
            .then((response) => {
                if (response.payload.status === 'success') {
                    const resetButton = document.querySelector('.btn-reset');
                    resetButton.click();

                    navigate(`/mentor/courses/edit/overview/${response.payload.id}`);
                } else {
                    // Alert Failed
                    MySwal.fire({
                        title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                        text: removeHTMLTags(response.payload.message),
                        icon: 'error'
                    });
                };
            });
    };

    return (
        <>
            <Helmet>
                <title>Courses</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            {/* Sidebar */}
                            <div className="col-lg-2">
                                <Sidebar />
                            </div>
                            {/* End of Sidebar */}

                            {/* Main Content */}
                            <div className="col-lg-10 text-muted px-4 px-lg-5">
                                <div className="mb-5">
                                    <BannerHeader
                                        title={
                                            <>
                                                Experience kamu sangat berharga untuk
                                                <br className="d-none d-lg-block" />
                                                masa depan programmer di Indonesia
                                            </>
                                        }
                                        image="/assets/img/learn/banner-courses.png"
                                        dismissible={false}
                                    >
                                        <button className="btn btn-sm btn-primary py-2 px-4 btn-rounded" data-bs-toggle="modal" data-bs-target="#courseForm">
                                            <FontAwesomeIcon icon={faSwatchbook} /> Buat Kelas
                                        </button>
                                    </BannerHeader>
                                </div>

                                <SectionCourses
                                    loading={loadingMentorCourse}
                                    data={mentorCourses}
                                />
                            </div>
                            {/* End of Main Content */}
                        </div>
                    </div>
                </section>
                <Modal title="Create Course" id="courseForm">
                    <form onSubmit={handleSubmit(onSubmitCourse)}>
                        <div className="mb-3">
                            <label htmlFor="courseTitle" className="form-label fw-bolder">Course Title</label>
                            <input name="courseTitle" className="form-control" placeholder="Course title" {...register("courseTitle", { required: true, maxLength: 50 })} />
                            {errors.courseTitle && errors.courseTitle.type === 'required' && <span className="text-danger">This field is required</span>}
                            {errors.courseTitle && errors.courseTitle.type === 'maxLength' && <span className="text-danger">Max length exceeded</span>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="courseSlug" className="form-label fw-bolder">Course Slug</label>
                            <input name="courseSlug" className="form-control" placeholder="course-slug" {...register("courseSlug", { required: true })} readOnly />
                            {errors.courseSlug && errors.courseSlug.type === 'required' && <span className="text-danger">This field is required</span>}
                        </div>
                        <div className="text-end">
                            <button type="reset" className="btn text-muted shadow-none btn-reset" data-bs-dismiss="modal">Cancel</button>
                            <button type="submit" className="btn btn-primary btn-rounded">Submit</button>
                        </div>
                    </form>
                </Modal>
            </Layout>
        </>
    );
};

export default Courses;
