import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SidebarItem = ({ title, link, icon, mobile, isNew }) => {
    const location = useLocation();

    return (
        <li data-bs-dismiss={mobile ? "offcanvas" : ""}>
            <Link className={`nav-link list-group-item list-group-item-action rounded mb-2 border-0 ${location.pathname === link ? 'active' : 'bg-transparent text-muted'}`} to={link}>
                <div className="d-flex align-items-start">
                    <FontAwesomeIcon fixedWidth className={location.pathname === link ? "text-white me-2 mt-1" : "me-2 mt-1"} icon={icon} />
                    {title || "Untitled Menu"}
                    {isNew && <span className="badge bg-danger ms-auto">New</span>}
                </div>
            </Link>
        </li>
    );
};

export default SidebarItem;