import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCourses = createAsyncThunk('browseCourse/getCourses', async ({ courseLabel, filter, sort, page, limit }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/course?page=${page}&limit=${limit}&course_label=${courseLabel}&filter=${filter}&sort=${sort}`);
        return { data: response.data, page };
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getCourseLabels = createAsyncThunk('browseCourse/getCourseLabels', async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/course/labels`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const browseCourse = createSlice({
    name: 'browseCourse',
    initialState: {
        courses: [],
        courseLabels: [],
        loadingBrowseCourse: false,
        page: 1,
        hasMore: true
    },
    reducers: {
        resetCourses: (state) => {
            state.courses = [];
            state.page = 1;
            state.hasMore = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCourses.pending, (state) => {
                state.loadingBrowseCourse = true;
            })
            .addCase(getCourses.fulfilled, (state, action) => {
                state.loadingBrowseCourse = false;
                const { data, page } = action.payload;

                if (data.length > 0) {
                    state.courses = page === 1 ? data : [...state.courses, ...data];
                    state.hasMore = data.length >= 11;
                } else {
                    state.hasMore = false;
                }
            })
            .addCase(getCourses.rejected, (state) => {
                state.loadingBrowseCourse = false;
            })
            .addCase(getCourseLabels.pending, (state, action) => {
                state.loadingBrowseCourse = true;
            })
            .addCase(getCourseLabels.fulfilled, (state, action) => {
                state.loadingBrowseCourse = false;
                state.courseLabels = action.payload;
            })
            .addCase(getCourseLabels.rejected, (state) => {
                state.loadingBrowseCourse = false;
            });
    }
});

export const { resetCourses } = browseCourse.actions;
export default browseCourse.reducer;