import { useSelector } from 'react-redux';
import CardIncome from '../../Cards/CardIncome/CardIncome'
import { formatPrice } from '../../../../utils/helper';

const SectionIncomeSummary = () => {
    const { statistic } = useSelector(state => state.mentor);

    let tax = statistic?.total_earning * (50 / 100) * (5 / 100);
    let netIncome = Math.floor(statistic?.total_earning - tax);


    return (
        <section>
            <h4 className="section-title mb-3">Ringkasan Penghasilan</h4>
            <div className="row g-3">
                <div className="col-md-4">
                    <CardIncome total={statistic?.total_transaction} price title="Total Transaksi" />
                </div>
                <div className="col-md-4">
                    <CardIncome total={statistic?.total_earning} price title="Total Penghasilan" />
                </div>
                <div className="col-md-4">
                    <CardIncome total={Math.floor(tax) || 0} price tax title="Total Pajak" />
                </div>
                <div className="col-md-4">
                    <CardIncome total={statistic?.total_order} title="Total Penjualan" />
                </div>
                <div className="col-md-4">
                    <CardIncome total={statistic?.total_order_this_week} arrow title="Penjualan Pekan Ini" />
                </div>
                <div className="col-md-4">
                    <CardIncome total={statistic?.total_earning_this_week} price arrow title="Penghasilan Pekan Ini" />
                </div>
            </div>

            <div className="card border-0 rounded shadow-sm mt-3">
                <div className="card-body text-center">
                    <h5>Penghasilan Bersih</h5>
                    <p className="fw-bolder h3 mb-0 text-primary">Rp {formatPrice(netIncome) || 0}</p>
                </div>
            </div>
        </section>
    )
}

export default SectionIncomeSummary