import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

// Get Course by slug
export const getFeedbacksByCourse = createAsyncThunk('feedback/getFeedbacksByCourse', async (slug) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/feedback/course/${slug}`);

            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const feedbackSlice = createSlice({
    name: 'feedback',
    initialState: {
        courseFeedbacks: [],
        loadingFeedback: false
    },
    extraReducers: {
        // Get Single Course
        [getFeedbacksByCourse.pending]: (state, action) => {
            state.loadingFeedback = true;
        },
        [getFeedbacksByCourse.fulfilled]: (state, action) => {
            state.loadingFeedback = false;
            state.courseFeedbacks = action.payload;
        },
        [getFeedbacksByCourse.rejected]: (state, action) => {
            state.loadingFeedback = false;
        }
    }
});

export default feedbackSlice.reducer;
