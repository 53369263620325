import { Link } from 'react-router-dom';
import CardCourseRoadmap from '../CardCourseRoadmap/CardCourseRoadmap';
import styles from './SectionTimeline.module.scss';

const SectionTimeline = ({ courses }) => {
    return (
        <section className="py-5">
            <ul className={styles.timeline_with_icons}>
                {Object.entries(courses !== undefined ? courses : []).map(([category, course], index) => {
                    return (
                        <li className={`${styles.timeline_item} mb-5`} key={category}>
                            <span className={styles.timeline_icon}>
                                <strong>{index + 1}</strong>
                            </span>
                            <h5 className="fw-bold">{category}</h5>
                            <p className="text-muted mb-2">{course.description}</p>
                            <div className="row my-3">
                                {course.items.map((item, index) => {
                                    return (
                                        <div className="col-lg-3 mb-3" key={index}>
                                            <Link className="link" to={`/learn/courses/detail/${item.slug}`}>
                                                <CardCourseRoadmap
                                                    thumbnail={item.cover}
                                                    title={item.course_title}
                                                    totalDuration={item.total_time}
                                                    totalModule={item.total_module}
                                                    status={item.completed}
                                                    labels={item.label}
                                                />
                                            </Link>
                                        </div>
                                    );
                                })}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
};

export default SectionTimeline;
