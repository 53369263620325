import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faBookReader, faBriefcase, faCode, faComments, faMasksTheater, faNewspaper, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import Layout from "../../components/global/Layout/Layout";

const JoinDiscord = () => {
    return (
        <>
            <Helmet>
                <title>Join Discord</title>
            </Helmet>
            <Layout>
                <section className="section py-5">
                    <div className="container-fluid">
                        <div
                            className="card mb-5 border-0"
                            style={{
                                background: `url('https://image.web.id/images/banner-discord.png')`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}>
                            <div className="card-body p-5 my-5">
                                <h2 className="section-title text-center mb-4 w-75 mx-auto">Selamat Datang di Komunitas Discord CODEPOLITAN!</h2>
                                <div className="text-center">
                                    <a className="btn btn-primary rounded-2" href="https://discord.gg/HF2UpWNCWX" target="_blank" rel="noopener noreferrer">Gabung Sekarang</a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="card mb-5 border-0"
                            style={{
                                background: `url('https://image.web.id/images/Group-3454.png')`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}>
                            <div className="card-body p-5 my-5">
                                <h3 className="section-title text-center">Tujuan Komunitas</h3>

                                <div className="row justify-content-center my-5">
                                    <div className="col-lg-8">
                                        <div className="ratio ratio-16x9">
                                            <iframe className="rounded-3" src="https://www.youtube.com/embed/bwsV2s0gJeI" title="YouTube video" allowFullScreen />
                                        </div>
                                    </div>
                                </div>

                                <p className="text-muted text-center">
                                    Komunitas ini disediakan untuk kamu yang suka kebingunan ketika ngoding atau memiliki masalah terkait pemrograman. Melalui komunitas Discord Codepolitan, kamu akan mendapatkan jawaban dengan cepat dari anggota Discord Codepolitan dan tentunya ga akan belajar sendirian lagi.
                                </p>
                            </div>
                        </div>
                        <div
                            className="card mb-5 border-0"
                            style={{
                                background: `url('https://image.web.id/images/Group-3453.png')`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}>
                            <div className="card-body p-5 mt-5">
                                <div className="text-center mb-5">
                                    <h3 className="section-title">Apa yang Kamu Dapatkan di Discord CODEPOLITAN?</h3>
                                    <p className="text-muted">Dengan komunitas Discord Code Politan, banyak manfaat yang akan kamu dapatkan</p>
                                </div>
                                <div className="row justify-content-center my-5 pt-5">
                                    <div className="col-md-6 col-lg-3 text-center mb-5">
                                        <FontAwesomeIcon size="3x" className="text-primary mb-3" icon={faUsers} />
                                        <p className="text-muted">Berkenalan Dengan Sesama Programer</p>
                                    </div>
                                    <div className="col-md-6 col-lg-3 text-center mb-5">
                                        <FontAwesomeIcon size="3x" className="text-primary mb-3" icon={faComments} />
                                        <p className="text-muted">Diskusi Teknologi dan Pemrograman</p>
                                    </div>
                                    <div className="col-md-6 col-lg-3 text-center mb-5">
                                        <FontAwesomeIcon size="3x" className="text-primary mb-3" icon={faBookReader} />
                                        <p className="text-muted">Update Informasi Belajar di CODEPOLITAN</p>
                                    </div>
                                    <div className="col-md-6 col-lg-3 text-center mb-5">
                                        <FontAwesomeIcon size="3x" className="text-primary mb-3" icon={faNewspaper} />
                                        <p className="text-muted">Update Informasi Event Teknologi dan Pemrograman</p>
                                    </div>
                                    <div className="col-md-6 col-lg-3 text-center mb-5">
                                        <FontAwesomeIcon size="3x" className="text-primary mb-3" icon={faCode} />
                                        <p className="text-muted">Informasi Seputar Coding</p>
                                    </div>
                                    <div className="col-md-6 col-lg-3 text-center mb-5">
                                        <FontAwesomeIcon size="3x" className="text-primary mb-3" icon={faBriefcase} />
                                        <p className="text-muted">Informasi Lowongan Kerja</p>
                                    </div>
                                    <div className="col-md-6 col-lg-3 text-center mb-5">
                                        <FontAwesomeIcon size="3x" className="text-primary mb-3" icon={faMasksTheater} />
                                        <p className="text-muted">Hiburan Geek Shitposting</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="card mb-5 border-0"
                            style={{
                                background: `url('https://image.web.id/images/Group-3472.png')`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'top'
                            }}>
                            <div className="card-body p-5 my-5">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h5 className="section-title text-white h2">Tunggu Apalagi ? Ayo Seru-Seruan Belajar Ngoding Bareng Di Server Discord CODEPOLITAN!</h5>
                                        <a className="btn btn-light rounded-2 my-3" href="https://discord.gg/HF2UpWNCWX" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="me-2" icon={faDiscord} /> Join Community</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default JoinDiscord;
