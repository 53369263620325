import Register from '../pages/Auth/Register/Register';
import ForgotPassword from '../pages/Auth/Recovery/ForgotPassword';
import ChangePassword from '../pages/Auth/Recovery/ChangePassword';

import Learn from '../pages/Learn';
import CourseDetail from '../pages/Learn/Courses/CourseDetail';
import CourseExam from '../pages/Learn/Courses/CourseExam';
import CourseFeedbackAndCertificate from '../pages/Learn/Courses/CourseFeedbackAndCertificate';
import CourseLesson from '../pages/Learn/Courses/CourseLesson';
import MyCourses from '../pages/Learn/Courses/Courses';
import RoadmapsOverview from '../pages/Learn/Roadmaps/Overview';
import Discussions from '../pages/Learn/Discussions/Discussions';
import Leaderboard from '../pages/Learn/Leaderboard/Leaderboard';
import Events from '../pages/Learn/Events/Events';
import Tutorial from '../pages/Learn/Tutorial/Tutorial';
// import ProfileOverview from '../pages/User';
import Redeem from '../pages/User/Redeem/Redeem';
import Invoice from '../pages/User/Invoice';
import InvoiceDetail from '../pages/User/Invoice/InvoiceDetail';
import Settings from '../pages/User/Settings';
import Mentor from '../pages/Mentor';
import Courses from '../pages/Mentor/Courses';
import EditCourseOverview from '../pages/Mentor/Courses/EditCourseOverview';
import EditCourseCurriculum from '../pages/Mentor/Courses/EditCourseCurriculum';
import EditCoursePricing from '../pages/Mentor/Courses/EditCoursePricing';
import Roadmaps from '../pages/Mentor/Roadmaps';
import EditRoadmapOverview from '../pages/Mentor/Roadmaps/EditRoadmapOverview';
import EditRoadmapCurriculum from '../pages/Mentor/Roadmaps/EditRoadmapCurriculum';
import EditRoadmapPricing from '../pages/Mentor/Roadmaps/EditRoadmapPricing';
import Earnings from '../pages/Mentor/Earnings';
import MentorSettings from '../pages/Mentor/Settings';
import OnProgress from '../pages/OnProgress/OnProgress';
import NotificationsPage from '../pages/Notifications/Notifications';
import JoinMentor from '../pages/Mentor/JoinMentor/JoinMentor';
import ExerciseOverview from '../pages/Exercises/Overview';
import Exercises from '../pages/Exercises';
import ExerciseDetail from '../pages/Exercises/ExerciseDetail';
import Exercise from '../pages/Exercises/Exercise';
import ExercisesByTag from '../pages/Exercises/ExercisesByTag';
import PointHistory from '../pages/User/PointHistory/PointHistory';
import TestBunny from '../pages/TestBunny';
import SubmissionKelasfullstack from '../pages/SubmissionKelasfullstack/SubmissionKelasfullstack';
import Avatar from '../pages/User/Settings/Avatar';
import InteractiveCoding from '../pages/Learn/InteractiveCoding/InteractiveCoding';
import ChangePasswordSetting from '../pages/User/Settings/ChangePassword';
import Interface from '../pages/User/Settings/Interface';
import CV from '../pages/User/CV';
import JoinDiscord from '../pages/JoinDiscord';
import BrowseCourses from '../pages/BrowseCourses/BrowseCourses';
import MyNotes from '../pages/User/MyNotes/MyNotes';
// import AffiliatorDashboard from '../pages/Affiliator';

const unprotectedRoutes = [
    {
        path: '/register',
        element: <Register />
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />
    },
    {
        path: '/user/change_password/:token',
        element: <ChangePassword />
    },
    {
        path: '/test-bunny',
        element: <TestBunny />
    },
];

const protectedRoutes = [
    // Learn
    {
        path: '/learn',
        element: <Learn />
    },
    {
        path: '/learn/courses',
        element: <MyCourses />
    },
    {
        path: '/learn/courses/detail/:slug',
        element: <CourseDetail />
    },
    {
        path: '/learn/courses/:slug/lessons/:lessonId',
        element: <CourseLesson />
    },
    {
        path: '/learn/courses/:slug/claim-certificate',
        element: <CourseFeedbackAndCertificate />
    },
    {
        path: '/learn/courses/:slug/exam',
        element: <CourseExam />
    },
    {
        path: '/learn/roadmaps/:slug',
        element: <RoadmapsOverview />
    },
    {
        path: '/learn/interactive-coding',
        element: <InteractiveCoding />
    },
    {
        path: '/learn/discussions',
        element: <Discussions />
    },
    {
        path: '/learn/leaderboard',
        element: <Leaderboard />
    },
    {
        path: '/learn/events',
        element: <Events />
    },
    {
        path: '/learn/tutorial',
        element: <Tutorial />
    },

    // User Settings
    // {
    //     path: '/user/:username',
    //     element: <ProfileOverview />
    // },
    {
        path: '/user/redeem',
        element: <Redeem />
    },
    {
        path: '/user/point-history',
        element: <PointHistory />
    },
    {
        path: '/user/invoice',
        element: <Invoice />
    },
    {
        path: '/user/invoice/:orderId',
        element: <InvoiceDetail />
    },
    {
        path: '/user/notes',
        element: <MyNotes />
    },
    {
        path: '/user/cv',
        element: <CV />
    },
    {
        path: '/user/settings',
        element: <Settings />
    },
    {
        path: '/user/settings/change-password',
        element: <ChangePasswordSetting />
    },
    {
        path: '/user/settings/interface',
        element: <Interface />
    },
    {
        path: '/user/settings/avatar',
        element: <Avatar />
    },

    // Mentor
    {
        path: '/join-mentor',
        element: <JoinMentor />
    },
    {
        path: '/mentor',
        element: <Mentor />
    },
    {
        path: '/mentor/courses',
        element: <Courses />
    },
    {
        path: '/mentor/courses/edit/overview/:id',
        element: <EditCourseOverview />
    },
    {
        path: '/mentor/courses/edit/curriculum/:id',
        element: <EditCourseCurriculum />
    },
    {
        path: '/mentor/courses/edit/pricing/:id',
        element: <EditCoursePricing />
    },
    {
        path: '/mentor/roadmaps',
        element: <Roadmaps />
    },
    {
        path: '/mentor/roadmaps/edit/overview/:id',
        element: <EditRoadmapOverview />
    },
    {
        path: '/mentor/roadmaps/edit/curriculum/:id',
        element: <EditRoadmapCurriculum />
    },
    {
        path: '/mentor/roadmaps/edit/pricing/:id',
        element: <EditRoadmapPricing />
    },
    {
        path: '/mentor/earnings',
        element: <Earnings />
    },
    {
        path: '/mentor/settings',
        element: <MentorSettings />
    },

    // Affiliator
    // {
    //     path: '/affiliator',
    //     element: <AffiliatorDashboard />
    // },

    // Exercise
    {
        path: '/exercises/overview',
        element: <ExerciseOverview />
    },
    {
        path: '/exercises',
        element: <Exercises />
    },
    {
        path: '/exercises/detail/:slug',
        element: <ExerciseDetail />
    },
    {
        path: '/exercise/:slug',
        element: <Exercise />
    },
    {
        path: '/exercises/tag/:slug',
        element: <ExercisesByTag />
    },

    // Jobs
    {
        path: '/jobs',
        element: <OnProgress />
    },

    // Other
    {
        path: '/browse-course',
        element: <BrowseCourses />
    },
    {
        path: '/notifications',
        element: <NotificationsPage />
    },
    {
        path: '/tugas-kelas-fullstack',
        element: <SubmissionKelasfullstack />
    },
    {
        path: '/join-discord',
        element: <JoinDiscord />
    },
];

export { unprotectedRoutes, protectedRoutes };