import { Helmet } from 'react-helmet-async';
import Layout from "../../components/global/Layout/Layout";
import Sidebar from "../../components/global/Sidebar/Sidebar";
import { learnmenus } from "../../components/global/Sidebar/sidebarmenus";
import SidebarMobile from "../../components/global/Sidebar/SidebarMobile";

const OnProgress = () => {
    return (
        <>
            <Helmet>
                <title>Under Construction</title>
            </Helmet>
            <Layout>
                <section className="section" id="learnHome" style={{ paddingTop: '150px' }}>
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            {/* Sidebar */}
                            <div className="col-lg-2">
                                <Sidebar menu={learnmenus} />
                                <SidebarMobile menu={learnmenus} />
                            </div>
                            {/* End of Sidebar */}

                            {/* Main Content */}
                            <div className="col-lg-10 text-muted px-4 px-lg-5">
                                <div className="row bg-white">
                                    <div className="col text-center">
                                        <img className="img-fluid" src="/assets/img/icon-on-progress.png" alt="Oops! Halaman Tidak ditemukan" />
                                        <p className="lead text-muted mb-5">
                                            Maaf, halaman ini masih dalam pengembangan. Segara kami kabari jika sudah publish ya.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* End of Main Content */}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default OnProgress;
