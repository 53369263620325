import { Helmet } from 'react-helmet-async';
import Layout from "../../components/global/Layout/Layout";
import SectionCourses from '../../components/browseCourse/SectionCourses/SectionCourses';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCourseLabels, getCourses, resetCourses } from '../../features/browseCourse/browseCourseSlice';
import SectionSearch from '../../components/browseCourse/SectionSearch/SectionSearch';
import SectionFilters from '../../components/browseCourse/SectionFilters/SectionFilters';

const BrowseCourses = () => {
    const [courseLabel, setCourseLabel] = useState('');
    const [filter, setFilter] = useState('');
    const [sort, setSort] = useState('created_at[desc]');
    const [page, setPage] = useState(1);
    const limit = 12;

    const dispatch = useDispatch();
    const loadMoreCourses = () => setPage(prevPage => prevPage + 1);

    useEffect(() => {
        dispatch(getCourseLabels());
    }, [dispatch]);

    // Fetch courses whenever filters or sort change, and reset page to 1
    useEffect(() => {
        dispatch(resetCourses());
        setPage(1);
    }, [dispatch, courseLabel, filter, sort]);

    // Fetch courses when page changes
    useEffect(() => {
        dispatch(getCourses({ courseLabel, filter, sort, page, limit }));
    }, [dispatch, courseLabel, filter, sort, page, limit]);

    return (
        <>
            <Helmet>
                <title>Browse Course</title>
            </Helmet>
            <Layout>
                <section className="section py-5" id="learnHome">
                    <div className="container-fluid">
                        <SectionSearch />
                        <SectionFilters
                            courseLabel={courseLabel}
                            setCourseLabel={setCourseLabel}
                            filter={filter}
                            setFilter={setFilter}
                            sort={sort}
                            setSort={setSort}
                        />
                        <SectionCourses loadMoreCourses={loadMoreCourses} />
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default BrowseCourses;
