import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBook } from '@fortawesome/free-solid-svg-icons';
import styles from './CardMyRoadmap.module.scss';

const CardMyRoadmap = ({ thumbnail, title, totalCourse, totalCompleted }) => {
    const precentage = Math.round((totalCompleted / totalCourse) * 100);

    return (
        <div className={`card card-rounded position-relative ${styles.card_myRoadmap} h-100 shadow-sm`}>
            <div className="position-absolute" style={{ zIndex: 2, right: '10px', top: '5px' }}>
                <span className="badge bg-light text-dark">Roadmap</span>
            </div>
            <img style={{ height: '20em', objectFit: 'cover' }} src={thumbnail || "/assets/img/placeholder.jpg"} className="card-rounded position-relative" alt={title} />
            <div className={`card-body px-3 text-white position-absolute bottom-0 d-flex flex-column justify-content-end w-100 ${styles.bg_gradient}`}>
                <h5 className="fw-bold"><small>{title || 'Untitled'}</small></h5>
                <p className="mb-0 small">
                    <FontAwesomeIcon icon={faBook} /> {totalCourse} courses
                </p>
                <div className="d-flex justify-content-between mt-3">
                    <div className="progress my-auto" style={{ height: '7px', width: '85%' }}>
                        <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${precentage}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small className="my-auto">{precentage}%</small>
                </div>
                <hr className="my-2" />
                <div className="d-flex justify-content-end">
                    <small>
                        {precentage === 0 && 'Mulai Belajar'}
                        {precentage !== 0 && precentage < 100 && 'Lanjut Belajar'}
                        {precentage >= 100 && 'Lihat Roadmap'}
                        <FontAwesomeIcon fixedWidth icon={faArrowRight} />
                    </small>
                </div>
            </div>
        </div>
    );
};

export default CardMyRoadmap;
