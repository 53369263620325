import { faChartSimple, faClock, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom';

const SectionRoadmapLastLearn = ({ data, numberLastLearn }) => {
    return (
        <section>
            <h4 className="mt-5 mb-3">Your latest lesson</h4>
            <div className="card border-0 shadow-sm p-3">
                <div className="row">
                    <div className="col-lg-2 d-flex justify-content-center">
                        <img src={data?.thumbnail || '/assets/img/placeholder.jpg'} className="w-100" alt="" />
                    </div>
                    <div className="col-lg-7 d-flex flex-column pt-2 pt-lg-0">
                        <span className="fw-bold text-primary">Langkah {numberLastLearn}</span>
                        <h5 className="fw-bold my-3">{data?.course_title}</h5>
                        <div>
                            <small>
                                <FontAwesomeIcon icon={faClock} className="me-1" />
                                <span>{data?.total_time} Menit</span>
                            </small>
                            <small className="ms-4">
                                <FontAwesomeIcon icon={faChartSimple} className="me-1" />
                                <span>Level {data?.level}</span>
                            </small>
                        </div>
                    </div>
                    <div className="col-lg-3 d-flex align-items-center justify-content-end justify-content-lg-center mt-2">
                        <Link to={`/learn/courses/detail/${data?.slug}`}>
                            <button className="btn btn-sm btn-primary"><FontAwesomeIcon icon={faRightFromBracket} /> Lanjut Belajar</button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionRoadmapLastLearn