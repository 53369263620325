import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

// Get Mentor Roadmap
export const getMentorRoadmap = createAsyncThunk('mentor/getMentorRoadmap', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/course/roadmap`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Detail Mentor Roadmap
export const getDetailMentorRoadmap = createAsyncThunk('mentor/getDetailMentorRoadmap', async (id) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/course/detailRoadmap/${id}`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Post Mentor Roadmap
export const postMentorRoadmap = createAsyncThunk('mentor/postMentorRoadmap', async (data) => {
    const payload = JSON.stringify(data)
    try {
        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/course/insertRoadmap`, payload, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Update Mentor Roadmap
export const updateMentorRoadmap = createAsyncThunk('mentor/updateMentorRoadmap', async (data) => {
    const payload = JSON.stringify(data)
    try {
        if (userToken) {
            const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/course/updateRoadmap/${data.id}`, payload, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Delete Mentor Roadmap
export const deleteMentorRoadmap = createAsyncThunk('mentor/deleteMentorRoadmap', async (id, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/course/deleteRoadmap/${id}`, {
                headers: {
                    Authorization: userToken
                }
            });
            dispatch(getMentorRoadmap());
            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

// GetCoursesByRoadmap
export const getCoursesByRoadmap = createAsyncThunk('mentor/getCoursesByRoadmap', async (id) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/course/coursesByRoadmap/${id}`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Connect Course to Roadmap
export const connectCoursesToRoadmap = createAsyncThunk('mentor/connectCoursesToRoadmap', async (data, { dispatch }) => {

    let payload = {
        "path_id": data.id,
        "courses": [...data.finalCourses]
    };

    try {
        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/course/connect`, payload, {
                headers: {
                    Authorization: userToken
                }
            });
            dispatch(getCoursesByRoadmap(data.id));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Disconnect Course From Roadmap
export const disconnectCourseFromRoadmap = createAsyncThunk('mentor/disconnectCourseFromRoadmap', async (data, { dispatch }) => {

    let payload = {
        "path_id": data.pathId,
        "course_id": data.courseId,
        "category_id": data.categoryId
    };

    try {
        if (userToken) {
            const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/course/disconnect`, payload, {
                headers: {
                    Authorization: userToken
                }
            });
            dispatch(getCoursesByRoadmap(data.pathId));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Move Up Course
export const moveUpCourse = createAsyncThunk('mentor/moveUpCourse', async (data, { dispatch }) => {

    try {
        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/course/moveup_course?path_id=${data.pathId}&object_id=${data.courseId}&position=${data.position}`, null, {
                headers: {
                    Authorization: userToken
                }
            });
            dispatch(getCoursesByRoadmap(data.pathId));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Move Down Course
export const moveDownCourse = createAsyncThunk('mentor/moveDownCourse', async (data, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/course/movedown_course?path_id=${data.pathId}&object_id=${data.courseId}&position=${data.position}`, null, {
                headers: {
                    Authorization: userToken
                }
            });
            dispatch(getCoursesByRoadmap(data.pathId));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});


const mentorRoadmapSlice = createSlice({
    name: 'mentorRoadmap',
    initialState: {
        mentorRoadmaps: [],
        coursesByRoadmap: [],
        loadingMentorRoadmaps: false
    },
    extraReducers: {

        // Get Mentor Roadmap
        [getMentorRoadmap.pending]: (state, action) => {
            state.loadingMentorRoadmaps = true;
        },
        [getMentorRoadmap.fulfilled]: (state, action) => {
            state.loadingMentorRoadmaps = false;
            state.mentorRoadmaps = action.payload
        },
        [getMentorRoadmap.rejected]: (state, action) => {
            state.loadingMentorRoadmaps = false;
        },

        // Get Detail Mentor Roadmap
        [getDetailMentorRoadmap.pending]: (state, action) => {
            state.loadingMentorRoadmaps = true;
        },
        [getDetailMentorRoadmap.fulfilled]: (state, action) => {
            state.loadingMentorRoadmaps = false;
        },
        [getDetailMentorRoadmap.rejected]: (state, action) => {
            state.loadingMentorRoadmaps = false;
        },

        // Post Mentor Roadmap
        [postMentorRoadmap.pending]: (state, action) => {
            state.loadingMentorRoadmaps = true;
        },
        [postMentorRoadmap.fulfilled]: (state, action) => {
            state.loadingMentorRoadmaps = false;
        },
        [postMentorRoadmap.rejected]: (state, action) => {
            state.loadingMentorRoadmaps = false;
        },

        // Post Mentor Roadmap
        [updateMentorRoadmap.pending]: (state, action) => {
            state.loadingMentorRoadmaps = true;
        },
        [updateMentorRoadmap.fulfilled]: (state, action) => {
            state.loadingMentorRoadmaps = false;
        },
        [updateMentorRoadmap.rejected]: (state, action) => {
            state.loadingMentorRoadmaps = false;
        },

        // Get Courses by roadmap
        [getCoursesByRoadmap.pending]: (state, action) => {
            state.loadingMentorRoadmaps = true;
        },
        [getCoursesByRoadmap.fulfilled]: (state, action) => {
            state.loadingMentorRoadmaps = false;
            state.coursesByRoadmap = action.payload;
        },
        [getCoursesByRoadmap.rejected]: (state, action) => {
            state.loadingMentorRoadmaps = false;
        },
    }
});

export default mentorRoadmapSlice.reducer;
