import { useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";

import CardExercise from "../../Cards/CardExercise/CardExercise";
import SkeletonCardCourse from "../../../skeletons/SkeletonCardCourse";

// install Swiper modules
SwiperCore.use([Navigation]);

const SectionSlideExercises = ({ title, data, loading }) => {
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    if (data?.length < 1) return null;

    return (
        <section className="my-5">
            <h4 className="section-title mb-4">{title || 'Untitled'}</h4>

            <div className="row">
                <div className="col">
                    {/* Custom Navigation */}
                    {data?.length > 4 ? (
                        <div className="position-relative d-none d-xl-block" style={{ zIndex: 2, top: '200px' }}>
                            <div className="position-absolute top-50 start-0 translate-middle">
                                <button type="button" className="btn text-primary shadow-none" ref={navigationPrevRef}>
                                    <FontAwesomeIcon size="3x" icon={faChevronCircleLeft} />
                                </button>
                            </div>
                            <div className="position-absolute top-50 start-100 translate-middle">
                                <button type="button" className="btn text-primary shadow-none" ref={navigationNextRef}>
                                    <FontAwesomeIcon size="3x" icon={faChevronCircleRight} />
                                </button>
                            </div>
                        </div>
                    ) : null}

                    <Swiper
                        className="py-3"
                        spaceBetween={20}
                        grabCursor={true}
                        slidesPerView={3.1}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        onBeforeInit={(swiper) => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current;
                            swiper.params.navigation.nextEl = navigationNextRef.current;
                        }}
                        breakpoints={{
                            // when window width is >= 414px
                            300: {
                                slidesPerView: 1.3,
                            },
                            // when window width is >= 768px
                            768: {
                                slidesPerView: 2.5,
                            },
                            1200: {
                                slidesPerView: 4,
                            },
                        }}
                    >
                        {loading && [1, 2, 3, 4,].map((item) => {
                            return (
                                <SwiperSlide key={item}>
                                    <SkeletonCardCourse />
                                </SwiperSlide>
                            );
                        })}
                        {!loading && data?.map((quiz) => {
                            return (
                                <SwiperSlide key={quiz.id}>
                                    <Link className="link" to={`/exercises/detail/${quiz.slug}`}>
                                        <CardExercise
                                            thumbnail={quiz.cover}
                                            title={quiz.title}
                                            level={quiz.level}
                                            totalStudents={quiz.total_students}
                                            duration={quiz.duration}
                                            totalQuestions={quiz.total_questions}
                                            price={quiz.price}
                                        />
                                    </Link>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default SectionSlideExercises;