import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

export const getPortfolios = createAsyncThunk('user/getPortfolios', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/entry/index/user_portfolio`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const getPortfolio = createAsyncThunk('user/getPortfolio', async (id, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/entry/detail/user_portfolio/${id}`, {
                headers: {
                    Authorization: userToken
                }
            });
            dispatch(getPortfolios());
            return response.data.result;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const postPortfolio = createAsyncThunk('user/postPortfolio', async ({ payload, screenshot }, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('project_name', payload.project_name);
        data.append('role_name', payload.role_name);
        data.append('year', payload.year);
        data.append('description', payload.description);
        data.append('thumbnail', screenshot);
        data.append('attachment', payload.attachment);

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/entry/insert/user_portfolio`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getPortfolios());
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const updatePortfolio = createAsyncThunk('user/updatePortfolio', async ({ payload, screenshot }, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('project_name', payload.project_name);
        data.append('role_name', payload.role_name);
        data.append('year', payload.year);
        data.append('description', payload.description);
        data.append('thumbnail', screenshot);
        data.append('attachment', payload.attachment);

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/entry/update/user_portfolio/${payload.id}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getPortfolios());
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const deletePortfolio = createAsyncThunk('user/deletePortfolio', async (id, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/entry/delete/user_portfolio/${id}`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: userToken
                }
            });
            dispatch(getPortfolios());
            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
});

const initialState = {
    portfolios: [],
    portfolio: {},
    loadingPortolio: false
};

const portfolioSlice = createSlice({
    name: 'portfolio',
    initialState,
    extraReducers: {
        // Get Portfolios Array
        [getPortfolios.pending]: (state, action) => {
            state.loadingPortfolio = true;
        },
        [getPortfolios.fulfilled]: (state, action) => {
            state.loadingPortfolio = false;
            if (action.payload.status !== 'failed') {
                state.portfolios = action.payload.results
            } else {
                state.portfolios = [];
            }
        },
        [getPortfolios.rejected]: (state, action) => {
            state.loadingPortfolio = false;
        },

        // Get Single Portfolio
        [getPortfolio.pending]: (state, action) => {
            state.loadingPortfolio = true;
        },
        [getPortfolio.fulfilled]: (state, action) => {
            state.loadingPortfolio = false;
            state.portfolio = action.payload;
        },
        [getPortfolio.rejected]: (state, action) => {
            state.loadingPortfolio = false;
        },

        // Post Portfolio
        [postPortfolio.pending]: (state, action) => {
            state.loadingPortfolio = true;
        },
        [postPortfolio.fulfilled]: (state, action) => {
            state.loadingPortfolio = false;
        },
        [postPortfolio.rejected]: (state, action) => {
            state.loadingPortfolio = false;
        },

        // Update Portfolio
        [updatePortfolio.pending]: (state, action) => {
            state.loadingPortfolio = true;
        },
        [updatePortfolio.fulfilled]: (state, action) => {
            state.loadingPortfolio = false;
        },
        [updatePortfolio.rejected]: (state, action) => {
            state.loadingPortfolio = false;
        },

        // Update Portfolio
        [deletePortfolio.pending]: (state, action) => {
            state.loadingPortfolio = true;
        },
        [deletePortfolio.fulfilled]: (state, action) => {
            state.loadingPortfolio = false;
        },
        [deletePortfolio.rejected]: (state, action) => {
            state.loadingPortfolio = false;
        },
    }
});

export default portfolioSlice.reducer;