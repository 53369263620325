import { faStar, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/global/Breadcrumb/Breadcrumb";
import Modal from "../../../components/global/Modal/Modal";
import SidebarCollapse from "../../../components/global/SidebarCollapse/SidebarCollapse";
import { getCourse } from "../../../features/courses/courseSlice";
import Footer from "../../../components/global/Footer/Footer";
import { Rating } from "react-simple-star-rating";
import axios from "axios";

// Sweetalert
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { getMarkedLesson } from "../../../features/lessons/lessonSlice";
import { checkCompletedCourse } from "../../../features/courses/courseCheckSlice";
import { userToken } from "../../../utils/config";

const CourseFeedbackAndCertificate = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const navigate = useNavigate()

    const [rating, setRating] = useState(0);
    const [comments, setComments] = useState('');

    const { theme } = useSelector((state) => state.theme);
    const { course } = useSelector((state) => state.courses);
    const { completedCourse } = useSelector((state) => state.completedCourse);
    const { markedLessons } = useSelector((state) => state.lesson);

    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();

    const { slug } = useParams();

    // Set sidebar default to closed in mobile device
    useEffect(() => {
        const width = window.innerWidth;
        if (width < 800) setSidebarOpen(false);
        else setSidebarOpen(true);
    }, []);

    useEffect(() => {
        dispatch(getCourse(slug));
        dispatch(getMarkedLesson(course?.course?.id));
        dispatch(checkCompletedCourse(course?.course?.id));
    }, [dispatch, slug, course?.course?.id]);

    const handleSubmitReview = async (e) => {
        e.preventDefault();

        try {
            let data = {
                "rate": rating,
                "comment": comments,
                "object_id": course?.course?.id,
                "object_type": "course"
            };

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/feedback/insert`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            })

            if (response.data.status === "success") {
                // Alert Success
                MySwal.fire({
                    title: <strong>Mantap!</strong>,
                    text: 'Terimakasih telah mengisi feedback pada course ini',
                    icon: 'success',
                    showDenyButton: true,
                    confirmButtonText: 'Kembali Ke Home',
                    denyButtonText: 'Pelajari Course Lain',
                    denyButtonColor: '#14a7a0',
                }).then((result) => {
                    setRating(0);
                    setComments('');
                    if (result.isConfirmed) {
                        navigate('/')
                        window.location.reload()
                    } else if (result.isDenied) {
                        window.open('https://codepolitan.com/library', '_blank');
                    }
                });
            } else {
                MySwal.fire({
                    title: <strong>Oops!</strong>,
                    text: response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                });
            }
        } catch (error) {
            throw new Error(error.message);
        };
    };

    const handleClaimCertificate = async () => {
        try {
            let data = {
                "object_id": course?.course?.id,
                "object_type": "course"
            };

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/certificate/claim`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            })

            if (response.data.status === "success") {
                window.open(`https://codepolitan.com/c/${response.data.code}`, '_blank');
            } else {
                MySwal.fire({
                    title: <strong>Oops!</strong>,
                    text: response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                });
            }
        } catch (error) {
            throw new Error(error.message);
        };
    };

    if (!completedCourse && completedCourse?.status !== "success") return <Navigate to="/" replace />

    return (
        <>
            <Helmet>
                <title>Claim Certificate</title>
            </Helmet>
            <main id="main">
                <SidebarCollapse
                    isOpen={sidebarOpen}
                    onClickToggle={() => setSidebarOpen(!sidebarOpen)}
                    courseSlug={slug}
                    examData={course?.exam}
                    courseId={course?.course?.id}
                    markedLessons={markedLessons}
                    lessons={course?.lessons}
                    courseIsComplete={completedCourse}
                />

                <div className={`content ${sidebarOpen && 'open'} ${theme === 'light' && 'bg-light'} ${theme === 'dark' && 'bg-dark text-white'} overflow-auto`}>
                    <div className="container p-4">
                        <div className="row justify-content-between">
                            <div className="col-auto">
                                <Breadcrumb>
                                    <li className="breadcrumb-item">
                                        <Link className="link" to="/learn/courses">Kelas Saya</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link className="link" to={`/learn/courses/detail/${course.course?.slug}`}>{course?.course?.title.slice(0, 15) + '...'}</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {document.title}
                                    </li>
                                </Breadcrumb>
                            </div>
                        </div>

                        <section className="row my-5">
                            <div className="col">
                                <div className={`card ${theme === 'dark' && 'bg-soft-dark'} border-0 card-rounded`}>
                                    <div className="card-body text-center py-5">
                                        <img className="img-fluid w-25" src="https://image.web.id/images/2022/06/15/ea2ea84daf797c37bd19ae032482121e.png" alt="Congratulations" />
                                        <h5 className="my-3">Yeay, Congratulations!</h5>
                                        <p className={`${theme === 'dark' ? 'text-white' : 'text-muted'} w-75 mx-auto`}>Kamu telah menyelesaikan kelas ini, sekarang kamu bisa langsung claim sertifikat dibawah ini setelah mengisi feedback.</p>
                                        <div className="d-flex justify-content-center gap-2 mt-4">
                                            <button type="button" className="btn btn-pink btn-rounded" data-bs-toggle="modal" data-bs-target="#feedbackModal"><FontAwesomeIcon icon={faStar} /> Review Kelas</button>
                                            <button onClick={handleClaimCertificate} type="button" className="btn btn-primary btn-rounded"><FontAwesomeIcon icon={faUpRightFromSquare} /> Open Certificate</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer theme={theme} />
                    </div>
                </div>
                {/* Modal Feedback */}
                <Modal
                    id="feedbackModal"
                >
                    <div className="card border-0">
                        <div className="card-header text-center">
                            <h5 className="my-3">Berikan Ulasan Kamu di Sini</h5>
                        </div>
                        <div className="card-body p-md-5">
                            <form onSubmit={handleSubmitReview}>
                                <div className="text-center mb-3">
                                    <Rating onClick={(rate) => setRating(rate)} ratingValue={rating} />
                                </div>
                                <div className="mb-3">
                                    <textarea onChange={(e) => setComments(e.target.value)} value={comments} className="form-control bg-light card-rounded" rows={4} minLength={100} placeholder="Contoh: Materi yang disampaikan oleh mentornya mudah di mengerti dan cocok untuk saya belajar sebagai pemula!" required />
                                    <div className="text-end mt-2">
                                        <small className="text-muted">{comments?.length < 100 ? 'Berikan komentar minimal 100 karakter' : 'Mantap!'}</small>
                                    </div>
                                </div>
                                <div className="mb-3 d-grid gap-2">
                                    <button type="submit" className="btn btn-primary btn-lg btn-rounded" disabled={comments?.length < 100 || rating === 0}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            </main>
        </>
    );
};

export default CourseFeedbackAndCertificate;