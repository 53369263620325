import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import mixpanel from "mixpanel-browser";
import { getCourse } from '../../../features/courses/courseSlice';
import { useParams } from 'react-router-dom';
import { getFeedbacksByCourse } from '../../../features/feedback/feedbackSlice';
import Layout from "../../../components/global/Layout/Layout";
import SectionBanner from '../../../components/learn/courseDetail/SectionBanner/SectionBanner';
import SectionSidebar from '../../../components/learn/courseDetail/SectionSidebar/SectionSidebar';
import SectionInfo from '../../../components/learn/courseDetail/SectionInfo/SectionInfo';
import SectionLessons from '../../../components/learn/courseDetail/SectionLessons/SectionLessons';
import SectionMentors from '../../../components/learn/courseDetail/SectionMentors/SectionMentors';
import SectionTestimonials from '../../../components/learn/courseDetail/SectionTestimonials/SectionTestimonials';
import { getCurrentCourseState } from '../../../features/courses/courseCheckSlice';

const CourseDetail = () => {
    const { slug } = useParams();
    const dispatch = useDispatch();

    const { course } = useSelector((state) => state.courses);

    const MIXPANEL_ID = process.env.REACT_APP_MIXPANEL_ID;
    mixpanel.init(MIXPANEL_ID);

    // Tracking Mixpanel
    useEffect(() => {
        if (course?.course?.id !== undefined) {
            mixpanel.track(`Buka Kelas : ${course?.course?.title}`, { 'source': 'Dashboard V2' })
        };
    }, [course]);

    // Fetch Data
    useEffect(() => {
        dispatch(getCourse(slug));
        dispatch(getFeedbacksByCourse(slug));
        if (course?.course?.id) {
            dispatch(getCurrentCourseState(course?.course?.id));
        };
    }, [dispatch, slug, course?.course?.id]);

    return (
        <>
            <Helmet>
                <title>{course?.course?.title}</title>
            </Helmet>
            <Layout className="overflow-hidden">
                <SectionBanner />
                <div className="container-fluid p-2 p-lg-5">
                    <div className="row justify-content-between">
                        {/* main content */}
                        <div className="col-lg-7">
                            <SectionInfo />
                            <SectionLessons />
                            <SectionMentors />
                            <SectionTestimonials />
                        </div>

                        {/* Sidebar */}
                        <div className="col-lg-4 order-first order-lg-last mb-3">
                            <SectionSidebar />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default CourseDetail;
