
import { Helmet } from 'react-helmet-async';
import Layout from "../../../components/global/Layout/Layout";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTutorial, getPopularTutorials } from '../../../features/tutorials/tutorialSlice';
import Banner from '../../../components/learn/tutorial/Banner/Banner';
import SectionPopular from '../../../components/learn/tutorial/SectionPopular/SectionPopular';
import SectionLatest from '../../../components/learn/tutorial/SectionLatest/SectionLatest';
import Sidebar from '../../../components/learn/tutorial/Sidebar/Sidebar';

const Tutorial = () => {
    const dispatch = useDispatch();
    const { popularTutorials, tutorials, loadingTutorial } = useSelector(state => state.tutorials);
    const [query, setQuery] = useState('');

    useEffect(() => {
        dispatch(getPopularTutorials());
        dispatch(getAllTutorial());
    }, [dispatch]);

    const search = (data) => data?.filter((item) => item.title
        .toLowerCase().includes(query.toLowerCase()));

    return (
        <>
            <Helmet>
                <title>Tutorial</title>
            </Helmet>
            <Layout>
                <section id="learnTutorial" className="section mt-5">
                    <div className="container-fluid">
                        <Banner
                            query={query}
                            setQuery={setQuery}
                        />
                        <SectionPopular
                            loading={loadingTutorial}
                            tutorials={search(popularTutorials)}
                        />
                        <div className="row my-5">
                            <div className="col-lg-8">
                                <SectionLatest
                                    loading={loadingTutorial}
                                    tutorials={search(tutorials)}
                                />
                            </div>
                            <div className="col-lg-4 order-first order-lg-last mb-4 mb-lg-auto sticky-lg-top" style={{ top: '100px', zIndex: 2 }}>
                                <Sidebar
                                    query={query}
                                    setQuery={setQuery}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default Tutorial;
