import { Helmet } from 'react-helmet-async';
import Layout from "../../../components/global/Layout/Layout";

const JoinMentor = () => {
    return (
        <>
            <Helmet>
                <title>Jadilah Mentor Codepolitan</title>
            </Helmet>
            <Layout>
                <section className="section" id="learnHome">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col text-center m-5">
                                <img className="img-fluid h-50" src="https://image.web.id/images/hero.png" alt="Join Mentor" />
                                <h3 className="fw-bold text-primary my-4">Jadilah Mentor Codepolitan</h3>
                                <p className="text-muted">Melalui program ini Codepolitan ingin menciptakan sebuah peluang dimana para developer bisa mendapatkan penghasilan dengan cara berbagi pengetahuan yang dimilikinya dalam bidang teknologi.</p>
                                <a className="btn btn-primary my-4" href="https://codepolitan.com/for-mentor" target="_blank" rel="noopener noreferrer">Pelajari Selengkapnya</a>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default JoinMentor;
