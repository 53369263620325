const Input = ({ name, label, placeholder, subtitle, type, isRequired, readOnly, register, errors }) => {
    return (
        <>
            <label htmlFor={name} className="form-label fw-bolder">{label}{isRequired && <span className="text-danger">*</span>} </label>
            <small>{subtitle}</small>
            <input
                type={type}
                name={name}
                className="form-control"
                placeholder={placeholder ?? label}
                readOnly={readOnly}
                {...register(name, { required: isRequired })}
            />
            {errors?.name?.type === 'required' && <span className="text-danger">This field is required</span>}
        </>
    );
};

export default Input;