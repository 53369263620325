import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import ModalImage from "react-modal-image";

export const CodeBlock = {
    p: ({ node, children }) => {
        if (node?.children[0]?.tagName === "img") {
            const image = node.children[0];
            return (
                <ModalImage
                    className="img-fluid d-block mx-auto my-4"
                    hideDownload
                    hideZoom
                    small={image.properties.src}
                    large={image.properties.src}
                    alt={image.properties.alt || "Image"}
                />
            );
        }
        return <p>{children}</p>;
    },
    code({ node, inline, className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || '');
        return !inline && match ? (
            <SyntaxHighlighter
                style={vscDarkPlus}
                language={match[1]}
                PreTag="div"
                {...props}
            >
                {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
        ) : (
            <code className={className} {...props}>
                {children}
            </code>
        );
    },
};