
import Skeleton from 'react-loading-skeleton';

const SkeletonCardMentorCourse = () => {
    return (
        <div className="card mb-3 overflow-hidden">
            <div className="card-body">
                <div className="row">
                    <div className="col col-lg-auto">
                        <Skeleton height={120} width={200} />
                    </div>
                    <div className="col col-lg-4 my-auto">
                        <Skeleton count={2} width={300} />
                        <Skeleton className="mt-3" width={100} />
                    </div>
                    <div className="col col-lg-4 my-auto">
                        <div className="d-flex justify-content-around">
                            <Skeleton height={50} width={80} />
                            <Skeleton height={50} width={80} />
                            <Skeleton height={50} width={80} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonCardMentorCourse;