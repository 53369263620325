import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

// Get roadmaps
export const getFeaturedRoadmaps = createAsyncThunk('roadmaps/getFeaturedRoadmaps', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/roadmap/featured?limit=200&page=1`);
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get roadmaps
export const getMyRoadmaps = createAsyncThunk('roadmaps/getMyRoadmaps', async () => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/path/user`, {
                headers: {
                    Authorization: userToken
                }
            });

            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get roadmap by slug
export const getRoadmap = createAsyncThunk('roadmaps/getRoadmap', async (slug) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/path/${slug}`, {
                headers: {
                    Authorization: userToken
                }
            });

            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const roadmapSlice = createSlice({
    name: 'roadmap',
    initialState: {
        featuredRoadmaps: [],
        myRoadmaps: [],
        roadmap: [],
        loadingRoadmap: false
    },
    extraReducers: {
        // Get featured roadmaps
        [getFeaturedRoadmaps.pending]: (state, action) => {
            state.loadingRoadmap = true;
        },
        [getFeaturedRoadmaps.fulfilled]: (state, action) => {
            state.loadingRoadmap = false;
            state.featuredRoadmaps = action.payload;
        },
        [getFeaturedRoadmaps.rejected]: (state, action) => {
            state.loadingRoadmap = false;
        },

        // Get roadmap detail
        [getMyRoadmaps.pending]: (state, action) => {
            state.loadingRoadmap = true;
        },
        [getMyRoadmaps.fulfilled]: (state, action) => {
            state.loadingRoadmap = false;
            action.payload.error !== 'No Content' && (state.myRoadmaps = action.payload)
        },
        [getMyRoadmaps.rejected]: (state, action) => {
            state.loadingRoadmap = false;
        },

        // Get roadmap detail
        [getRoadmap.pending]: (state, action) => {
            state.loadingRoadmap = true;
        },
        [getRoadmap.fulfilled]: (state, action) => {
            state.loadingRoadmap = false;
            state.roadmap = action.payload;
        },
        [getRoadmap.rejected]: (state, action) => {
            state.loadingRoadmap = false;
        }
    }
});

export default roadmapSlice.reducer;
