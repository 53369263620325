const CardExerciseTopic = ({ name, thumbnail }) => {
    return (
        <div className="card card-rounded border-none shadow">
            <div className="card-body">
                <div className="row">
                    <div className="col-4">
                        <img className="img-fluid" src={thumbnail || "/assets/img/placeholder.jpg"} alt={name} />
                    </div>
                    <div className="col my-auto">
                        <h5 className="my-auto">{name}</h5>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardExerciseTopic;
