import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { formatDateTransaction, formatPrice } from '../../../../utils/helper'
import { useSelector } from 'react-redux'

const SectionTransactionHistory = () => {
    const { transactions } = useSelector((state) => state.mentor);
    const [limit, setLimit] = useState(10);

    return (
        <section className="my-5">
            <h4 className="section-title mb-3">Riwayat Transaksi</h4>
            <div className="card border-0 shadow-sm">
                <div className="card-body overflow-auto">
                    <table className="table table-striped mb-0">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Order Code</th>
                                <th>Products</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions?.length < 1 && (
                                <tr>
                                    <td colSpan={4}>Belum ada transaksi</td>
                                </tr>
                            )}
                            {transactions?.slice(0, limit).map((transaction, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{formatDateTransaction(transaction.purchased_at)}</td>
                                        <td>{transaction.order_code}</td>
                                        <td>{transaction.product}</td>
                                        <td>Rp {formatPrice(transaction.amount)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {transactions?.length > limit && (
                        <div className="text-center my-4">
                            <button onClick={() => setLimit(limit + 7)} className="btn btn-sm btn-outline-dark">
                                <FontAwesomeIcon className="me-1" icon={faArrowRotateRight} />
                                <span>Lihat Lainnya</span>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default SectionTransactionHistory