import { faArrowDown, faArrowUp, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { disconnectCourseFromRoadmap, moveDownCourse, moveUpCourse } from "../../../../features/mentor/mentorRoadmapSlice";
import { capitalizeFirstLetter, removeHTMLTags } from "../../../../utils/helper";

const ItemRoadmap = ({ index, dataLength, objectId, title, position, categoryId }) => {
    const { id } = useParams();
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();

    // Move Up Course
    const onMoveUpCourse = () => {
        dispatch(moveUpCourse({
            pathId: id,
            courseId: objectId,
            position: position
        }));
    };

    // Move Down Course
    const onMoveDownCourse = () => {
        dispatch(moveDownCourse({
            pathId: id,
            courseId: objectId,
            position: position
        }));
    };

    // Remove Course
    const onDeleteCourse = () => {
        MySwal.fire({
            title: 'Remove Course?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result => {
            if (result.isConfirmed) {
                dispatch(disconnectCourseFromRoadmap({
                    pathId: id,
                    courseId: objectId,
                    categoryId: categoryId
                })).then((response) => {
                    // Alert
                    MySwal.fire({
                        title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                        text: removeHTMLTags(response.payload.message),
                        icon: response.payload.status === 'success' ? 'success' : 'error',
                        showConfirmButton: false,
                        timer: 2000
                    });
                });
            }
        }));
    };

    return (
        <li className="list-group-item bg-white rounded-0 border p-2 mb-2 text-nowrap overflow-auto">
            <span className="me-2">
                {index !== 0 && (
                    <button onClick={onMoveUpCourse} className="btn btn-sm text-muted shadow-none p-1">
                        <FontAwesomeIcon icon={faArrowUp} />
                    </button>
                )}
                {index !== dataLength - 1 && (
                    <button onClick={onMoveDownCourse} className="btn btn-sm text-muted shadow-none p-1">
                        <FontAwesomeIcon icon={faArrowDown} />
                    </button>
                )}
            </span>
            <span className="text-muted" title={title}>{title.length < 40 ? title : title.slice(0, 40) + "..." || <i>Belum ada judul</i>}</span>
            <span className="ms-2">
                <a className="btn text-muted btn-sm shadow-none" href={`/mentor/courses/edit/overview/${objectId}`} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faPen} />
                </a>
                <button onClick={onDeleteCourse} className="btn text-muted btn-sm shadow-none">
                    <FontAwesomeIcon icon={faTrash} />
                </button>
            </span>
        </li>
    );
};

export default ItemRoadmap;