import styles from './ButtonShareSocmed.module.scss';
import { faFacebook, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ButtonShareSocmed = ({ type, link }) => {
    return (
        <>
            {type === 'facebook' && (
                <a className={`btn ${styles.btn_facebook} btn-sm rounded-pill m-1`} href={`https://www.facebook.com/sharer/sharer.php?u=${link}`} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="me-2" icon={faFacebook} />
                    Facebook
                </a>
            )}
            {type === 'twitter' && (
                <a className={`btn ${styles.btn_twitter} btn-sm rounded-pill m-1`} href={`https://twitter.com/intent/tweet?url=${link}`} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="me-2" icon={faTwitter} />
                    Twitter
                </a>
            )}
            {type === 'linkedin' && (
                <a className={`btn ${styles.btn_linkedin} btn-sm rounded-pill m-1`} href={`https://www.linkedin.com/sharing/share-offsite/?url=${link}`} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="me-2" icon={faLinkedin} />
                    LinkedIn
                </a>
            )}
        </>
    );
};

export default ButtonShareSocmed;
