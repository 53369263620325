import { faClock, faSwatchbook, faUsers, faChartColumn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './CardExercise.module.scss';

const CardExercise = ({ thumbnail, title, level, totalStudents, duration, totalQuestions, price }) => {
    return (
        <div className={`${styles.card_exercise} card border-0 shadow-sm h-100`}>
            <div className="position-relative">
                <div className="position-absolute bottom-0">
                    {level === "Beginner" ? (
                        <span className={"bg-primary text-white px-3 py-1"}>{level}</span>
                    ) : null}
                    {level === "Intermediate" ? (
                        <span className={"bg-pink text-white px-3 py-1"}>{level}</span>
                    ) : null}
                    {level === "Master" ? (
                        <span className={"bg-purple text-white px-3 py-1"}>{level}</span>
                    ) : null}
                </div>
                <img src={thumbnail || "/assets/img/placeholder.jpg"} className={`${styles.card_img_top} card-img-top w-100`} loading="lazy" alt={title} />
            </div>
            <div className="card-body" style={{ minHeight: '150px' }}>
                <h5 className={styles.exercise_title} title={title}>{title || 'Untitled'}</h5>
                <div className={styles.exercise_info}>
                    <div className="row">
                        <div className="col-auto">
                            <p className="my-2"><FontAwesomeIcon fixedWidth icon={faChartColumn} /> {level}</p>
                            <p className="my-2"><FontAwesomeIcon fixedWidth icon={faUsers} /> {totalStudents || 0} Siswa</p>
                        </div>
                        <div className="col-auto">
                            <p className="my-2"><FontAwesomeIcon fixedWidth icon={faClock} /> {duration || 0} Menit</p>
                            <p className="my-2"><FontAwesomeIcon fixedWidth icon={faSwatchbook} /> {totalQuestions || 0} Soal</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`card-footer bg-white ${styles.card_footer}`}>
                <div className={styles.price}>
                    <div className="row justify-content-between">
                        <div className="col-auto">
                            <strong>{price || 'Free'}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardExercise;
