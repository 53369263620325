import { faClock, faCheckCircle, faSwatchbook, faUsers, faChartColumn, faStarHalfStroke, faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPrice } from '../../../../utils/helper';
import styles from './CardCourse.module.scss';

const CardCourse = ({ icon, roadmap, thumbnail, author, title, level, totalStudents, totalModules, totalTimes, totalRating, totalFeedback, normalBuyPrice, normalRentPrice, retailBuyPrice, retailRentPrice, isFlashsale, isPopular }) => {
    return (
        <div className={`${styles.card_course} card border-0 shadow-sm`}>
            {isPopular && (
                <img src="/assets/ribbon-red.png" className="position-absolute" style={{ transform: 'rotate(270deg)', width: '35%', left: '-1.8%', top: -5 }} alt="Popular" />
            )}

            <img src={thumbnail || "/assets/img/learn/Group 2266.png"} className={`${styles.card_img_top} card-img-top w-100`} loading="lazy" alt={title} />

            <div className={`card-body ${styles.card_body}`}>
                <span>
                    <small>By {author || 'Unknown'} {author && (<FontAwesomeIcon fixedWidth className="text-primary" icon={faCheckCircle} />)}</small>
                </span>
                <h5 className={styles.course_title} title={title}>{title}</h5>
                <div className={styles.course_info}>
                    {
                        !roadmap
                            ? (
                                <div className="row">
                                    <div className="col-auto">
                                        <p className="my-2"><FontAwesomeIcon fixedWidth icon={faChartColumn} /> {level && level.charAt(0).toUpperCase() + level.slice(1)}</p>
                                        <p className="my-2"><FontAwesomeIcon fixedWidth icon={faUsers} /> {totalStudents} Siswa</p>
                                    </div>
                                    <div className="col-auto">
                                        <p className="my-2"><FontAwesomeIcon fixedWidth icon={faClock} /> {totalTimes} Jam</p>
                                        <p className="my-2"><FontAwesomeIcon fixedWidth icon={faSwatchbook} /> {totalModules} Modul</p>
                                    </div>
                                </div>
                            )
                            : (
                                <div className="row">
                                    <div className="col-auto">
                                        <p className="my-2"><FontAwesomeIcon fixedWidth icon={faSwatchbook} /> {totalModules} Kelas</p>
                                    </div>
                                    <div className="col-auto">
                                        <p className="my-2"><FontAwesomeIcon fixedWidth icon={faUsers} /> {totalStudents} Siswa</p>

                                    </div>
                                </div>

                            )
                    }
                </div>
                {
                    !roadmap && (
                        <div className="mt-2">
                            <span className="text-warning">
                                <small>
                                    <strong>
                                        {totalRating === 0 ? (
                                            <FontAwesomeIcon className="me-1" icon={faStarRegular} />
                                        ) : (
                                            <FontAwesomeIcon className="me-1" icon={totalRating !== 5 ? faStarHalfStroke : faStar} />
                                        )}
                                        {totalRating !== 5 ? totalRating.toFixed(1) : totalRating + '.0'}
                                    </strong>
                                </small>
                            </span>
                            <span className="ms-1 text muted">
                                <small>
                                    ({totalFeedback}) Penilaian
                                </small>
                            </span>
                        </div>
                    )
                }
            </div>
            <div className={`card-footer bg-white ${styles.card_footer}`}>
                <div className={styles.rate_and_price}>
                    <div className="row justify-content-between">
                        <div className="col-auto">
                            {retailBuyPrice > 0 && (
                                <strong>Beli</strong>
                            )}
                            <br />
                            {retailRentPrice > 0 && (
                                <strong>Sewa</strong>
                            )}
                        </div>
                        <div className="col-auto ms-auto text-end">
                            <span>
                                {normalBuyPrice > 0 && normalBuyPrice !== retailBuyPrice && (
                                    <strong className="text-danger me-1">
                                        <del className={isFlashsale ? styles.blink : ''}>
                                            Rp {formatPrice(normalBuyPrice)}
                                        </del>
                                    </strong>
                                )}
                                {retailBuyPrice > 0 && (
                                    <strong>Rp {formatPrice(retailBuyPrice)}</strong>
                                )}
                                {retailBuyPrice === -1 && (
                                    <strong>Beli lewat Roadmap</strong>
                                )}
                                {retailBuyPrice === 0 && (
                                    <strong>GRATIS</strong>
                                )}
                            </span>
                            <br />
                            <span>
                                {normalRentPrice > 0 && normalRentPrice !== retailRentPrice && (
                                    <strong className="text-danger me-1">
                                        <del className={isFlashsale ? styles.blink : ''}>
                                            Rp {formatPrice(normalRentPrice)}
                                        </del>
                                    </strong>
                                )}
                                {retailRentPrice > 0 && (
                                    <strong>Rp {formatPrice(retailRentPrice)}</strong>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardCourse;
